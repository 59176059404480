import React, { useContext, useEffect, useRef, useState } from "react";
import { ParamContext } from "../contexts/ParamContexts";
import { useNavigate } from "react-router-dom";
import ContentCartCheckout from "../components/ContentCartCheckout";
import CheckoutDeliveryGuess from "../components/CheckoutDeliveryGuess";
import CheckoutBillingGuess from "../components/CheckoutBillingGuess";
import CheckoutPayment from "../components/CheckoutPayment";
import { useTranslation } from "react-i18next";
// import ChooseShipping from "../components/ChooseShipping";

const GuessCheckout = ({ setIsLoading }) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { cartItems, emailGuess, setEmailGuess } = useContext(ParamContext);

  const [activeStep, setActiveStep] = useState(
    parseInt(sessionStorage.getItem("activeStep")) || 1
  );

  const [isEditingEmail, setIsEditingEmail] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const isCartFull = Object.values(cartItems).some(
      (cartItem) => cartItem.quantity > 0
    );

    if (!isCartFull) {
      navigate("/");
    }
  }, [cartItems, navigate]);

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
    sessionStorage.setItem("activeStep", activeStep + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
    sessionStorage.setItem("activeStep", activeStep - 1);
  };

  const handleGuestCheckout = (event) => {
    event.preventDefault();
    setEmailGuess(event.target.emailGuess.value);
    setIsEditingEmail(false);
    sessionStorage.setItem(
      "emailGuess",
      JSON.stringify(event.target.emailGuess.value)
    );
  };

  const myComponentRef = useRef(null);

  useEffect(() => {
    if ((activeStep === 1 || activeStep === 2) && myComponentRef.current) {
      myComponentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [activeStep]);

  return (
    <div className="checkout-nd-page">
      <div className="left-container-nd-step">
        <div className="head-checkout-page" ref={myComponentRef}>
          <p>{t("securePay")}</p>
          {isEditingEmail ? (
            <form
              className="left-side-checkout-st"
              onSubmit={handleGuestCheckout}
            >
              <input
                type="email"
                id="emailGuess"
                name="emailGuess"
                defaultValue={emailGuess || ""}
                placeholder="Email"
                required
              />
              <button>{t("change")}</button>
            </form>
          ) : (
            <div className="email-guess-container">
              <span>
                {t("contactEmail")}
                {" : "}
                {emailGuess}
              </span>
              <button onClick={() => setIsEditingEmail(true)}>
                {t("change")}
              </button>
            </div>
          )}
        </div>
        <div className="guess-forms-container">
          {/* <ChooseShipping
            isActive={activeStep === 1}
            handleNextStep={handleNextStep}
          /> */}
          <CheckoutDeliveryGuess
            isActive={activeStep === 1}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
          />
          <CheckoutBillingGuess
            isActive={activeStep === 2}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
          />
          <CheckoutPayment
            isActive={activeStep === 3}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
          />
        </div>
      </div>
      <div className="right-container-nd-step">
        <ContentCartCheckout setIsLoading={setIsLoading} />
      </div>
    </div>
  );
};

export default GuessCheckout;
