import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

const Stocks = ({ toggleStocks, setToggleStocks, idProduct }) => {
  const [stocks, setStocks] = useState({});
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const myRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!myRef.current.contains(e.target)) {
        setToggleStocks(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    const getProduct = async () => {
      await axios
        .get(`https://api.sweatteeshop.com/back/products/data/${idProduct}`)
        .then((res) => {
          const ordersObj = Object.values(res.data);
          const sorted = ordersObj[0];
          setStocks(sorted.stocks);
        });
    };
    getProduct();
  }, [idProduct]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const colorsResponse = await axios.get(
          "https://api.sweatteeshop.com/front/colors"
        );
        const sizesResponse = await axios.get(
          "https://api.sweatteeshop.com/front/sizes"
        );

        setColors(Object.values(colorsResponse.data));
        setSizes(Object.values(sizesResponse.data));
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={`bgStock ${toggleStocks ? "active" : "inactive"}`}>
      <div ref={myRef} className="stockContainer">
        <div className="headSizeGuide">
          <svg
            onClick={() => {
              setToggleStocks(false);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 451.124 451.124"
          >
            <path
              className="crossConnect"
              d="M310.5,260.5L747.5,697.5Zm0,0L747.5,697.5Zm436.992,0L310.5,697.5Zm0,0L310.5,697.5Z"
              transform="translate(-303.438 -253.438)"
            />
          </svg>
        </div>
        <table className="stockTable">
          <thead>
            <tr>
              <th>Taille</th>
              <th>Couleur</th>
              <th>Stock</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(stocks).map((product, index) => (
              <tr key={index}>
                <td>
                  {
                    sizes.find((size) => size.idSize === product.idSize)
                      ?.nameSize
                  }
                </td>
                <td>
                  {
                    colors.find((color) => color.idColor === product.idColor)
                      ?.nameColor
                  }
                </td>
                <td>{product.stockProduct}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Stocks;
