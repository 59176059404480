import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { ParamContext } from "../contexts/ParamContexts";
import PopUp from "./PopUp";

const BillCardBack = (props) => {
  let { idBill } = props;

  const { adminData } = useContext(ParamContext);

  const [bill, setBill] = useState([]);
  const [togglePopup, setTogglePopup] = useState(false);
  const [messagePopup, setMessagePopup] = useState("");
  const [comeFrom, setComeFrom] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);

  useEffect(() => {
    const getBill = async () => {
      await axios
        .get(`https://api.sweatteeshop.com/back/bills/data/${idBill}`)
        .then((res) => {
          const billObj = Object.values(res.data);
          setBill(billObj[0]);
        });
    };
    getBill();
  }, [idBill]);

  const datePerser = (date) => {
    if (date) {
      const newDate = new Date(date);
      const day = newDate.getDate().toString().padStart(2, "0");
      const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
      const year = newDate.getFullYear();
      return `${day}/${month}/${year}`;
    } else {
      return "";
    }
  };

  const deleteBill = () => {
    const formatData = {
      idBill,
      nameFile: bill?.pathBill,
    };

    axios
      .post("https://api.sweatteeshop.com/back/bills/delete", formatData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendBill = () => {
    setIsLoading(true);

    const formatData = {
      idBill: bill?.idBill,
      idOrder: bill.orderBill,
      firstName: bill?.firstNameBill,
      lastName: bill?.lastNameBill,
      email: bill?.emailBilling,
      pathBill: bill?.pathBill,
    };

    axios
      .post("https://api.sweatteeshop.com/back/bills/send", formatData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        // alert(response.data)
        setIsLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const cancelBill = () => {
    setIsLoadingCancel(true);
    const formatData = {
      idBill,
    };

    axios
      .post("https://api.sweatteeshop.com/back/bills/cancel", formatData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        console.log(response);
        window.location.reload();
        // setIsLoadingCancel(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingCancel(false);
      });
  };

  const openPdf = () => {
    window.open(
      "https://api.sweatteeshop.com/public/bills/" + bill?.pathBill,
      "_blank"
    );
  };

  const btnSend = bill?.isShared === 1 ? "Envoyer à nouveau" : "Envoyer";
  const btnCancel =
    bill?.isCanceled === 1 ? "Restaurer la facture" : "Annuler la facture";

  let billingFunc = null;

  if (comeFrom === "BillDeletion") {
    billingFunc = deleteBill;
  } else if (comeFrom === "sendBill") {
    billingFunc = sendBill;
  } else if (comeFrom === "cancelBill") {
    billingFunc = cancelBill;
  }

  return (
    <ul className="order-card-back">
      <li>
        <h5>N Facture</h5>
        <span>{bill.orderBill}</span>
      </li>
      <li>
        <h5>Client</h5>
        <span>
          {bill?.firstNameBill} {bill?.lastNameBill}
        </span>
      </li>
      <li>
        <h5>Montant</h5>
        <span>{bill?.totalPrice} €</span>
      </li>
      <li>
        <h5>Date</h5>
        <span style={bill.isCanceled === 0 ? { color: "" } : { color: "red" }}>
          {bill.isCanceled === 1 ? "Annulée" : datePerser(bill.dateBill)}
        </span>
      </li>
      <li className="order-button">
        <button className="card-btn" onClick={openPdf}>
          Consulter
        </button>
        <button
          className={`card-btn ${bill?.isCanceled === "1" ? "inactive" : ""}`}
          onClick={() => {
            setTogglePopup(true);
            setMessagePopup(
              "En confirmant l'envoie, la facture sera immédiatement envoyé au client !"
            );
            setComeFrom("sendBill");
          }}
          disabled={isLoading || bill?.isCanceled === 1}
        >
          {isLoading ? "..." : btnSend}
        </button>
        {adminData.adminAccess === 1 && (
          <button
            className="card-btn"
            onClick={() => {
              setTogglePopup(true);
              setMessagePopup("Confirmer vous cette action ?");
              setComeFrom("cancelBill");
            }}
            disabled={isLoadingCancel}
          >
            {isLoadingCancel ? "..." : btnCancel}
          </button>
        )}
        <button
          className="card-btn"
          onClick={() => {
            setTogglePopup(true);
            setMessagePopup(
              "La suppression est irreversible, êtes-vous certain de vouloir supprimer cette facture ?"
            );
            setComeFrom("BillDeletion");
          }}
        >
          Supprimer
        </button>
      </li>
      <PopUp
        togglePopup={togglePopup}
        setTogglePopup={setTogglePopup}
        onSubmite={billingFunc}
        messagePopup={messagePopup}
        comeFrom={comeFrom}
      />
    </ul>
  );
};

export default BillCardBack;
