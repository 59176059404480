import axios from "axios";
import React, { useEffect, useState } from "react";
import PopUp from "./PopUp";
import { NavLink } from "react-router-dom";

const OrderCardBack = (props) => {
  let { idOrder } = props;

  const [order, setOrder] = useState([]);
  const [products, setProducts] = useState([]);
  const [user, setUser] = useState([]);
  const [userAddress, setAddress] = useState([]);
  const [togglePopup, setTogglePopup] = useState(false);
  const [editeStatut, setEditeStatut] = useState(false);
  const [updateData, setUpdateData] = useState(0);
  const [messagePopup, setMessagePopup] = useState("");
  const [comeFrom, setComeFrom] = useState("");
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  const idBilling = order?.idBilling;
  const idUser = order.idUser;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orderResponse = await axios.get(
          `https://api.sweatteeshop.com/back/orders/data/${idOrder}`
        );
        const orderData = Object.values(orderResponse.data);
        setOrder(orderData[0]);
        const productResponse = await axios.get(
          `https://api.sweatteeshop.com/back/orders/products/${idOrder}`
        );
        setProducts(Object.values(productResponse.data));
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [idOrder, updateData]);

  useEffect(() => {
    const getUserAddress = async () => {
      await axios
        .get(
          `https://api.sweatteeshop.com/front/users/Address_info/${idBilling}`
        )
        .then((res) => {
          const ordersObj = Object.values(res.data);
          setAddress(ordersObj[0]);
        });
    };
    const getUser = async () => {
      await axios
        .get(`https://api.sweatteeshop.com/front/users/data/${idUser}`)
        .then((res) => {
          const ordersObj = Object.values(res.data);
          setUser(ordersObj[0]);
        });
    };
    getUser();
    getUserAddress();
  }, [idBilling, idUser]);

  const datePerser = (date) => {
    if (date) {
      const newDate = new Date(date);
      const day = newDate.getDate().toString().padStart(2, "0");
      const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
      const year = newDate.getFullYear();
      return `${day}/${month}/${year}`;
    } else {
      return "";
    }
  };

  const confirmOrder = () => {
    setIsLoadingConfirm(true);
    const contentOrder = products?.filter(
      (product) => product.idOrder === idOrder
    );

    const formatData = {
      idOrder,
      dateOrder: order.dateOrder,
      typeDiscount: order.discountType,
      discountValue: order.discounValue,
      discountCalcVal: order.discountCalcVal,
      firstName: userAddress.firstName,
      lastName: userAddress.lastName,
      emailContact: user.email,
      email: userAddress.email,
      phone: userAddress.phone,
      address: userAddress.address,
      addressComp: userAddress.addressComp,
      city: userAddress.city,
      zip: userAddress.zipCode,
      country: userAddress.country,
      shipping: order.shipping,
      totalPrice: order.totalPrice,
      contentOrder,
    };

    axios
      .post("https://api.sweatteeshop.com/back/orders/confirm", formatData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        console.log(response);
        // alert(response.data);
        setUpdateData(updateData + 1);
        setIsLoadingConfirm(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingConfirm(false);
      });
  };

  const updateOrder = (status) => {
    const formatData = {
      idOrder,
      status,
    };

    axios
      .post("https://api.sweatteeshop.com/back/orders/edite", formatData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        setUpdateData(updateData + 1);
        setEditeStatut(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const cancelOrder = () => {
    const formatData = {
      idOrder,
      status: "Annulée",
    };

    axios
      .post("https://api.sweatteeshop.com/back/orders/cancel", formatData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        setUpdateData(updateData + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateConsulted = () => {
    const formatData = {
      idOrder,
    };

    axios
      .post(
        "https://api.sweatteeshop.com/back/orders/update-consulted",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        setUpdateData(updateData + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteOrder = () => {
    const formatData = {
      idOrder: idOrder,
    };

    axios
      .post("https://api.sweatteeshop.com/back/orders/delete", formatData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStatusChange = (event) => {
    updateOrder(event.target.value);
  };

  return (
    <ul
      className={`order-card-back ${
        order?.isConsulted === 0 ? "unconsulted" : ""
      }`}
    >
      <li>
        <h5>Numero</h5>
        <span>{order?.NumberOrder}</span>
      </li>
      <li>
        <h5>Date</h5>
        <span>{datePerser(order?.dateOrder)}</span>
      </li>
      <li>
        <h5>Montant</h5>
        <span>{order?.totalPrice}</span>
      </li>
      <li>
        <h5>Code promo</h5>
        <span>{order?.promoCode ? order?.promoCode : "AUCUN"}</span>
      </li>
      <li>
        <h5>Status</h5>
        {editeStatut ? (
          <select
            name="statutOrder"
            value={order?.orderStatus}
            onChange={handleStatusChange}
          >
            <option value="En attente">En attente</option>
            <option value="En traitement">En traitement</option>
            <option value="Livrée">Livrée</option>
            <option value="Annulée">Annulée</option>
          </select>
        ) : (
          <span
            className={`orderStatusBack ${
              order?.orderStatus === "Annulée"
                ? "orderStatus--canceled"
                : order?.orderStatus === "En traitement"
                ? "orderStatus--traitement"
                : order?.orderStatus === "Livrée"
                ? "orderStatus--livraison"
                : "orderStatus--attente"
            }`}
          >
            {order?.orderStatus}
          </span>
        )}
      </li>
      {order?.orderStatus === "En attente" ? (
        <li className="order-button">
          <NavLink to={`/back/commandes/${idOrder}`}>
            <button onClick={updateConsulted}>Consulter</button>
          </NavLink>
          <div>
            <button
              onClick={() => {
                setTogglePopup(true);
                setMessagePopup(
                  "En confirmant la commande, une nouvelle facture sera creer!"
                );
                setComeFrom("confirmOrder");
              }}
              disabled={isLoadingConfirm}
            >
              {isLoadingConfirm ? "Chargement" : "Confirmer"}
            </button>
            <br />
          </div>
          <div>
            <button onClick={cancelOrder}>Rejeter</button>
            <br />
          </div>
        </li>
      ) : (
        order?.orderStatus !== "En attente" && (
          <li className="order-button">
            <NavLink to={`/back/commandes/${idOrder}`}>
              <button onClick={updateConsulted}>Consulter</button>
            </NavLink>
            {editeStatut ? (
              <button
                className="btnAction"
                onClick={() => setEditeStatut(false)}
              >
                Annuler
              </button>
            ) : (
              <button
                className="btnAction"
                onClick={() => setEditeStatut(true)}
              >
                Modifier le statue
              </button>
            )}
            <button
              onClick={() => {
                setTogglePopup(true);
                setMessagePopup(
                  "La suppression est irreversible, êtes-vous certain de vouloir supprimer ce cette commande ?"
                );
                setComeFrom("orderDeletion");
              }}
            >
              Supprimer
            </button>
          </li>
        )
      )}
      <PopUp
        togglePopup={togglePopup}
        setTogglePopup={setTogglePopup}
        onSubmite={comeFrom === "orderDeletion" ? deleteOrder : confirmOrder}
        messagePopup={messagePopup}
        comeFrom={comeFrom}
      />
    </ul>
  );
};

export default OrderCardBack;
