import React, { useContext, useEffect, useState } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { ParamContext } from "../contexts/ParamContexts";
import { useNavigate } from "react-router-dom";

const PaypalCheckoutButton = () => {
  const { totalPrice, userStatue, totalCartGuess, paidFor, setPaidFor } =
    useContext(ParamContext);

  const order_amount = userStatue ? totalPrice : totalCartGuess;

  const navigate = useNavigate();

  const [error, setError] = useState(null);

  // const handleApprove = () => {
  //   setPaidFor(true);
  //   sessionStorage.setItem("paymentSuccess", false);
  //   navigate("/confirmation-de-paiement");
  //   sessionStorage.setItem("paidFor", true);
  // };

  useEffect(() => {
    if (paidFor) {
      navigate("/confirmation-de-paiement");
    }
  }, [navigate, paidFor]);

  if (error) {
    alert(error);
  }

  return (
    <PayPalButtons
      style={{
        layout: "horizontal",
        height: 30,
        tagline: false,
        color: "silver",
      }}
      createOrder={(data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: order_amount,
              },
            },
          ],
        });
      }}
      onApprove={async (data, actions) => {
        // const order = await actions.order.capture();
        // console.log("order", order);
        setPaidFor(true);
        sessionStorage.setItem("paymentSuccess", false);
        navigate("/confirmation-de-paiement");
        sessionStorage.setItem("paidFor", true);
      }}
      onError={(err) => {
        setError(err);
        console.error("PayPal Checkout onError", err);
      }}
      onCancel={() => {
        navigate("/");
      }}
      onClick={(data, actions) => {
        const hasAlreadyBoughtCourse = false;

        if (hasAlreadyBoughtCourse) {
          setError("Votre achat a déjà été validé.");

          return actions.reject();
        } else {
          return actions.resolve();
        }
      }}
    />
  );
};

export default PaypalCheckoutButton;