import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import countriesFr from "../countriesFr.json";
import countriesEn from "../countriesEn.json";
import { useContext } from "react";
import { ParamContext } from "../contexts/ParamContexts";

const CheckoutBillingGuess = ({
  isActive,
  handleNextStep,
  handlePreviousStep,
}) => {
  const { t, i18n } = useTranslation();

  const language = i18n.language || "fr";
  const jsonFile = language === "fr" ? countriesFr : countriesEn;

  const {
    billingFormData,
    setBillingFormData,
    deliveryformData,
    isSomeData,
    setIsSomeData,
    billingFormDataStores,
    setBillingFormDataStores,
    // selectedShipping,
  } = useContext(ParamContext);

  const validationSchema = Yup.object().shape({
    country: Yup.string().test(
      "Pays",
      "Ce champ est obligatoire",
      function (country) {
        return country !== "Pays *";
      }
    ),
    firstName: isSomeData
      ? Yup.string()
      : Yup.string().required(t("LastName") + " " + t("missing")),
    lastName: isSomeData
      ? Yup.string()
      : Yup.string().required(t("FirstName") + " " + t("missing")),
    email: isSomeData
      ? Yup.string()
      : Yup.string()
          .required("Email " + t("missing"))
          .email("Email " + t("invalid") + " (sweatteeshop@gmail.com)"),
    phone: isSomeData
      ? Yup.string()
      : Yup.string()
          .required(t("Phone") + " " + t("missing"))
          .matches(/([0-9])/, t("Phone") + " " + t("invalid")),
    address: isSomeData
      ? Yup.string()
      : Yup.string().required(t("Address") + " " + t("missing")),
    city: isSomeData
      ? Yup.string()
      : Yup.string().required(t("City") + " " + t("missing")),
    zip: isSomeData
      ? Yup.string()
      : Yup.string()
          .required(t("Zip") + " " + t("missing"))
          .matches(/([0-9])/, t("Zip") + " " + t("invalid")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  function onSubmit(data) {
    const formatData = {
      firstNameBilling: data.firstName,
      lastNameBilling: data.lastName,
      emailBilling: data.email,
      phoneBilling: data.phone,
      addressBilling: data.address,
      adressCompBilling: data.adressComp,
      cityBilling: data.city,
      zipBilling: data.zip,
      countryBilling: data.country,
    };

    handleNextStep();

    if (!isSomeData) {
      setBillingFormData(formatData);
      setBillingFormDataStores(formatData);
      sessionStorage.setItem("billingFormData", JSON.stringify(formatData));
      sessionStorage.setItem(
        "billingFormDataStores",
        JSON.stringify(formatData)
      );
    } else {
      setBillingFormData(deliveryformData);
      sessionStorage.setItem(
        "billingFormData",
        JSON.stringify(deliveryformData)
      );
    }
  }

  function handleCheckboxChange(event) {
    setIsSomeData(event.target.checked);
    if (event.target.checked) {
      setBillingFormData(deliveryformData);
      sessionStorage.setItem(
        "billingFormData",
        JSON.stringify(deliveryformData)
      );
    } else {
      setBillingFormData(billingFormDataStores);
      sessionStorage.setItem(
        "billingFormData",
        JSON.stringify(billingFormDataStores)
      );
    }
    sessionStorage.setItem("isSomeData", JSON.stringify(event.target.checked));
  }

  return (
    <form
      className="form-delivery"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>2. {t("billingInfo")}.</div>
      {isActive && (
        <div className="checkout-form-container">
          {/* {selectedShipping !== "Ralay" && ( */}
          <div className="check-same-address-container">
            <input
              type="checkbox"
              name="newsletter"
              id="sameAddress"
              onChange={handleCheckboxChange}
              checked={isSomeData}
            />
            <label htmlFor="sameAddress">{t("sameAddress")}</label>
          </div>
          {/* )} */}
          {!isSomeData && (
            <div className="checkout-form-container">
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="lastName"
                    name="lastName"
                    defaultValue={billingFormData.lastNameBilling || ""}
                    placeholder={t("LastName")}
                    {...register("lastName")}
                  />
                  {errors.lastName && (
                    <small className="errorInputConfig">
                      {errors.lastName?.message}
                    </small>
                  )}
                </div>
                <div className="input-checkout-form-nd">
                  <input
                    id="firstName"
                    name="firstName"
                    defaultValue={billingFormData.firstNameBilling || ""}
                    placeholder={t("FirstName")}
                    {...register("firstName", {})}
                  />
                  {errors.firstName && (
                    <small className="errorInputConfig">
                      {errors.firstName?.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="email"
                    name="email"
                    defaultValue={billingFormData.emailBilling || ""}
                    placeholder="Email"
                    {...register("email")}
                  />
                  {errors.email && (
                    <small className="errorInputConfig">
                      {errors.email?.message}
                    </small>
                  )}
                </div>
                <div className="input-checkout-form-nd">
                  <input
                    id="phone"
                    name="phone"
                    defaultValue={billingFormData.phoneBilling || ""}
                    placeholder={t("Phone")}
                    {...register("phone")}
                  />
                  <small className="errorInputConfig">
                    {errors.phone?.message}
                  </small>
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="address"
                    name="address"
                    defaultValue={billingFormData.addressBilling || ""}
                    placeholder={t("Address") + " " + t("Line") + " 1"}
                    {...register("address")}
                  />
                  {errors.address && (
                    <small className="errorInputConfig">
                      {errors.address?.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="adressComp"
                    name="adressComp"
                    defaultValue={billingFormData.addressCompBilling || ""}
                    placeholder={t("Address") + " " + t("Line") + " 2"}
                    {...register("adressComp")}
                  />
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="city"
                    name="city"
                    defaultValue={billingFormData.cityBilling || ""}
                    placeholder={t("City")}
                    {...register("city")}
                  />
                  {errors.city && (
                    <small className="errorInputConfig">
                      {errors.city?.message}
                    </small>
                  )}
                </div>
                <div className="input-checkout-form-nd">
                  <input
                    type="text"
                    id="zip"
                    name="zip"
                    defaultValue={billingFormData.zipBilling || ""}
                    placeholder={t("Zip")}
                    {...register("zip")}
                  />
                  {errors.zip && (
                    <small className="errorInputConfig">
                      {errors.zip?.message}
                    </small>
                  )}
                </div>
                <div className="selectCase-checkout">
                  <select
                    className="ItemSelect"
                    name="country"
                    defaultValue={billingFormData.countryBilling || "France"}
                    {...register("country")}
                  >
                    <option disabled>{billingFormData.countryBilling}</option>
                    {jsonFile.map((country) => (
                      <option key={country.code}>{country.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
          <div className="btn-checkout-container">
            <button type="button" onClick={handlePreviousStep}>
              <span>{t("previous")}</span>
            </button>
            <button type="submit">
              <span>{t("Next")}</span>
            </button>
          </div>
        </div>
      )}
    </form>
  );
};

export default CheckoutBillingGuess;
