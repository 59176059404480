import React, { useContext, useEffect, useState } from "react";
import { ParamContext } from "../contexts/ParamContexts";
import { NavLink } from "react-router-dom";
import axios from "axios";

const NavbarBack = () => {
  const { logo, handleLogoutAdmin, adminData } = useContext(ParamContext);

  useEffect(() => {
    if (adminData.adminAccess === 1) {
      setRank("administrateur");
    } else {
      setRank("collaborateur");
    }
  }, [adminData]);

  const [rank, setRank] = useState("");
  const [newOrder, setNewOrder] = useState([]);

  const countOrder = () => {
    const orders = newOrder.filter((order) => order?.isConsulted === 0);
    return orders.length;
  };

  const ordersCount = countOrder();

  useEffect(() => {
    const getOrder = () => {
      axios.get(`https://api.sweatteeshop.com/back/orders/datas`).then((res) => {
        const infoObj = Object.values(res.data);
        setNewOrder(infoObj);
      });
    };
    getOrder();
  }, []);

  return (
    <div className="navbar-back-container">
      <div className="img-navBack-container">
        <img src={logo} alt="Logo" />
      </div>
      <ul>
        <li>
          {ordersCount > 0 && <div className="badgeCart">{ordersCount}</div>}
          <NavLink
            to="/back/gestion-des-commandes"
            className="items-back-link"
            activeclassname="active"
          >
            Commandes
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/back/gestion-des-factures"
            className="items-back-link"
            activeclassname="active"
          >
            Facturation
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/back/gestion-des-produits"
            className="items-back-link"
            activeclassname="active"
          >
            Produits
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/back/gestion-des-newsletter"
            className="items-back-link"
            activeclassname="active"
          >
            Newsletter
          </NavLink>
        </li>
        {adminData.adminAccess === 1 && (
          <li>
            <NavLink
              to="/back/gestion-des-admin"
              className="items-back-link"
              activeclassname="active"
            >
              Gestion equipe
            </NavLink>
          </li>
        )}
        {adminData.adminAccess === 1 && (
          <li>
            <NavLink
              to="/back/parametre-du-site"
              className="items-back-link"
              activeclassname="active"
            >
              Parametres
            </NavLink>
          </li>
        )}
      </ul>
      <div className="footer-navbar-back">
        <div className="data-admin">
          <span>{adminData.firstName}</span>
          <span>{rank}</span>
        </div>
        <div className="logout-container-back">
          <button onClick={handleLogoutAdmin}>Se deconecter</button>
        </div>
      </div>
    </div>
  );
};

export default NavbarBack;
