import React, { useEffect, useState } from "react";
import NavbarBack from "../components/NavbarBack";
import NavSection from "../components/NavSection";
import FilterBarNewsletter from "../components/FilterBarNewsletter";
import NewsletterCard from "../components/NewsletterCard";

const NewsletterManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [newsData, setNewsData] = useState([]);
  const [resultsPage, setResultsPage] = useState(9);
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreData = () => {
    if (!hasMore) return;
    setResultsPage(resultsPage + 9);
  };

  const sliceData = newsData.slice(0, resultsPage);

  let dataToDisplay;
  if (sliceData.length > 0) {
    dataToDisplay = sliceData.map((order) => (
      <NewsletterCard key={order.idNewsletter} idArticle={order.idNewsletter} />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>Aucune commande trouvée.</h3>
      </div>
    );
  }

  const shareProducts = (datas) => {
    setNewsData(datas);
    setHasMore(datas.length > 9);
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavSection />
        <div className="admin-content-page">
          <FilterBarNewsletter onShare={shareProducts} />
          <div className="articles-content">{dataToDisplay}</div>
          {hasMore && (
            <div className="loadMoreButtonContainer">
              <button className="loadMoreButton" onClick={fetchMoreData}>
                Plus de commandes
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsletterManagement;
