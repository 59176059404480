import axios from "axios";
import React, { useEffect, useState } from "react";
import PopUp from "./PopUp";
import { NavLink } from "react-router-dom";
import Stocks from "./Stocks";

const ProductCardBack = (props) => {
  let { idProduct } = props;

  const [product, setProdct] = useState([]);
  const [togglePopup, setTogglePopup] = useState(false);
  const [isOn, setIsOn] = useState(false);
  const [toggleStocks, setToggleStocks] = useState(false);
  const [messagePopup, setMessagePopup] = useState("");
  const [comeFrom, setComeFrom] = useState("");

  const baseUrl = "https://api.sweatteeshop.com/public/images/";

  useEffect(() => {
    if (product[0]?.onlineProduct === 1) {
      setIsOn(true);
    } else {
      setIsOn(false);
    }
  }, [product]);

  useEffect(() => {
    const getOrder = async () => {
      await axios
        .get(`https://api.sweatteeshop.com/back/products/data/${idProduct}`)
        .then((res) => {
          const productsObj = Object.values(res.data);
          setProdct(productsObj);
        });
    };
    getOrder();
  }, [idProduct]);

  const datePerser = (date) => {
    if (date) {
      const newDate = new Date(date);
      const day = newDate.getDate().toString().padStart(2, "0");
      const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
      const year = newDate.getFullYear();
      return `${day}/${month}/${year}`;
    } else {
      return "";
    }
  };

  const totalQuantity = product[0]?.stocks.reduce(
    (total, stock) => total + parseInt(stock.stockProduct),
    0
  );

  const HandlePopupDeletion = () => {
    setMessagePopup(
      "La suppression est irreversible, êtes-vous certain de vouloir supprimer ce produit ?"
    );
    setComeFrom("productDeletion");
    setTogglePopup(true);
  };

  const HandlePopupOline = () => {
    setMessagePopup("Confirmez-vous cette action ?");
    setComeFrom("toggleOnline");
    setTogglePopup(true);
  };

  const toggleSwitch = () => {
    setIsOn(!isOn);

    const formatData = {
      idProduct,
    };

    axios
      .post("https://api.sweatteeshop.com/back/products/publish", formatData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteProduct = () => {
    const formatData = {
      idProduct,
    };

    axios
      .post("https://api.sweatteeshop.com/back/products/delete", formatData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ul className="order-card-back">
      <li>
        <h5>Ref</h5>
        <span>{product[0]?.referenceProduct}</span>
      </li>
      <li>
        <h5>Date de creation</h5>
        {datePerser(product[0]?.dateProduct)}
      </li>
      <li>
        <h5>Nom du produit</h5>
        <span>{product[0]?.nameProduct}</span>
      </li>
      <li>
        <h5>Prix</h5>
        <span>{product[0]?.PriceProduct}</span>
      </li>
      <li>
        <h5>Total stock</h5>
        <span>{totalQuantity}</span>
      </li>
      <li>
        <h5>En ligne</h5>
        <div
          className={`switch ${isOn ? "on" : "off"}`}
          onClick={HandlePopupOline}
        >
          <div className="toggle" />
        </div>
      </li>
      <li>
        <button onClick={() => setToggleStocks(true)}>Stocks</button>
        <NavLink to={`/back/modification/produit/${idProduct}`}>
          <button>Modifier</button>
        </NavLink>
        <button onClick={() => HandlePopupDeletion()}>Supprimer</button>
      </li>
      <li>
        <img
          src={
            baseUrl +
            product[0]?.mediaProduct.find((media) => media.isCardImg === 1)
              ?.nameMedia
          }
          alt="produit"
        />
      </li>
      <Stocks
        toggleStocks={toggleStocks}
        setToggleStocks={setToggleStocks}
        idProduct={idProduct}
      />
      <PopUp
        togglePopup={togglePopup}
        setTogglePopup={setTogglePopup}
        onSubmite={comeFrom === "toggleOnline" ? toggleSwitch : deleteProduct}
        messagePopup={messagePopup}
        comeFrom={comeFrom}
      />
    </ul>
  );
};

export default ProductCardBack;
