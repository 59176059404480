import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { ParamContext } from "../contexts/ParamContexts";

const GuideSizes = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  const baseUrl = "https://api.sweatteeshop.com/public/images/";

  const { nameCompany, imgOpenTags } = useContext(ParamContext);

  return (
    <div className="container-size-page">
      <Helmet>
        <title>{`${nameCompany} | Guide des tailles`}</title>
        <meta
          property="og:title"
          content={`${nameCompany} | Guide des tailles`}
        />
        <meta
          name="description"
          content="Consultez le guide des tailles pour trouver le fit parfait."
        />
        <meta
          property="og:description"
          content="Consultez le guide des tailles pour trouver le fit parfait."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta property="og:url" content="https://sweatteeshop.com/guide" />
        <meta property="og:site_name" content="sweatteeshop-officiel" />
        <link rel="canonical" href={`https://sweatteeshop.com/guide`} />
      </Helmet>
      <h1>{t("sizeGuide")}</h1>
      <span>{t("GuideSizeText1")}</span>
      <ul>
        <li>
          <strong>{t("ChestSize")} :</strong> {t("GuideSizeText2")}
        </li>
        <li>
          <strong>{t("Length")} :</strong> {t("GuideSizeText3")}
        </li>
      </ul>
      <span>{t("GuideSizeText4")}</span>
      <div className="size-guide">
        <h2>T-shirts</h2>
        <table>
          <thead>
            <tr>
              <th>{t("sizes")} (EU)</th>
              <th>{t("chest")}(cm)</th>
              <th>{t("Length")} (cm)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>S</td>
              <td>91-96</td>
              <td>70</td>
            </tr>
            <tr>
              <td>M</td>
              <td>96-101</td>
              <td>72</td>
            </tr>
            <tr>
              <td>L</td>
              <td>101-106</td>
              <td>74</td>
            </tr>
            <tr>
              <td>XL</td>
              <td>106-111</td>
              <td>76</td>
            </tr>
            <tr>
              <td>XXL</td>
              <td>111-116</td>
              <td>78</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="size-guide">
        <h2>Sweat-shirts</h2>
        <table>
          <thead>
            <tr>
              <th>{t("sizes")} (EU)</th>
              <th>{t("chest")} (cm)</th>
              <th>{t("Length")} (cm)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>S</td>
              <td>96-101</td>
              <td>67</td>
            </tr>
            <tr>
              <td>M</td>
              <td>101-106</td>
              <td>69</td>
            </tr>
            <tr>
              <td>L</td>
              <td>106-111</td>
              <td>71</td>
            </tr>
            <tr>
              <td>XL</td>
              <td>111-116</td>
              <td>73</td>
            </tr>
            <tr>
              <td>XXL</td>
              <td>116-121</td>
              <td>75</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="care-wash-container">
        <h2>{t("WashingCare")}</h2>
        <span className="instructions">{t("GuideSizeText5")}</span>
        <span className="instructions">{t("GuideSizeText6")}</span>
      </div>
    </div>
  );
};

export default GuideSizes;
