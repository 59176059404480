import { useContext, useEffect, useState } from "react";
import NavbarBack from "../components/NavbarBack";
import NavSection from "../components/NavSection";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ParamContext } from "../contexts/ParamContexts";

const AdditinalManagemntProduct = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [collections, setCollections] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [codes, setCodes] = useState([]);
  const [seuil, setSeuil] = useState("");

  const navigate = useNavigate();

  const { adminData } = useContext(ParamContext);

  useEffect(() => {
    if (adminData.adminAccess === 0) {
      navigate("/back/gestion-des-commandes");
    }
  }, [adminData, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const colorsResponse = await axios.get(
          "https://api.sweatteeshop.com/front/colors"
        );
        const sizesResponse = await axios.get(
          "https://api.sweatteeshop.com/front/sizes"
        );
        const collectionResponse = await axios.get(
          "https://api.sweatteeshop.com/front/collections"
        );
        const codePromoResponse = await axios.get(
          "https://api.sweatteeshop.com/back/promocode/datas"
        );
        setColors(Object.values(colorsResponse.data));
        setSizes(Object.values(sizesResponse.data));
        setCollections(Object.values(collectionResponse.data));
        setCodes(Object.values(codePromoResponse.data));
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const AddCollection = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    axios
      .post("https://api.sweatteeshop.com/back/collection/create", formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        // alert(response.data)
        window.location.reload();
      })
      .catch((error) => {
        alert(error.data.message);
      });
  };

  const deleteCollection = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    axios
      .post("https://api.sweatteeshop.com/back/collection/delete", formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        // alert(response.data)
        window.location.reload();
      })
      .catch((error) => {
        alert(error.data.message);
      });
  };

  const AddColor = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const nameColor = formData.get("nameColor");
    const codeColor = formData.get("codeColor");

    axios
      .post(
        "https://api.sweatteeshop.com/back/colors/create",
        { nameColor, codeColor },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        // alert(response.data)
        window.location.reload();
      })
      .catch((error) => {
        alert(error.data.message);
      });
  };

  const deleteColor = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    axios
      .post("https://api.sweatteeshop.com/back/colors/delete", formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        // alert(response.data)
        window.location.reload();
      })
      .catch((error) => {
        alert(error.data.message);
      });
  };

  const AddSize = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    axios
      .post("https://api.sweatteeshop.com/back/sizes/create", formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        // alert(response.data)
        window.location.reload();
      })
      .catch((error) => {
        alert(error.data.message);
      });
  };

  const deleteSize = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    axios
      .post("https://api.sweatteeshop.com/back/sizes/delete", formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        // alert(response.data)
        window.location.reload();
      })
      .catch((error) => {
        alert(error.data.message);
      });
  };

  const AddPromoCode = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const codeName = formData.get("codeName");
    const starterDate = formData.get("starterDate");
    const endingDate = formData.get("endingDate");
    const codeValue = formData.get("codeValue");
    const codeType = formData.get("codeType");
    const useOnce = formData.get("useOnce");

    axios
      .post(
        "https://api.sweatteeshop.com/back/promocode/create",
        {
          codeName,
          starterDate,
          endingDate,
          codeValue,
          codeType,
          useOnce,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        // alert(response.data)
        window.location.reload();
      })
      .catch((error) => {
        alert(error.data.message);
      });
  };

  const deleteCode = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    axios
      .post("https://api.sweatteeshop.com/back/promocode/delete", formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        // alert(response.data)
        window.location.reload();
      })
      .catch((error) => {
        alert(error.data.message);
      });
  };

  useEffect(() => {
    const getOrder = async () => {
      await axios
        .get(`https://api.sweatteeshop.com/back/settings/datas`)
        .then((res) => {
          const SettingssObj = Object.values(res.data);
          const settingsObj = Object.values(SettingssObj);
          settingsObj.forEach((setting) => {
            switch (setting.refSettings) {
              case "thresholdAmount":
                setSeuil(parseInt(setting.settingValue));
                break;
              default:
                break;
            }
          });
        });
    };
    getOrder();
  }, []);

  const EditeLimite = async () => {
    const formatData = {
      domainName: "",
      homeTitle: "",
      homeSubTitle: "",
      popupMessage: "",
      isdisplaypfopup: "",
      nameCompany: "",
      StatusCompany: "",
      addressCompany: "",
      zipCompany: "",
      cityCompany: "",
      countryCompany: "",
      idCompany: "",
      rcs: "",
      vatCompany: "",
      ShareCapital: "",
      publisher: "",
      nameContact: "",
      addressContact: "",
      zipContact: "",
      cityContact: "",
      countryContact: "",
      emailContact: "",
      Facebook: "",
      Instagram: "",
      Tiktok: "",
      $annonce1: "",
      $annonce2: "",
      $annonce3: "",
      thresholdAmount: seuil,
    };

    try {
      const response = await axios.post(
        "https://api.sweatteeshop.com/back/settings/edite",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.log(response);
    } catch (error) {
      // console.log(error.message);
      window.location.reload();
    }
  };

  const handleChange = (event) => {
    setSeuil(event.target.value);
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavSection />
        <div className="admin-content-page">
          <div className="content-edit-back">
            <div className="checkout-form-container">
              <div className="param-product-container">
                <h4>COLLECTION</h4>
                <form className="checkout-row-nd-step" onSubmit={AddCollection}>
                  <div className="input-checkout-form-nd">
                    <input
                      id="nameCollection"
                      name="nameCollection"
                      placeholder="Nom de la collection"
                    />
                  </div>
                  <button className="btn-style">Ajouter</button>
                </form>
                <form
                  className="checkout-row-nd-step"
                  onSubmit={deleteCollection}
                >
                  <select
                    name="idCollection"
                    className="ItemSelect"
                    id="idCollection"
                  >
                    {collections.map((collection, index) => (
                      <option key={index} value={collection.idCollection}>
                        {collection.nameCollection}
                      </option>
                    ))}
                  </select>
                  <button className="btn-style">Supprimer</button>
                </form>
              </div>
            </div>
            <div className="param-product-container">
              <h4>COULEURS</h4>
              <form className="checkout-row-nd-step" onSubmit={AddColor}>
                <div className="input-checkout-form-nd">
                  <input id="address" name="nameColor" placeholder="Couleur" />
                </div>
                <div className="input-checkout-form-nd">
                  <input
                    id="address"
                    name="codeColor"
                    placeholder="Code couleur"
                  />
                </div>
                <button className="btn-style">Ajouter</button>
              </form>
              <form className="checkout-row-nd-step" onSubmit={deleteColor}>
                <select name="idColor" className="ItemSelect" id="idColor">
                  {colors.map((color, index) => (
                    <option key={index} value={color.idColor}>
                      {color.nameColor}
                    </option>
                  ))}
                </select>
                <button className="btn-style">Supprimer</button>
              </form>
            </div>
            <div className="param-product-container">
              <h4>TAILLES</h4>
              <form className="checkout-row-nd-step" onSubmit={AddSize}>
                <div className="input-checkout-form-nd">
                  <input id="nameSize" name="nameSize" placeholder="Tailles" />
                </div>
                <button className="btn-style">Ajouter</button>
              </form>
              <form className="checkout-row-nd-step" onSubmit={deleteSize}>
                <select name="idSize" className="ItemSelect" id="idSize">
                  {sizes.map((size, index) => (
                    <option key={index} value={size.idSize}>
                      {size.nameSize}
                    </option>
                  ))}
                </select>
                <button className="btn-style">Supprimer</button>
              </form>
            </div>
            <div className="param-product-container">
              <h4>CODE PROMOS</h4>
              <form className="code-promo-manage" onSubmit={AddPromoCode}>
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input id="codeName" name="codeName" placeholder="Code" />
                  </div>
                </div>
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input type="date" id="starterDate" name="starterDate" />
                  </div>
                </div>
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input type="date" id="endingDate" name="endingDate" />
                  </div>
                </div>
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input
                      type="number"
                      id="codeValue"
                      name="codeValue"
                      placeholder="Valeur du code promo"
                    />
                  </div>
                </div>
                <select
                  name="codeType"
                  className="ItemSelect"
                  id="codeType"
                  defaultValue="All"
                >
                  <option value="All" disabled>
                    Type de code
                  </option>
                  <option value="rate">Pourcentage</option>
                  <option value="fixed">fixe</option>
                </select>
                <select
                  name="useOnce"
                  className="ItemSelect"
                  id="useOnce"
                  defaultValue="All"
                >
                  <option value="All" disabled>
                    Utilisation
                  </option>
                  <option value={0}>Multiple</option>
                  <option value={1}>Unique</option>
                </select>
                <button className="btn-style">Ajouter</button>
              </form>
            </div>
            <form className="checkout-row-nd-step" onSubmit={deleteCode}>
              <select
                name="idCode"
                className="ItemSelect"
                id="idCode"
                defaultValue="All"
              >
                <option value="All" disabled>
                  Code a supprimer
                </option>
                {codes.map((code, index) => (
                  <option key={index} value={code.idCode}>
                    {code.codePromo}
                  </option>
                ))}
              </select>
              <button className="btn-style">Supprimer</button>
            </form>
            <div className="checkout-form-container">
              <div className="param-product-container">
                <h4>SEUIL DE LIVRAISON GRATUITE</h4>
                <form className="checkout-row-nd-step" onSubmit={EditeLimite}>
                  <div className="input-checkout-form-nd">
                    <input
                      type="number"
                      id="thresholdAmount"
                      name="thresholdAmount"
                      placeholder="Montant"
                      value={seuil || 0}
                      onChange={handleChange}
                    />
                  </div>
                  <button className="btn-style">Modifier</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditinalManagemntProduct;
