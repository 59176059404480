import { useEffect, useState } from "react";
import NavbarBack from "../components/NavbarBack";
import NavSection from "../components/NavSection";
import axios from "axios";

const CreateProduct = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [message, setMessage] = useState("");
  const [collections, setCollections] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [stocks, setStocks] = useState([]);

  const [isDisplayed, setIsDisplayed] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedImageImg, setSelectedImageImg] = useState(null);
  const [formDataState, setFormDataState] = useState({
    category: "",
    nameProduct: "",
    collection: "",
    reference: "",
    price: "",
    weigth: "",
    description: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const colorsResponse = await axios.get(
          "https://api.sweatteeshop.com/front/colors"
        );
        const sizesResponse = await axios.get(
          "https://api.sweatteeshop.com/front/sizes"
        );
        const collectionResponse = await axios.get(
          "https://api.sweatteeshop.com/front/collections"
        );

        setColors(Object.values(colorsResponse.data));
        setSizes(Object.values(sizesResponse.data));
        setCollections(Object.values(collectionResponse.data));
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleImageClick = (name) => {
    setSelectedImageImg(name);
  };

  const handleChange = (value, name) => {
    setFormDataState((prevState) => ({ ...prevState, [name]: value }));
  };

  function addInput() {
    const newInput = { sizeInput: "", colorSelect: "", qtyInput: "" };
    setStocks([...stocks, newInput]);
  }

  const handleChangeFiles = (event) => {
    const newFiles = event.target.files;
    const currentFiles = files;
    const allFiles = currentFiles ? [...currentFiles] : [];
    for (let i = 0; i < newFiles.length; i++) {
      allFiles.push(newFiles[i]);
    }
    setFiles(allFiles);
  };

  const handleRemoveImage = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const CreateProduct = async () => {
    if (
      formDataState.category === "" ||
      formDataState.nameProduct === "" ||
      formDataState.collection === "" ||
      formDataState.reference === "" ||
      formDataState.price === "" ||
      formDataState.weigth === "" ||
      formDataState.description === "" ||
      files.length === 0 ||
      stocks.length === 0
    ) {
      setMessage("Veuillez remplir tous les champs requis.");
      return;
    }

    const filesData = new FormData();
    for (let i = 0; i < files.length; i++) {
      filesData.append("file[]", files[i]);
    }

    try {
      const responseUpload = await axios.post(
        "https://api.sweatteeshop.com/back/products/createIMG",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const formatData = {
        idMedia: responseUpload.data,
        selectedImageImg,
        formDataState: formDataState,
        stocks: stocks,
        displayHomePage: isDisplayed,
      };

      const responseCreateProduct = await axios.post(
        "https://api.sweatteeshop.com/back/products/create",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      // console.log(responseCreateProduct);
      // alert(responseCreateProduct.data)
      setFiles([]);
      setFormDataState("");
      setMessage(responseCreateProduct.data.message);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavSection />
        <div className="admin-content-page">
          <div className="content-edit-back">
            <div className="selectCase-checkout">
              <span>Categorie</span>
              <div>
                <select
                  name="category"
                  className="ItemSelect"
                  required
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                >
                  <option value="all">Selectionnez une categorie</option>
                  <option value="T-shirts">T-shirts</option>
                  <option value="Sweat-shirts">Sweat-shirts</option>
                  <option value="Accessories">Accessories</option>
                </select>
              </div>
            </div>
            <div className="selectCase-checkout">
              <span>Collection</span>
              <div>
                <select
                  name="collection"
                  className="ItemSelect"
                  id="collection"
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                >
                  <option value="all">Selectionnez un collection</option>
                  {collections.map((collection, index) => (
                    <option key={index} value={collection.idCollection}>
                      {collection.nameCollection}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="input-checkout-form-nd">
              <label htmlFor="ref">Nom du produit</label>
              <div>
                <input
                  type="text"
                  id="nameProduct"
                  name="nameProduct"
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                  required
                />
              </div>
              <small>Ex : T-shirt, Polo, Casquette...</small>
            </div>
            <div className="input-checkout-form-nd">
              <label htmlFor="ref">Reference</label>
              <div>
                <input
                  type="text"
                  id="ref"
                  name="reference"
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                  required
                />
              </div>
            </div>
            <div className="input-checkout-form-nd">
              <label htmlFor="price">Prix</label>
              <div>
                <input
                  type="price"
                  id="price"
                  name="price"
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                  required
                />
              </div>
            </div>
            <div className="input-checkout-form-nd">
              <label htmlFor="price">Poids de l'article</label>
              <div>
                <input
                  id="weigth"
                  name="weigth"
                  value={formDataState.weigth}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
            </div>
            <div className="input-checkout-form-nd">
              <label htmlFor="description">Description</label>
              <div>
                <textarea
                  name="description"
                  id="descriptionesc"
                  cols="70"
                  rows="10"
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                  required
                ></textarea>
              </div>
            </div>
            <div className="stock-management">
              <span>Stocks</span>
              <div>
                {stocks.map((input, index) => (
                  <div className="containerForDataStock" key={index}>
                    <select
                      name="size"
                      value={input.sizeInput}
                      id={`size-select-${index}`}
                      onChange={(e) => {
                        const newInputs = [...stocks];
                        newInputs[index].sizeInput = e.target.value;
                        setStocks(newInputs);
                      }}
                    >
                      <option value="all">Tailles</option>
                      {sizes.map((size, index) => (
                        <option key={index} value={size.idSize}>
                          {size.nameSize}
                        </option>
                      ))}
                    </select>
                    <select
                      name="color"
                      value={input.colorSelect}
                      id={`color-select-${index}`}
                      onChange={(e) => {
                        const newInputs = [...stocks];
                        newInputs[index].colorSelect = e.target.value;
                        setStocks(newInputs);
                      }}
                    >
                      <option value="all">Couleures</option>
                      {colors.map((color, index) => (
                        <option key={index} value={color.idColor}>
                          {color.nameColor}
                        </option>
                      ))}
                    </select>
                    <input
                      className="inputConfigStock"
                      type="number"
                      placeholder="Qty"
                      id={`qty-input-${index}`}
                      value={input.qtyInput}
                      onChange={(e) => {
                        const newInputs = [...stocks];
                        newInputs[index].qtyInput = e.target.value;
                        setStocks(newInputs);
                      }}
                      min="0"
                      step="1"
                    />
                    <button
                      onClick={() => {
                        const newInputs = [...stocks];
                        newInputs.splice(index, 1);
                        setStocks(newInputs);
                      }}
                    >
                      Retirer
                    </button>
                  </div>
                ))}
                <button onClick={addInput}>Ajouter un stock</button>
              </div>
            </div>
            <div className="payment-methode-container">
              <div className="payment-methode">
                <input
                  type="checkbox"
                  name="displayHomePage"
                  id="displayHomePage"
                  checked={isDisplayed}
                  onChange={(e) => setIsDisplayed(e.target.checked)}
                />
                <div className="label-card-select">
                  <label htmlFor="displayHomePage">
                    Afficher en page d'acceuil
                  </label>
                </div>
              </div>
            </div>
            <div className="input-checkout-form-ndFiles">
              <div>
                <span>Images du produit</span>
                <form className="upload">
                  <label htmlFor="uploadInput" className="uploadButton">
                    <div className="uploadBody">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 99.09 122.88"
                      >
                        <path d="M64.64,13,86.77,36.21H64.64V13ZM42.58,71.67a3.25,3.25,0,0,1-4.92-4.25l9.42-10.91a3.26,3.26,0,0,1,4.59-.33,5.14,5.14,0,0,1,.4.41l9.3,10.28a3.24,3.24,0,0,1-4.81,4.35L52.8,67.07V82.52a3.26,3.26,0,1,1-6.52,0V67.38l-3.7,4.29ZM24.22,85.42a3.26,3.26,0,1,1,6.52,0v7.46H68.36V85.42a3.26,3.26,0,1,1,6.51,0V96.14a3.26,3.26,0,0,1-3.26,3.26H27.48a3.26,3.26,0,0,1-3.26-3.26V85.42ZM99.08,39.19c.15-.57-1.18-2.07-2.68-3.56L63.8,1.36A3.63,3.63,0,0,0,61,0H6.62A6.62,6.62,0,0,0,0,6.62V116.26a6.62,6.62,0,0,0,6.62,6.62H92.46a6.62,6.62,0,0,0,6.62-6.62V39.19Zm-7.4,4.42v71.87H7.4V7.37H57.25V39.9A3.71,3.71,0,0,0,61,43.61Z" />
                      </svg>
                      <p>Glissez le fichier</p>
                    </div>
                  </label>
                  <div className="uploadFooter">
                    <label htmlFor="uploadInput" className="uploadButton">
                      <p>Parcourir</p>
                      <input
                        type="file"
                        multiple
                        id="uploadInput"
                        onChange={handleChangeFiles}
                        required
                      />
                    </label>
                  </div>
                </form>
                <small>3 images par couleurs,</small>
                <br />
                <small>Ex nom de fichiers : NomduFichier.Extension.</small>
                <div className="uploadedImages">
                  {files &&
                    Array.from(files).map((file, index) => (
                      <div
                        className={`uploacontainer ${
                          selectedImageImg === file.name ? "selectedImage" : ""
                        }`}
                        key={index}
                      >
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`uploaded-${index}`}
                          onClick={() => handleImageClick(file.name)}
                        />
                        <div
                          className="containerIconRemove"
                          onClick={() => handleRemoveImage(index)}
                        >
                          <svg
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 105.7 122.88"
                          >
                            <path d="M30.46,14.57V5.22A5.18,5.18,0,0,1,32,1.55v0A5.19,5.19,0,0,1,35.68,0H70a5.22,5.22,0,0,1,3.67,1.53l0,0a5.22,5.22,0,0,1,1.53,3.67v9.35h27.08a3.36,3.36,0,0,1,3.38,3.37V29.58A3.38,3.38,0,0,1,102.32,33H98.51l-8.3,87.22a3,3,0,0,1-2.95,2.69H18.43a3,3,0,0,1-3-2.95L7.19,33H3.37A3.38,3.38,0,0,1,0,29.58V17.94a3.36,3.36,0,0,1,3.37-3.37Zm36.27,0V8.51H39v6.06ZM49.48,49.25a3.4,3.4,0,0,1,6.8,0v51.81a3.4,3.4,0,1,1-6.8,0V49.25ZM69.59,49a3.4,3.4,0,1,1,6.78.42L73,101.27a3.4,3.4,0,0,1-6.78-.43L69.59,49Zm-40.26.42A3.39,3.39,0,1,1,36.1,49l3.41,51.8a3.39,3.39,0,1,1-6.77.43L29.33,49.46ZM92.51,33.38H13.19l7.94,83.55H84.56l8-83.55Z" />
                          </svg>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="btn-checkout-container">
              <a href="/back/gestion-des-produits">
                <button>annuler</button>
              </a>
              <button onClick={CreateProduct}>Valider</button>
            </div>
            <div className="message-conect-form">{message}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProduct;
