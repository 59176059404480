import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ParamContext } from "../contexts/ParamContexts";
import HomePageCard from "../components/HomePageCard";
import { NavLink } from "react-router-dom";
import axios from "axios";
import ProductCard from "../components/ProductCard";
import { Helmet } from "react-helmet";

const HomePage = ({ setIsLoading }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  const baseUrl = "https://api.sweatteeshop.com/public/images/";

  const {
    homeHero,
    homeTitle,
    homeSubTitle,
    tshirtImg,
    sweatImg,
    bagImg,
    newInImg,
    bande1,
    bande2,
    bande3,
    isDesplayBandeST,
    isDesplayBandeND,
    isDesplayBandeRD,
    loadingParam,
    imgHomeCover,
    nameCompany,
    imgOpenTags,
  } = useContext(ParamContext);

  const textST = isDesplayBandeST === "true" ? bande1 : null;
  const textND = isDesplayBandeND === "true" ? bande2 : null;
  const textRD = isDesplayBandeRD === "true" ? bande3 : null;

  const texts =
    isDesplayBandeST === "true" ||
    isDesplayBandeND === "true" ||
    isDesplayBandeRD === "true"
      ? [textST, textND, textRD]
      : [];

  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productsResponse = await axios.get(
          "https://api.sweatteeshop.com/front/products"
        );

        const productsData = Object.values(productsResponse.data).filter(
          (product) => product.displayHomePage === 1
        );
        setProducts(productsData);
        setIsLoading(false);
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la récupération des données.",
          error
        );
        setIsLoading(false);
      }
    };

    fetchData();
  }, [setIsLoading]);

  return (
    <div className="homePage">
      <Helmet>
        <title>{`${nameCompany} | LA BOUTIQUE DU T-SHIRT`}</title>
        <meta
          property="og:title"
          content={`${nameCompany} | LA BOUTIQUE DU T-SHIRT`}
        />
        <meta
          property="og:description"
          content="Styles uniques, qualité exceptionnelle. Trouvez vos t-shirts et sweat-shirts préférés chez nous."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta property="og:url" content="https://sweatteeshop.com" />
        <meta property="og:site_name" content="sweatteeshop-officiel" />
        <meta
          name="description"
          content="Explorez notre collection unique de t-shirts et sweat-shirts  tendances, Trouvez votre style dès maintenant !"
        />
        <link rel="canonical" href={`https://sweatteeshop.com`} />
      </Helmet>
      <section className="hero-home-container">
        <div className="homeHero">
          <img src={homeHero} alt="Hero" />
          <div className="heroText">
            <h1>{homeTitle}</h1>
            <h2>{homeSubTitle}</h2>
          </div>
        </div>
      </section>
      {loadingParam && texts.length > 0 && (
        <div className="scroll-container">
          <div className="scrolling-text">
            {texts.map((text, index) => (
              <p key={index} className="text-item">
                {text}
              </p>
            ))}
          </div>
        </div>
      )}
      <div className="content-homePage">
        <section className="cat-home-page">
          <NavLink to="/nouveautés">
            <HomePageCard tshirtImg={newInImg} nameCat={t("News")} />
          </NavLink>
          <NavLink to="/t-shirts">
            <HomePageCard tshirtImg={tshirtImg} nameCat="T-SHIRTS" />
          </NavLink>
          <NavLink to="/sweat-shirts">
            <HomePageCard tshirtImg={sweatImg} nameCat="SWEAT-SHIRTs" />
          </NavLink>
          <NavLink to="/accessoires">
            <HomePageCard tshirtImg={bagImg} nameCat={t("Accessories")} />
          </NavLink>
        </section>
      </div>
      <section
        className="homeCover"
        style={{ backgroundImage: imgHomeCover }}
        alt="cover"
      >
        <div className="heroOverlay"></div>
        <div className="home-cover-Text">
          <h2>{homeTitle}</h2>
          <p>{homeSubTitle}</p>
        </div>
      </section>
      <div className="content-homePage">
        <h2 className="home-prod-title">{t("OurProduct")}</h2>
        <section className="products-container-HomePage">
          {products.map((product) => (
            <ProductCard
              key={product.idProduct}
              idProduct={product.idProduct}
              setIsLoading={setIsLoading}
            />
          ))}
        </section>
      </div>
    </div>
  );
};

export default HomePage;
