import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import PopUp from "./PopUp";
import axios from "axios";

const OrderCard = (props) => {
  let { idOrder, dateOrder, totalPrice, orderStatus, idUser } = props;

  const { t } = useTranslation();

  const [togglePopup, setTogglePopup] = useState(false);

  const datePerser = (date) => {
    if (date) {
      const newDate = new Date(date);
      const day = newDate.getDate().toString().padStart(2, "0");
      const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
      const year = newDate.getFullYear();
      return `${day}/${month}/${year}`;
    } else {
      return "";
    }
  };

  const deleteOrder = () => {
    const formatData = {
      idOrder,
      idUser,
    };

    axios
      .post(
        "https://api.sweatteeshop.com/front/users/delete-order-user",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="orderCard">
        <div className="orderCardBody">
          <div className="orderItem">
            <div className="orderItemDetails">
              <p className="orderItemPrice">
                {t("OrderNumber")} : {idOrder}
              </p>
              <p className="orderItemDate">
                {t("OrderDate")} : {datePerser(dateOrder)}
              </p>
              <div className="priceContainerUp">
                <p>
                  {t("TotalAmount")}: {totalPrice} &euro;
                </p>
              </div>
              <div className="contentStatut">
                <p>{t("Status")} :</p>
                <p
                  className={`orderStatus ${
                    orderStatus === "Annulée"
                      ? "orderStatus--canceled"
                      : orderStatus === "En traitement"
                      ? "orderStatus--traitement"
                      : orderStatus === "En cours de livraison"
                      ? "orderStatus--enCours"
                      : orderStatus === "Livrée"
                      ? "orderStatus--livraison"
                      : "orderStatus--attente"
                  }`}
                >
                  {orderStatus}
                </p>
              </div>
            </div>
          </div>
          <div className="orderTotal">
            <p>
              {t("TotalAmount")} : {totalPrice} &euro;
            </p>
          </div>
        </div>
        <div className="orderCardFooter">
          <div className="contentFooterOrderCard">
            <p>{t("Status")} :</p>
            <p className="orderStatus">{orderStatus}</p>
          </div>
          <div className="btn-order-card">
            <button
              onClick={() => {
                setTogglePopup(true);
              }}
            >
              {t("Delete")}
            </button>
            <NavLink to={`/commande/${idOrder}`}>
              <button>{t("SeeOrderDetails")}</button>
            </NavLink>
          </div>
        </div>
      </div>
      <PopUp
        togglePopup={togglePopup}
        setTogglePopup={setTogglePopup}
        onSubmite={deleteOrder}
        messagePopup={
          "La suppression est irreversible, êtes-vous certain de vouloir supprimer cette commande ?"
        }
        comeFrom={"orderDeletionUser"}
      />
    </div>
  );
};

export default OrderCard;
