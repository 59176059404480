import { useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ParamContext } from "./ParamContexts";


const PrivateRouteFront = () => {
  
  const { userStatue } = useContext(ParamContext);

  const navigate = useNavigate();

  return  userStatue ? <Outlet /> : navigate("/");
};

export default PrivateRouteFront;
