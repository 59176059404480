import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import PopUp from "./PopUp";
import axios from "axios";
import { ParamContext } from "../contexts/ParamContexts";

const BillsUserCard = (props) => {
  let {
    idBill,
    dateBill,
    totalPrice,
    pathBill,
    firstNameBill,
    lastNameBill,
    orderBill,
  } = props;

  const { t } = useTranslation();

  const [togglePopup, setTogglePopup] = useState(false);

  const { idUser } = useContext(ParamContext);

  const datePerser = (date) => {
    if (date) {
      const newDate = new Date(date);
      const day = newDate.getDate().toString().padStart(2, "0");
      const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
      const year = newDate.getFullYear();
      return `${day}/${month}/${year}`;
    } else {
      return "";
    }
  };

  const deleteOrder = () => {
    const formatData = {
      idBill,
      idUser,
    };

    axios
      .post(
        "https://api.sweatteeshop.com/front/users/delete-bills",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openPdf = () => {
    window.open(
      "https://api.sweatteeshop.com/public/bills/" + pathBill,
      "_blank"
    );
  };

  return (
    <div>
      <div className="orderCard">
        <div className="orderCardBody">
          <div className="orderItem">
            <div className="orderItemDetails">
              <p className="orderItemPrice">N Facture : {orderBill}</p>
              <p className="orderItemDate">Date : {datePerser(dateBill)}</p>
              <div className="priceContainerUp">
                <p>Montant : {totalPrice} &euro;</p>
              </div>
            </div>
          </div>
          <div className="orderTotal">
            <p>
              {t("TotalAmount")} : {totalPrice} &euro;
            </p>
          </div>
        </div>
        <div className="orderCardFooter">
          <div className="contentFooterOrderCard">
            <p>
              Client : {firstNameBill} {lastNameBill}
            </p>
          </div>
          {/* <div className="btn-order-card">
            <button
              onClick={() => {
                setTogglePopup(true);
              }}
            >
              {t("Delete")}
            </button>
            <button onClick={openPdf}>Visualiser</button>
          </div> */}
        </div>
      </div>
      <PopUp
        togglePopup={togglePopup}
        setTogglePopup={setTogglePopup}
        onSubmite={deleteOrder}
        messagePopup={
          "La suppression est irreversible, êtes-vous certain de vouloir supprimer cette facture ?"
        }
        comeFrom={"billDeletionUser"}
      />
    </div>
  );
};

export default BillsUserCard;
