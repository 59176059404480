import React, { useContext, useEffect, useState } from "react";
import NavBarUserAccount from "../components/NavBarUserAccount";
import { useTranslation } from "react-i18next";
import { ParamContext } from "../contexts/ParamContexts";
import axios from "axios";
import AddressUserCard from "../components/AddressUserCard";
import { NavLink } from "react-router-dom";

const UserAddress = ({ setIsLoading }) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { idUser } = useContext(ParamContext);

  const [address, setAddress] = useState([]);

  useEffect(() => {
    const getAddressData = async (url, setAddressFunction) => {
      try {
        const response = await axios.get(url);
        const infoObj = Object.values(response.data);
        setAddressFunction(infoObj);
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la récupération des données :",
          error
        );
      }
    };

    getAddressData(
      `https://api.sweatteeshop.com/front/users/Address_infos/${idUser}`,
      setAddress
    );
    setIsLoading(false);
  }, [idUser, setIsLoading]);

  let DefaultBilling;
  if (address.length > 0) {
    DefaultBilling = address
      .filter((address) => address.isDefaultBilling === 1)
      .map((address) => (
        <AddressUserCard
          key={address.idAddress}
          idAddress={address.idAddress}
          firstName={address.firstName}
          lastName={address.lastName}
          email={address.email}
          phone={address.phone}
          address={address.address}
          addressComp={address.addressComp}
          city={address.city}
          zip={address.zipCode}
          country={address.country}
          isDefault={true}
        />
      ));
  } else {
    DefaultBilling = (
      <div className="contentEmpty">
        <h3>{t("NoAddress")}</h3>
      </div>
    );
  }

  let DefaultDelivery;
  if (address.length > 0) {
    DefaultDelivery = address
      .filter((address) => address.isDefaultDelivery === 1)
      .map((address) => (
        <AddressUserCard
          key={address.idAddress}
          idAddress={address.idAddress}
          firstName={address.firstName}
          lastName={address.lastName}
          email={address.email}
          phone={address.phone}
          address={address.address}
          addressComp={address.addressComp}
          city={address.city}
          zip={address.zipCode}
          country={address.country}
          isDefault={true}
        />
      ));
  } else {
    DefaultDelivery = (
      <div className="contentEmpty">
        <h3>{t("NoAddress")}</h3>
      </div>
    );
  }

  let addressToDisplay;
  if (address.length > 0) {
    addressToDisplay = address
      .filter(
        (address) =>
          address.isDefaultDelivery === 0 && address.isDefaultBilling === 0
      )
      .map((address) => (
        <AddressUserCard
          key={address.idAddress}
          idAddress={address.idAddress}
          firstName={address.firstName}
          lastName={address.lastName}
          email={address.email}
          phone={address.phone}
          address={address.address}
          addressComp={address.addressComp}
          city={address.city}
          zip={address.zipCode}
          country={address.country}
          isDefault={false}
          activeStep={null}
        />
      ));
  } else {
    addressToDisplay = (
      <div className="contentEmpty">
        <h3>{t("NoAddress")}</h3>
      </div>
    );
  }

  return (
    <div className="user-account-page">
      <div className="left-side-user-account">
        <NavBarUserAccount />
      </div>
      <div className="rigth-user-account">
        <div className="header-user-account">
          <p>{t("Addressbook")}</p>
          <div className="btn-header">
            <NavLink to="/creer-une-adresse">
              <button>{t("AddAnAddress")}</button>
            </NavLink>
          </div>
        </div>
        <div className="body-user-account">
          <div className="address-user-container">
            <div className="address-user">
              <span>{t("DefaultDeliveryAddress")}</span>
              {DefaultDelivery}
            </div>
            <div className="address-user">
              <span>{t("DefaultBillingAddress")}</span>
              {DefaultBilling}
            </div>
          </div>
          <div className="address-user-container">
            <div className="address-user">
              <span>{t("AdditionalAddresses")}</span>
              {addressToDisplay}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserAddress;
