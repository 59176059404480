import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ParamContext } from "../contexts/ParamContexts";
import { Helmet } from "react-helmet";
const LegaleNotice = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  const baseUrl = "https://api.sweatteeshop.com/public/images/";

  const {
    nameCompany,
    statusCompany,
    emailContact,
    webSide,
    addressCompany,
    zipCompany,
    cityCompany,
    countryCompany,
    idCompany,
    capitalCompany,
    vat,
    rcs,
    publisherCompany,
    imgOpenTags,
  } = useContext(ParamContext);

  const formatNumber = (number) => {
    const numberString = number.toString();
    return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  return (
    <div className="container-size-page">
      <Helmet>
        <title>{`${nameCompany} | Mentions légales`}</title>
        <meta
          property="og:title"
          content={`${nameCompany} | Mentions légales`}
        />
        <meta
          name="description"
          content="Consultez nos mentions légales pour en savoir plus sur nous."
        />
        <meta
          property="og:description"
          content="Consultez nos mentions légales pour en savoir plus sur nous."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta
          property="og:url"
          content="https://sweatteeshop.com/mentions-legales"
        />
        <meta property="og:site_name" content="sweatteeshop-officiel" />
        <link
          rel="canonical"
          href={`https://sweatteeshop.com/mentions-legales`}
        />
      </Helmet>
      <h1>{t("LegalNotice")}</h1>
      <span>
        Vous êtes actuellement connectés au site {webSide} qui est édité et
        exploité par la société {nameCompany}.
      </span>
      <ul>
        <li>Dénomination Sociale : {nameCompany}</li>
        <li>Forme juridique : {statusCompany}</li>
        <li>Capital social : {formatNumber(capitalCompany)} euros</li>
        <li>
          Siège social : Siège social: {addressCompany}, {zipCompany}{" "}
          {cityCompany} - {countryCompany}
        </li>
        <li>
          Numéro de CMA : {rcs} : {idCompany}
        </li>
        <li>Numéro de TVA intracommunautaire : TVA intracom : {vat}</li>
        <li>Courriel : {emailContact}</li>
        <li>
          L'éditeur s'engage à respecter l'ensemble des lois concernant la mise
          en place et l'activité d'un site Internet
        </li>
      </ul>
      <ul>
        <li>Directeur de la publication : {publisherCompany}</li>
        <li>Hébergement : HOSTINGER INTERNATIONAL LTD</li>
        <li>
          Siège social hébergeur : 61 Lordou Vironos Street, 6023 Larnaca,
          Chypre.
        </li>
      </ul>
    </div>
  );
};

export default LegaleNotice;
