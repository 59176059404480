import { useTranslation } from "react-i18next";

const GuideSize = ({ toggleSizeGuide, setToggleSizeGuide, comeFrom, des }) => {
  const { t } = useTranslation();

  const tshirtSize = {
    S: { chest: "91 - 96", length: "70" },
    M: { chest: "96 - 101", length: "72" },
    L: { chest: "101 - 106", length: "74" },
    XL: { chest: "106 - 111", length: "76" },
    XXL: { chest: "111 - 116", length: "81.28" },
  };

  const sweatSize = {
    S: { chest: "96 - 101", length: "67" },
    M: { chest: "101 - 106", length: "69" },
    L: { chest: "106 - 111", length: "71" },
    XL: { chest: "111 - 116", length: "73" },
    XXL: { chest: "116 - 121", length: "75" },
  };

  return (
    <div className={`bg-Guide-size ${toggleSizeGuide ? "active" : "inactive"}`}>
      <div className="guide-size-content">
        {comeFrom === "T-shirts" ? (
          <div className="tabl-sizes-container">
            <div className="headSizeGuide">
              <span>T-shirts (cm)</span>
              <div
                className="close-guide"
                onClick={() => {
                  setToggleSizeGuide(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M18 6L6 18M6 6l12 12"
                    stroke="#000"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
            <table className="size-guide-table">
              <thead>
                <tr>
                  <th>{t("sizes")}</th>
                  <th>{t("chest")}</th>
                  <th>{t("Length")}</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(tshirtSize).map(([size, data]) => (
                  <tr key={size}>
                    <td>{size}</td>
                    <td>{data.chest}</td>
                    <td>{data.length}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : comeFrom === "Sweat-shirts" ? (
          <div className="tabl-sizes-container">
            <div className="headSizeGuide">
              <span>Sweat-shirts (cm)</span>
              <div
                className="close-guide"
                onClick={() => {
                  setToggleSizeGuide(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M18 6L6 18M6 6l12 12"
                    stroke="#000"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
            <table className="size-guide-table">
              <thead>
                <tr>
                  <th>{t("sizes")}</th>
                  <th>{t("chest")}</th>
                  <th>{t("Length")}</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(sweatSize).map(([size, data]) => (
                  <tr key={size}>
                    <td>{size}</td>
                    <td>{data.chest}</td>
                    <td>{data.length}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : comeFrom === "descProduct" ? (
          <div className="info-product-container">
            <div className="headSizeGuide">
              <span>{t("ProductDetails")}</span>
              <div
                className="close-guide"
                onClick={() => {
                  setToggleSizeGuide(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M18 6L6 18M6 6l12 12"
                    stroke="#000"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
            <p>{des}</p>
          </div>
        ) : comeFrom === "ShipInfo" ? (
          <div className="info-product-container">
            <div className="headSizeGuide">
              <span>{t("DeliveryReturns")}</span>
              <div
                className="close-guide"
                onClick={() => {
                  setToggleSizeGuide(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M18 6L6 18M6 6l12 12"
                    stroke="#000"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
            <p>{t("shipMessage")}</p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default GuideSize;
