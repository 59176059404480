import axios from "axios";
import React, { useEffect, useState } from "react";

const FilterBarNewsletter = ({ onShare }) => {
  const [toggleArrowDate, setToggleArrowDate] = useState(false);
  const [DateGrowing, setDateGrowing] = useState(false);
  const [DateBill, setDateBill] = useState("descending");
  const [SearchTerm, setSearchTerm] = useState("");
  const [news, setNews] = useState([]);

  const toggleDateFunc = () => {
    setDateGrowing(!DateGrowing);
    setToggleArrowDate(true);
    handleSortChangeDate();
  };

  const handleSortChangeDate = () => {
    const newSortOrder = DateBill === "ascending" ? "descending" : "ascending";
    setDateBill(newSortOrder);
    const sortedOrders = [...news].sort((a, b) => {
      if (newSortOrder === "ascending") {
        return a.dateArticle > b.dateArticle ? 1 : -1;
      } else {
        return a.dateArticle < b.dateArticle ? 1 : -1;
      }
    });
    setNews(sortedOrders);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [ordersRes] = await Promise.all([
          axios.get("https://api.sweatteeshop.com/back/newsletter/datas"),
        ]);

        const ordersObj = Object.values(ordersRes.data).sort(
          (a, b) => new Date(b.dateBill) - new Date(a.dateBill)
        );

        let filteredNews = ordersObj;

        if (SearchTerm) {
          filteredNews = filteredNews?.filter(
            (News) =>
              News?.title?.toLowerCase().includes(SearchTerm.toLowerCase()) ||
              News?.Author?.toLowerCase().includes(SearchTerm.toLowerCase()) ||
              News?.description?.toString().includes(SearchTerm.toLowerCase())
          );
        }

        setNews(filteredNews);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [SearchTerm]);

  useEffect(() => {
    onShare(news);
  }, [onShare, news]);

  const resetSort = () => {
    window.location.reload();
  };

  return (
    <div className="filter-bar-back-container">
      <div className="sort-data-container" onClick={() => toggleDateFunc()}>
        Dates{" "}
        {toggleArrowDate && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 327 512.23"
            className={`arrowSortProduct ${DateGrowing ? "growing" : ""}`}
          >
            <path
              fillRule="nonzero"
              d="M301.64 204.53c10.06-.51 17.22-3.9 21.37-10.2 10.52-15.76-1.96-31.28-12.06-42.65L183.12 9.5c-11.46-12.67-27.78-12.67-39.23 0L18.97 148.45c-10.7 11.9-26.4 28.79-15 45.88 4.18 6.3 11.34 9.69 21.39 10.2h76.34v301.36c0 3.49 2.86 6.34 6.34 6.34h110.93c3.47 0 6.33-2.86 6.33-6.34V204.53h76.34z"
            />
          </svg>
        )}
      </div>
      {(toggleArrowDate) && (
        <div className="cancelFilter">
          <span
            className="btnCancelFilter"
            onClick={() => {
              resetSort();
            }}
          >
            Retirer les filtres
          </span>
        </div>
      )}
      <div className="searech-bar">
        <input
          type="search"
          placeholder="Rechercher N°, Montant..."
          value={SearchTerm}
          onChange={handleSearchChange}
        />
      </div>
    </div>
  );
};

export default FilterBarNewsletter;
