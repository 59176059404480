import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoadingPage from "./pages/LoadingPage";
import { ParamContextProvider } from "./contexts/ParamContexts.js";
import HomePage from "./pages/HomePage";
import NavbarFront from "./components/NavbarFront";
import Tshirts from "./pages/Tshirts";
import Sweatshirt from "./pages/Sweatshirt";
import Bags from "./pages/Bags";
import Cart from "./pages/Cart";
import LoginPage from "./pages/LoginPage";
import NewsIn from "./pages/NewsIn";
import Footer from "./components/Footer";
import GuideSizes from "./pages/GuideSizes";
import ChangesAndReturns from "./pages/ChangesAndReturns";
import Delivery from "./pages/Delivery";
import AboutUs from "./pages/AboutUs";
import Privacypolicy from "./pages/Privacypolicy";
import LegaleNotice from "./pages/LegaleNotice";
import CGV from "./pages/CGV";
import CollectionsPage from "./pages/CollectionsPage";
import PageProduct from "./pages/PageProduct";
import SignUp from "./pages/SignUp";
import PrivateRouteFront from "./contexts/PrivateRouteFront";
import PasswordRecPage from "./pages/PasswordRecPage";
import ConfirmAccount from "./pages/ConfirmAccount";
import CheckoutPage from "./pages/CheckoutPage";
import GuessCheckout from "./pages/GuessCheckout";
import CheckoutUser from "./pages/CheckoutUser";
import PaymentPage from "./pages/PaymentPage";
import PaymentAccepted from "./pages/PaymentAccepted";
import UserAddress from "./pages/UserAddress";
import CreateAddressUser from "./pages/CreateAddressUser";
import ModificationAddressUser from "./pages/ModificationAddressUser";
import SettingsAccountUser from "./pages/SettingsAccountUser";
import OrderUserPage from "./pages/OrderUserPage";
import DetailsOrder from "./pages/DetailsOrder";
import LoginBack from "./pages/LoginBack";
import PrivateRouteBack from "./contexts/PrivateRouteBack";
import OrderManagement from "./pages/OrderManagement";
import OderDeatailBack from "./pages/OderDeatailBack";
import BillingPageBack from "./pages/BillingPageBack";
import CreateBill from "./pages/CreateBill";
import ProductsManagement from "./pages/ProductsManagement";
import CreateProduct from "./pages/CreateProduct";
import EditeProduct from "./pages/EditeProduct";
import AdditinalManagemntProduct from "./pages/AdditinalManagemntProduct";
import CreateNewsletter from "./pages/CreateNewsletter";
import NewsletterManagement from "./pages/NewsletterManagement";
import ArticlePage from "./pages/ArticlePage";
import EditionArticle from "./pages/EditionArticle";
import AdminManagement from "./pages/AdminManagement";
import EditeAdmin from "./pages/EditeAdmin";
import CreateAdmin from "./pages/CreateAdmin";
import Settings from "./pages/Settings";
import MediaManagement from "./pages/MediaManagement";
import DisplayerPopup from "./components/DisplayerPopup";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import BillsUser from "./pages/BillsUser";
import CreateAddressChackout from "./pages/CreateAddressChackout";
import axios from "axios";
import WelcomPage from "./pages/WelcomPage";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [logo, setLogo] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [settingsResponse] = await Promise.all([
          axios.get("https://api.sweatteeshop.com/front/settings"),
        ]);

        const settingsObj = Object.values(settingsResponse.data);
        settingsObj.forEach((setting) => {
          switch (setting.refSettings) {
            case "logo":
              setLogo(setting.mediaSetting);
              break;
            default:
              break;
          }
        });
      } catch (error) {
        console.error("Une erreur s'est produite : ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const initialOptions = {
    "client-id":
      "AdXdF0nLqwfA8NRGLWvI0-y0atgeQTePcAhjS1B8w9r-65zHUnSEnhEZmQIg4arMewN5Sj_0Vy6y5ARl",
    currency: "EUR",
    intent: "capture",
  };

  // Aai3xJ256BIP2edmIlZDDLoESMHP6MSdrZnueT0ljTqT9dSYOJZn_uEWOmslZddT7P0W7sHiMTwlaPQ0

  return (
    <BrowserRouter>
      <ParamContextProvider>
        <PayPalScriptProvider options={initialOptions} >
          {isLoading ? (
            <LoadingPage />
          ) : (
            <>
              <NavbarFront />
              <div className="body-website">
                <Routes>
                  <Route
                    path="/"
                    element={<WelcomPage setIsLoading={setIsLoading} />}
                  />
                  <Route element={<PrivateRouteBack />}>
                  <Route
                      path="/acceuil"
                      element={<HomePage setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/nouveautés"
                      element={<NewsIn setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/t-shirts"
                      element={<Tshirts setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/sweat-shirts"
                      element={<Sweatshirt setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/accessoires"
                      element={<Bags setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/collection/:id"
                      element={<CollectionsPage setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/panier"
                      element={<Cart setIsLoading={setIsLoading} />}
                    />
                    <Route path="/connexion" element={<LoginPage />} />
                    <Route path="/guide" element={<GuideSizes />} />
                    <Route
                      path="/echanges-et-retours"
                      element={<ChangesAndReturns />}
                    />
                    <Route path="/livraisons" element={<Delivery />} />
                    <Route path="/a-propos" element={<AboutUs />} />
                    <Route
                      path="/protection-des-données"
                      element={<Privacypolicy />}
                    />
                    <Route
                      path="/mentions-legales"
                      element={<LegaleNotice />}
                    />
                    <Route path="/termes-et-conditions" element={<CGV />} />
                    <Route path="/creer-un-compte" element={<SignUp />} />
                    <Route
                      path="/produit/:id"
                      element={<PageProduct setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/reinisialisation-mot-de-passe"
                      element={<PasswordRecPage />}
                    />
                    <Route
                      path="/confirmation-inscription"
                      element={<ConfirmAccount setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/checkout"
                      element={<CheckoutPage setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/checkout/guess"
                      element={<GuessCheckout setIsLoading={setIsLoading} />}
                    />
                    <Route path="/checkout/payment" element={<PaymentPage />} />
                    <Route
                      path="/confirmation-de-paiement"
                      element={
                        <PaymentAccepted
                          setIsLoading={setIsLoading}
                          isLoading={isLoading}
                        />
                      }
                    />
                    <Route element={<PrivateRouteFront />}>
                      <Route
                        path="/address-utilisateur"
                        element={<UserAddress setIsLoading={setIsLoading} />}
                      />
                      <Route
                        path="/creer-une-adresse"
                        element={<CreateAddressUser />}
                      />
                      <Route
                        path="/modification-adresse/:id"
                        element={
                          <ModificationAddressUser
                            setIsLoading={setIsLoading}
                          />
                        }
                      />
                      <Route
                        path="/factures"
                        element={<BillsUser setIsLoading={setIsLoading} />}
                      />
                      <Route
                        path="/commandes"
                        element={<OrderUserPage setIsLoading={setIsLoading} />}
                      />
                      <Route
                        path="/commande/:id"
                        element={<DetailsOrder setIsLoading={setIsLoading} />}
                      />
                      <Route
                        path="/parametres-du-compte"
                        element={<SettingsAccountUser />}
                      />
                      <Route
                        path="/checkout/user"
                        element={<CheckoutUser setIsLoading={setIsLoading} />}
                      />
                      <Route
                        path="/checkout/creer-une-adresse"
                        element={<CreateAddressChackout />}
                      />
                    </Route>
                  </Route>
                  <Route
                    path="/back/login"
                    element={<LoginBack setIsLoading={setIsLoading} />}
                  />
                  <Route element={<PrivateRouteBack />}>
                    <Route
                      path="/back/gestion-des-commandes"
                      element={<OrderManagement setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/back/commandes/:id"
                      element={<OderDeatailBack setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/back/gestion-des-factures"
                      element={<BillingPageBack setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/back/creer-une-facture"
                      element={<CreateBill setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/back/gestion-des-produits"
                      element={
                        <ProductsManagement setIsLoading={setIsLoading} />
                      }
                    />
                    <Route
                      path="/back/creer-un-produit"
                      element={<CreateProduct setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/back/modification/produit/:id"
                      element={<EditeProduct setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/back/produit/gestion-des-parametres"
                      element={
                        <AdditinalManagemntProduct
                          setIsLoading={setIsLoading}
                        />
                      }
                    />
                    <Route
                      path="/back/gestion-des-newsletter"
                      element={
                        <NewsletterManagement setIsLoading={setIsLoading} />
                      }
                    />
                    <Route
                      path="/back/creation-de-newsletter"
                      element={<CreateNewsletter setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/back/newsletter/article/:id"
                      element={<ArticlePage setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/back/newsletter/edition/:id"
                      element={<EditionArticle setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/back/gestion-des-admin"
                      element={<AdminManagement setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/back/admin/edition/:id"
                      element={<EditeAdmin setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/back/creer-un-administrateur"
                      element={<CreateAdmin setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/back/parametre-du-site"
                      element={<Settings setIsLoading={setIsLoading} />}
                    />
                    <Route
                      path="/back/gestion-des-medias"
                      element={<MediaManagement setIsLoading={setIsLoading} />}
                    />
                  </Route>
                  <Route
                    path="*"
                    element={<WelcomPage setIsLoading={setIsLoading} />}
                  />
                </Routes>
                <Footer />
              </div>
            </>
          )}
          <DisplayerPopup />
        </PayPalScriptProvider>
      </ParamContextProvider>
    </BrowserRouter>
  );
}

export default App;
