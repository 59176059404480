import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import countriesFr from "../countriesFr.json";
import countriesEn from "../countriesEn.json";
import { ParamContext } from "../contexts/ParamContexts";
import { NavLink, useNavigate } from "react-router-dom";

const CreateAddressChackout = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const language = i18n.language || "fr";
  const jsonFile = language === "fr" ? countriesFr : countriesEn;

  const { idUser, comeFrom } = useContext(ParamContext);
  const [message, setMessage] = useState("");

  const validationSchema = Yup.object().shape({
    country: Yup.string().test(
      "Pays",
      "Ce champ est obligatoire",
      function (country) {
        return country !== "Pays *";
      }
    ),
    firstName: Yup.string().required(t("LastName") + " " + t("missing")),
    lastName: Yup.string().required(t("FirstName") + " " + t("missing")),
    email: Yup.string()
      .required("Email " + t("missing"))
      .email("Email " + t("invalid") + " (sweatteeshop@gmail.com)"),
    phone: Yup.string()
      .required(t("Phone") + " " + t("missing"))
      .matches(/([0-9])/, t("Phone") + " " + t("invalid")),
    address: Yup.string().required(t("Address") + " " + t("missing")),
    city: Yup.string().required(t("City") + " " + t("missing")),
    zip: Yup.string()
      .required(t("Zip") + " " + t("missing"))
      .matches(/([0-9])/, t("Zip") + " " + t("invalid")),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  function onSubmit(data) {
    const formatData = {
      idUser,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      address: data.address,
      adressComp: data.adressComp,
      city: data.city,
      zip: data.zip,
      country: data.country,
      DefaultBilling: comeFrom === "bill" ? "true" : data.DefaultBilling,
      DefaultDelivery: comeFrom === "delivery" ? "true" : "false",
    };

    axios
      .post(
        "https://api.sweatteeshop.com/front/users/registration/adress",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        setMessage(response.data.message);
        if (response.data.success === true) {
          navigate("/checkout/user");
          reset();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  console.log(message);

  return (
    <div className="user-account-page">
      <div className="rigth-user-account">
        <div className="header-user-account">
          <p>{t("CreatingANewAddress")}</p>
        </div>
        <div className="body-user-account">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="checkout-form-container">
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="lastName"
                    name="lastName"
                    placeholder={t("LastName")}
                    {...register("lastName")}
                  />
                  {errors.lastName && (
                    <small className="errorInputConfig">
                      {errors.lastName?.message}
                    </small>
                  )}
                </div>
                <div className="input-checkout-form-nd">
                  <input
                    id="firstName"
                    name="firstName"
                    placeholder={t("FirstName")}
                    {...register("firstName", {})}
                  />
                  {errors.firstName && (
                    <small className="errorInputConfig">
                      {errors.firstName?.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="email"
                    name="email"
                    placeholder="Email"
                    {...register("email")}
                  />
                  {errors.email && (
                    <small className="errorInputConfig">
                      {errors.email?.message}
                    </small>
                  )}
                </div>
                <div className="input-checkout-form-nd">
                  <input
                    id="phone"
                    name="phone"
                    placeholder={t("Phone")}
                    {...register("phone")}
                  />
                  <small className="errorInputConfig">
                    {errors.phone?.message}
                  </small>
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="address"
                    name="address"
                    placeholder={t("Address") + " " + t("Line") + " 1"}
                    {...register("address")}
                  />
                  {errors.address && (
                    <small className="errorInputConfig">
                      {errors.address?.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="adressComp"
                    name="adressComp"
                    placeholder={t("Address") + " " + t("Line") + " 2"}
                    {...register("adressComp")}
                  />
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="city"
                    name="city"
                    placeholder={t("City")}
                    {...register("city")}
                  />
                  {errors.city && (
                    <small className="errorInputConfig">
                      {errors.city?.message}
                    </small>
                  )}
                </div>
                <div className="input-checkout-form-nd">
                  <input
                    type="text"
                    id="zip"
                    name="zip"
                    placeholder={t("Zip")}
                    {...register("zip")}
                  />
                  {errors.zip && (
                    <small className="errorInputConfig">
                      {errors.zip?.message}
                    </small>
                  )}
                </div>
                <div className="selectCase-checkout">
                  <select
                    className="ItemSelect"
                    name="country"
                    {...register("country")}
                  >
                    {jsonFile.map((country) => (
                      <option key={country.code}>{country.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              {comeFrom === "delivery" && (
                <div className="payment-methode">
                  <input
                    type="checkbox"
                    name="DefaultBilling"
                    id="DefaultBilling"
                    {...register("DefaultBilling")}
                  />
                  <div className="label-card-select">
                    <label htmlFor="DefaultBilling">
                      {t("UseAsDefaultBillingAddress")}
                    </label>
                  </div>
                </div>
              )}
              <div className="btn-checkout-container">
                <NavLink to="/checkout/user">
                  <button type="button">
                    <span>{t("back")}</span>
                  </button>
                </NavLink>
                <button type="submit">
                  <span>{t("save")}</span>
                </button>
              </div>
            </div>
          </form>
          <div>{message}</div>
        </div>
      </div>
    </div>
  );
};

export default CreateAddressChackout;
