import React, { useEffect, useState } from "react";
import NavbarBack from "../components/NavbarBack";
import NavSection from "../components/NavSection";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";

const EditionArticle = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let { id } = useParams();

  const [article, setArticle] = useState([]);
  const [togglemessage, setToggleMessage] = useState("");

  useEffect(() => {
    const getOrder = async () => {
      await axios
        .get(`https://api.sweatteeshop.com/back/newsletter/data/${id}`)
        .then((res) => {
          const productsObj = Object.values(res.data);
          setArticle(productsObj[0]);
        });
    };
    getOrder();
  }, [id]);

  const EditeArticle = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const ArticleTitle = formData.get("ArticleTitle");
    const Auteur = formData.get("Auteur");
    const DescArticle = formData.get("DescArticle");
    const articleContent = formData.get("articleContent");

    try {
      const response = await axios.post(
        "https://api.sweatteeshop.com/back/newsletter/edit",
        { ArticleTitle, Auteur, DescArticle, articleContent, id },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      // alert(response.data);
      setToggleMessage(response.data.message);
    } catch (error) {
      console.log(error.message);
      setToggleMessage(
        "Une erreur s'est produite lors de la création du produit."
      );
    }
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavSection />
        <div className="admin-content-page">
          <div className="content-back-container">
            <div className="header-content-admin">
              <NavLink to="/back/gestion-des-newsletter">
                <button>Retour</button>
              </NavLink>
            </div>
            <form onSubmit={EditeArticle}>
              <div className="checkout-form-container">
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input
                      id="ArticleTitle"
                      name="ArticleTitle"
                      placeholder="Titre"
                      defaultValue={article?.title || ""}
                    />
                  </div>
                  <div className="input-checkout-form-nd">
                    <input
                      id="Auteur"
                      name="Auteur"
                      placeholder="Auteur"
                      defaultValue={article?.Author || ""}
                    />
                  </div>
                </div>
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input
                      id="DescArticle"
                      name="DescArticle"
                      placeholder="Description"
                      defaultValue={article?.description || ""}
                    />
                  </div>
                </div>
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <textarea
                      rows="50"
                      id="articleContent"
                      name="articleContent"
                      placeholder="Contenu"
                      defaultValue={article?.content || ""}
                    />
                  </div>
                </div>
                <div className="btn-checkout-container">
                  <button type="submit">
                    <span>Enregistrer</span>
                  </button>
                </div>
              </div>
            </form>
            <div>{togglemessage}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditionArticle;
