import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ParamContext } from "../contexts/ParamContexts";
import PaypalCheckoutButton from "./PaypalCheckoutButton";

const CheckoutPayment = ({ isActive, handlePreviousStep }) => {
  const { t } = useTranslation();

  const { userStatue } = useContext(ParamContext);

  return (
    <form className="form-delivery">
      <div>
        {userStatue ? 2 : 3}. {t("Payment")}.
      </div>
      {isActive && (
        <div className="checkout-form-container">
          <div className="payment-methode-container">
            <div className="payment-methode">
              <NavLink to="/checkout/payment">
                <div className="Card-btn-choice">
                  <img src="../../img/visa-icon.png" alt="visa card" />
                  <img src="../../img/logo_mastercard.png" alt="mastercard" />
                  <img src="../../img/icon-cb.png" alt="CB" />
                </div>
              </NavLink>
            </div>
            <div className="payment-methode">
              <PaypalCheckoutButton />
            </div>
          </div>
          <div className="btn-checkout-container">
            <button type="button" onClick={handlePreviousStep}>
              <span>{t("previous")}</span>
            </button>
          </div>
        </div>
      )}
    </form>
  );
};

export default CheckoutPayment;
