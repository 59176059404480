import { useEffect, useState } from "react";
import FilterBarProduct from "../components/FilterBarProduct";
import NavbarBack from "../components/NavbarBack";
import NavSection from "../components/NavSection";
import ProductCardBack from "../components/ProductCardBack";

const ProductsManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [ordersData, setOrdersData] = useState([]);
  const [resultsPage, setResultsPage] = useState(10);
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreData = () => {
    if (!hasMore) return;
    setResultsPage(resultsPage + 10);
  };

  const sliceData = ordersData.slice(0, resultsPage);

  let dataToDisplay;
  if (sliceData.length > 0) {
    dataToDisplay = sliceData.map((product) => (
      <ProductCardBack key={product.idProduct} idProduct={product.idProduct} />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>Aucune commande trouvée.</h3>
      </div>
    );
  }

  const shareProducts = (datas) => {
    setOrdersData(datas);
    setHasMore(datas.length > 10);
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavSection />
        <div className="admin-content-page">
          <FilterBarProduct onShare={shareProducts} />
          <div className="content-back-container">{dataToDisplay}</div>
          {hasMore && (
            <div className="loadMoreButtonContainer">
              <button className="loadMoreButton" onClick={fetchMoreData}>
                Plus de commandes
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductsManagement;
