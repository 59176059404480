import React, { useContext, useEffect, useState } from "react";
import NavbarBack from "../components/NavbarBack";
import NavSection from "../components/NavSection";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import countriesFr from "../countriesFr.json";
import countriesEn from "../countriesEn.json";
import { ParamContext } from "../contexts/ParamContexts";

const EditeAdmin = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let { id } = useParams();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const { adminData } = useContext(ParamContext);

  useEffect(() => {
    if (adminData.adminAccess === 0) {
      navigate("/back/gestion-des-commandes");
    }
  }, [adminData, navigate]);

  const language = i18n.language || "fr";
  const jsonFile = language === "fr" ? countriesFr : countriesEn;

  const [message, setMessage] = useState("");
  const [country, setCoutry] = useState("");
  const [admin, setAdmin] = useState([]);
  const [statut, setStatut] = useState("");

  useEffect(() => {
    const getOrder = async () => {
      await axios
        .get(`https://api.sweatteeshop.com/back/admin/data/${id}`)
        .then((res) => {
          const productsObj = Object.values(res.data);
          setAdmin(productsObj[0]);
          setStatut(productsObj[0].adminAccess);
          setCoutry(productsObj[0].country);
        });
    };
    getOrder();
  }, [id]);

  const EditeDataAdmin = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    const lastName = formData.get("lastName");
    const firstName = formData.get("firstName");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const login = formData.get("login");
    const password = formData.get("password");
    const address = formData.get("address");
    const addressComp = formData.get("addressComp");
    const city = formData.get("city");
    const zip = formData.get("zip");

    try {
      const response = await axios.post(
        "https://api.sweatteeshop.com/back/admin/edite",
        {
          id,
          lastName,
          firstName,
          email,
          phone,
          login,
          password,
          address,
          addressComp,
          city,
          zip,
          country,
          statut,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      // alert(response.data);
      setMessage(response.data.message);
    } catch (error) {
      console.log(error.message);
      setMessage("Une erreur s'est produite lors de la création du produit.");
    }
  };

  const handleChangeCountry = (event) => {
    setCoutry(event.target.value);
  };

  const handleChangeStatut = (event) => {
    setStatut(event.target.value);
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavSection />
        <div className="admin-content-page">
          <div className="header-content-admin">
            <NavLink to="/back/gestion-des-admin">
              <button>Retour</button>
            </NavLink>
          </div>
          <div className="content-edit-back">
            <form className="checkout-form-container" onSubmit={EditeDataAdmin}>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="lastName"
                    name="lastName"
                    placeholder="Nom"
                    defaultValue={admin?.lastName || ""}
                  />
                </div>
                <div className="input-checkout-form-nd">
                  <input
                    id="firstName"
                    name="firstName"
                    placeholder="Prenom"
                    defaultValue={admin?.firstName || ""}
                  />
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="email"
                    name="email"
                    placeholder="Email"
                    defaultValue={admin?.email || ""}
                  />
                </div>
                <div className="input-checkout-form-nd">
                  <input
                    id="phone"
                    name="phone"
                    placeholder="Telephone"
                    defaultValue={admin?.phone || ""}
                  />
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="login"
                    name="login"
                    placeholder="Identifiant"
                    defaultValue={admin?.login || ""}
                  />
                </div>
                <div className="input-checkout-form-nd">
                  <input id="password" name="password" placeholder="password" />
                </div>
              </div>
              <div className="items-container">
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input
                      id="address"
                      name="address"
                      placeholder="Adresse"
                      defaultValue={admin?.adress || ""}
                    />
                  </div>
                </div>
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input
                      id="addressComp"
                      name="addressComp"
                      placeholder="Complement d'adresse"
                      defaultValue={admin?.adressComp || ""}
                    />
                  </div>
                </div>
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input
                      id="city"
                      name="city"
                      placeholder="Ville"
                      defaultValue={admin?.city || ""}
                    />
                  </div>
                  <div className="input-checkout-form-nd">
                    <input
                      type="text"
                      id="zip"
                      name="zip"
                      placeholder="Code postal"
                      defaultValue={admin?.zip || ""}
                    />
                  </div>
                  <div className="selectCase-checkout">
                    <select
                      className="ItemSelect"
                      name="country"
                      value={country || admin.country}
                      onChange={handleChangeCountry}
                    >
                      <option value="all" disabled>
                        Selectionnez une categorie
                      </option>
                      {jsonFile.map((country) => (
                        <option key={country.code}>{country.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="items-container">
                <div className="selectCase-checkout">
                  <select
                    className="ItemSelect"
                    name="statut"
                    value={statut || admin.adminAccess}
                    onChange={handleChangeStatut}
                  >
                    <option value={0}>Collaborateur</option>
                    <option value={1}>Administrateur</option>
                  </select>
                </div>
                <div className="btn-checkout-container">
                  <button type="submit">
                    <span>Enregistrer</span>
                  </button>
                </div>
              </div>
              <div>{message}</div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditeAdmin;
