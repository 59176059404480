import React, { useContext, useEffect, useState } from "react";
import NavbarBack from "../components/NavbarBack";
import NavSection from "../components/NavSection";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import countriesFr from "../countriesFr.json";
import countriesEn from "../countriesEn.json";
import { ParamContext } from "../contexts/ParamContexts";

const CreateAdmin = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let { id } = useParams();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const { adminData } = useContext(ParamContext);

  useEffect(() => {
    if (adminData.adminAccess === 0) {
      navigate("/back/gestion-des-commandes");
    }
  }, [adminData, navigate]);

  const language = i18n.language || "fr";
  const jsonFile = language === "fr" ? countriesFr : countriesEn;

  const [message, setMessage] = useState("");

  const CreateAdmin = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    const lastName = formData.get("lastName");
    const firstName = formData.get("firstName");
    const email = formData.get("email");
    const phone = formData.get("phone");
    const login = formData.get("login");
    const password = formData.get("password");
    const address = formData.get("address");
    const addressComp = formData.get("addressComp");
    const city = formData.get("city");
    const zip = formData.get("zip");
    const country = formData.get("country");
    const statut = formData.get("statut");

    try {
      const response = await axios.post(
        "https://api.sweatteeshop.com/back/admin/create",
        {
          id,
          lastName,
          firstName,
          email,
          phone,
          login,
          password,
          address,
          addressComp,
          city,
          zip,
          country,
          statut,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      // alert(response.data);
      setMessage(response.data.message);
    } catch (error) {
      console.log(error.message);
      setMessage("Une erreur s'est produite lors de la création du produit.");
    }
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavSection />
        <div className="admin-content-page">
          <div className="content-edit-back">
            <form className="checkout-form-container" onSubmit={CreateAdmin}>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input id="lastName" name="lastName" placeholder="Nom" />
                </div>
                <div className="input-checkout-form-nd">
                  <input id="firstName" name="firstName" placeholder="Prenom" />
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input id="email" name="email" placeholder="Email" />
                </div>
                <div className="input-checkout-form-nd">
                  <input id="phone" name="phone" placeholder="Telephone" />
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input id="login" name="login" placeholder="Identifiant" />
                </div>
                <div className="input-checkout-form-nd">
                  <input id="password" name="password" placeholder="password" />
                </div>
              </div>
              <div className="items-container">
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input id="address" name="address" placeholder="Adresse" />
                  </div>
                </div>
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input
                      id="addressComp"
                      name="addressComp"
                      placeholder="Complement d'adresse"
                    />
                  </div>
                </div>
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input id="city" name="city" placeholder="Ville" />
                  </div>
                  <div className="input-checkout-form-nd">
                    <input
                      type="text"
                      id="zip"
                      name="zip"
                      placeholder="Code postal"
                    />
                  </div>
                  <div className="selectCase-checkout">
                    <select className="ItemSelect" name="country">
                      <option value="all" disabled>
                        Selectionnez une categorie
                      </option>
                      {jsonFile.map((country) => (
                        <option key={country.code}>{country.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="items-container">
                <div className="selectCase-checkout">
                  <select className="ItemSelect" name="statut">
                    <option value={0}>Collaborateur</option>
                    <option value={1}>Administrateur</option>
                  </select>
                </div>
                <div className="btn-checkout-container">
                  <button type="submit">
                    <span>Enregistrer</span>
                  </button>
                </div>
              </div>
              <div>{message}</div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAdmin;
