import { useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ParamContext } from "./ParamContexts";


const PrivateRouteBack = () => {
  
  const { adminStatue } = useContext(ParamContext);

  const navigate = useNavigate();

  return  adminStatue ? <Outlet /> : navigate("/back/login");
};

export default PrivateRouteBack;
