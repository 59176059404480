import React, { useContext, useEffect, useState } from "react";
import NavBarUserAccount from "../components/NavBarUserAccount";
import { useTranslation } from "react-i18next";
import { ParamContext } from "../contexts/ParamContexts";
import axios from "axios";
import PopUp from "../components/PopUp";
import { useNavigate } from "react-router-dom";

const SettingsAccountUser = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { usersDatas, idUser, handleLogoutUser } = useContext(ParamContext);

  const [message, setMessage] = useState("");
  const [togglePopup, setTogglePopup] = useState(false);

  const navigate = useNavigate();

  const handleChange = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const email = formData.get("email");
    const firstName = formData.get("firstName");
    const lastName = formData.get("lastName");

    if (/[^a-zA-Z\s]/.test(lastName) || /[^a-zA-Z\s]/.test(firstName)) {
      setMessage(t("dontUsSpecialCaracteres"));
      return;
    }

    if (
      firstName === usersDatas.firstName &&
      lastName === usersDatas.lastName &&
      email === usersDatas.email
    ) {
      setMessage(t("indiqueModif"));
      return;
    }

    axios
      .post(
        "https://api.sweatteeshop.com/front/users/edite/personal_info",
        { firstName, lastName, email, idUser },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        setMessage(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePassword = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const oldPass = formData.get("oldPass");
    const newPass = formData.get("newPass");
    const firstName = usersDatas.firstName;
    const email = usersDatas.email;

    axios
      .post(
        "https://api.sweatteeshop.com/front/users/edite/password",
        { oldPass, newPass, idUser, firstName, email },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        setMessage(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteAcount = () => {
    axios
      .post(
        "https://api.sweatteeshop.com/front/users/delete/user",
        { idUser },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        handleLogoutUser();
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="user-account-page">
      <div className="left-side-user-account">
        <NavBarUserAccount />
      </div>
      <div className="rigth-user-account">
        <div className="header-user-account">
          <p>{t("Settings")}</p>
          <span>{t("EditingAccountInformation")}</span>
        </div>
        <div className="body-user-account">
          <form onSubmit={handleChange}>
            <div className="checkout-form-container">
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="lastName"
                    name="lastName"
                    defaultValue={usersDatas.lastName || ""}
                    placeholder={t("LastName")}
                  />
                </div>
                <div className="input-checkout-form-nd">
                  <input
                    id="firstName"
                    name="firstName"
                    defaultValue={usersDatas.firstName || ""}
                    placeholder={t("FirstName")}
                  />
                </div>
              </div>
              <div className="Account-user-row">
                <div className="input-checkout-form-nd">
                  <input
                    id="email"
                    name="email"
                    defaultValue={usersDatas.email || ""}
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="btn-checkout-container">
                <button type="submit">
                  <span>{t("save")}</span>
                </button>
              </div>
            </div>
          </form>
          <form onSubmit={handleChangePassword}>
            <div className="checkout-form-container">
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="oldPass"
                    name="oldPass"
                    placeholder={t("old") + " " + t("Password")}
                  />
                </div>
                <div className="input-checkout-form-nd">
                  <input
                    id="newPass"
                    name="newPass"
                    placeholder={t("new") + " " + t("Password")}
                  />
                </div>
              </div>
              <div className="btn-checkout-container">
                <button type="submit">
                  <span>{t("save")}</span>
                </button>
              </div>
            </div>
          </form>
          <div>{message}</div>
          <div className="btn-delete-user">
            <button type="submit" onClick={() => setTogglePopup(true)}>
              <span>{t("deleteAccount")}</span>
            </button>
          </div>
        </div>
      </div>
      <PopUp
        togglePopup={togglePopup}
        setTogglePopup={setTogglePopup}
        messagePopup={"Etes vous certain de vouloir supprimer votre compte?"}
        comeFrom={"deleteAccount"}
        onSubmite={deleteAcount}
      />
    </div>
  );
};

export default SettingsAccountUser;
