import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ParamContext } from "../contexts/ParamContexts";
import CurtainsMenu from "./CurtainsMenu";
import { useTranslation } from "react-i18next";

const NavbarFront = () => {
  const { t } = useTranslation();

  const { logo, collections, cartItemCount, userStatue, totalQuantity } =
    useContext(ParamContext);

  const [toggleSubMenu, setToggleSubMenu] = useState(false);
  const [toggleSubMenuColl, setToggleSubMenuColl] = useState(false);
  const [toggleCross, setToggleCross] = useState(false);
  const [toggleNav, setToggleNav] = useState(false);
  const [showCollections, setShowCollections] = useState(false);

  const subMenuRef = useRef();
  const curtainsMenuRef = useRef();
  const subMenuColRef = useRef();
  const location = useLocation();

  useEffect(() => {
    if (
      !(
        location.pathname.startsWith("/back/") ||
        location.pathname.startsWith("/checkout") ||
        location.pathname === "/desinscription-newsletter" ||
        location.pathname === "/"
      )
    ) {
      let handler = (e) => {
        if (!subMenuRef.current.contains(e.target)) {
          setToggleSubMenu(false);
        }
        if (!subMenuColRef.current.contains(e.target)) {
          setToggleSubMenuColl(false);
        }
        if (!curtainsMenuRef.current.contains(e.target)) {
          setToggleNav(false);
          setToggleCross(false);
          setShowCollections(false);
        }
      };
      document.addEventListener("mousedown", handler);
      return () => {
        document.removeEventListener("mousedown", handler);
      };
    }
  }, [location]);

  const closeSubMenu = () => {
    setToggleSubMenu(false);
  };

  const toggleNavFunc = () => {
    setToggleNav(!toggleNav);
    setToggleCross(!toggleCross);
    setShowCollections(false);
  };

  return (
    <div>
      {location.pathname.startsWith("/checkout") && (
        <nav className="navBar navBarCheckout">
          <div className="img-nav-container">
            <a href="/">
              <img src={logo} alt="logo" />
            </a>
          </div>
        </nav>
      )}
      {!(
        location.pathname.startsWith("/back/") ||
        location.pathname.startsWith("/checkout") ||
        location.pathname === "/desinscription-newsletter" ||
        location.pathname === "/"
      ) && (
        <nav className="navBar">
          <div className="menu-curtains-btn" ref={curtainsMenuRef}>
            <div className={toggleCross ? "btn-crossed" : "menuButton"}>
              <div className="menuButton" onClick={toggleNavFunc}>
                <div className="menu-btn-burger"></div>
              </div>
            </div>
            <CurtainsMenu
              toggleNav={toggleNav}
              setToggleNav={setToggleNav}
              setToggleCross={setToggleCross}
              showCollections={showCollections}
              setShowCollections={setShowCollections}
            />
          </div>
          <div
            className={`bg-curtains-menu ${toggleNav ? "active" : "inactive"}`}
          ></div>
          <div className="img-nav-container">
            <a href="/acceuil">
              <img src={logo} alt="logo" />
            </a>
          </div>
          <div className="nav-middle-container">
            <li>
              <NavLink to="/nouveautés">{t("News")}</NavLink>
            </li>
            <div className="product-button" ref={subMenuRef}>
              <li
                className="transparent-button"
                onClick={() => {
                  setToggleSubMenu(!toggleSubMenu);
                }}
              >
                {t("Products")}
              </li>
              <ul
                className={`SubMenu ${toggleSubMenu ? "active" : "inactive"}`}
              >
                <li>
                  <NavLink to="/t-shirts" onClick={closeSubMenu}>
                    T-SHIRTS
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/sweat-shirts" onClick={closeSubMenu}>
                    SWEAT-SHIRTS
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/accessoires" onClick={closeSubMenu}>
                    {t("Accessories")}
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="collection-button" ref={subMenuColRef}>
              <li
                onClick={() => {
                  setToggleSubMenuColl(!toggleSubMenuColl);
                }}
              >
                COLLECTIONS
              </li>
              <ul
                className={`SubMenuCol ${
                  toggleSubMenuColl ? "active" : "inactive"
                }`}
              >
                {collections.map((item) => {
                  return (
                    <a
                      href={`/collection/${item.idCollection}`}
                      key={item.idCollection}
                    >
                      <li className="filterItem">{item.nameCollection}</li>
                    </a>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="nav-rigth-container">
            <NavLink to="/panier">
              <div className="cart-icon-container">
                {userStatue
                  ? totalQuantity > 0 && (
                      <div className="badgeCart">{totalQuantity}</div>
                    )
                  : cartItemCount > 0 && (
                      <div className="badgeCart">{cartItemCount}</div>
                    )}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 122.43 122.88"
                >
                  <style type="text/css">
                    {`.st0 {
                    fill-rule: evenodd;
                    clip-rule: evenodd;
                  }`}
                  </style>
                  <path
                    className="st0"
                    d="M22.63,12.6h93.3c6.1,0,5.77,2.47,5.24,8.77l-3.47,44.24c-0.59,7.05-0.09,5.34-7.56,6.41l-68.62,8.73 l3.63,10.53c29.77,0,44.16,0,73.91,0c1,3.74,2.36,9.83,3.36,14h-12.28l-1.18-4.26c-24.8,0-34.25,0-59.06,0 c-13.55-0.23-12.19,3.44-15.44-8.27L11.18,8.11H0V0h19.61C20.52,3.41,21.78,9.15,22.63,12.6L22.63,12.6z M53.69,103.92 c5.23,0,9.48,4.25,9.48,9.48c0,5.24-4.25,9.48-9.48,9.48c-5.24,0-9.48-4.24-9.48-9.48C44.21,108.17,48.45,103.92,53.69,103.92 L53.69,103.92z M92.79,103.92c5.23,0,9.48,4.25,9.48,9.48c0,5.24-4.25,9.48-9.48,9.48c-5.24,0-9.48-4.24-9.48-9.48 C83.31,108.17,87.56,103.92,92.79,103.92L92.79,103.92z"
                  />
                </svg>
              </div>
            </NavLink>
            {userStatue ? (
              <NavLink to="/commandes">
                <div className="user-icon-container">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720 720">
                    <defs>
                      <style>
                        {`.cls-1 {
                      fill: none;
                      stroke: #000;
                      stroke-width: 20px;
                      fill-rule: evenodd;
                    }`}
                      </style>
                    </defs>
                    <path
                      id="UserIcon"
                      className="cls-1"
                      d="M572,329c69.036,0,125,55.741,125,124.5S641.036,578,572,578,447,522.259,447,453.5,502.964,329,572,329ZM478,601c35.244-.27,51.738,13.485,82,19,33.67,6.136,56.4-12.605,79-18,44.365-10.591,88.2,31.161,108,51,7.055,7.07,45.447,46.993,42,59-4.96,17.277-56.829,48.335-74,58-119.857,67.464-292.408,36.337-361-58,0.688-13.762,8.9-19.693,15-28C397.674,644.968,429.166,620.638,478,601Zm99-443c193.3,0,350,156.7,350,350S770.3,858,577,858,227,701.3,227,508,383.7,158,577,158Z"
                      transform="translate(-217 -148)"
                    />
                  </svg>
                </div>
              </NavLink>
            ) : (
              <NavLink to="/connexion">
                <div className="user-icon-container">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720 720">
                    <defs>
                      <style>
                        {`.cls-1 {
                      fill: none;
                      stroke: #000;
                      stroke-width: 20px;
                      fill-rule: evenodd;
                    }`}
                      </style>
                    </defs>
                    <path
                      id="UserIcon"
                      className="cls-1"
                      d="M572,329c69.036,0,125,55.741,125,124.5S641.036,578,572,578,447,522.259,447,453.5,502.964,329,572,329ZM478,601c35.244-.27,51.738,13.485,82,19,33.67,6.136,56.4-12.605,79-18,44.365-10.591,88.2,31.161,108,51,7.055,7.07,45.447,46.993,42,59-4.96,17.277-56.829,48.335-74,58-119.857,67.464-292.408,36.337-361-58,0.688-13.762,8.9-19.693,15-28C397.674,644.968,429.166,620.638,478,601Zm99-443c193.3,0,350,156.7,350,350S770.3,858,577,858,227,701.3,227,508,383.7,158,577,158Z"
                      transform="translate(-217 -148)"
                    />
                  </svg>
                </div>
              </NavLink>
            )}
          </div>
        </nav>
      )}
    </div>
  );
};

export default NavbarFront;
