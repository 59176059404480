import React, { useContext, useState } from "react";
import { ParamContext } from "../contexts/ParamContexts";
import axios from "axios";
import PopUp from "./PopUp";
import { useTranslation } from "react-i18next";

const DeliveryCard = (props) => {
  let {
    idAddress,
    firstName,
    lastName,
    email,
    phone,
    address,
    addressComp,
    city,
    zip,
    country,
    isDefault,
    isFromOrder,
    activeStep,
  } = props;

  const { idUser } = useContext(ParamContext);

  const { t } = useTranslation();

  const [togglePopup, setTogglePopup] = useState(false);

  function numberFormat(num) {
    if (num === null || num === undefined) {
      return "";
    }
    return num.toString().replace(/\B(?=(\d{2})+(?!\d))/g, " ");
  }

  function deleteAddress() {
    const formatData = {
      idUser: idUser,
      idAddress: idAddress,
    };

    axios
      .post(
        "https://api.sweatteeshop.com/front/users/delete/addresse",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        alert(error.data.message);
      });
  }

  function handleDelivery() {
    const formatData = {
      idUser,
      idAddress,
      DefaultDelivery: "true",
    };

    axios
      .post(
        "https://api.sweatteeshop.com/front/users/edite/address",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        if (response.data === true) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleBilling() {
    const formatData = {
      idUser,
      idAddress,
      DefaultBilling: "true",
    };

    axios
      .post(
        "https://api.sweatteeshop.com/front/users/edite/address",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        if (response.data === true) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="Card-address-container">
      <div>
        <div className="Card-address-header">
          <p>
            {firstName} {lastName}
          </p>
          <p>{email}</p>
          <p>{numberFormat(phone)}</p>
        </div>
        <div className="Card-address-body">
          <p>{address}</p>
          <p>{addressComp}</p>
          <p>
            {country}, {city} {zip}
          </p>
        </div>
      </div>
      {!isFromOrder && (
        <div className="btnEditeAddressContainer">
          {isDefault || activeStep === 1 || activeStep === 2 ? (
            activeStep === 1 ? (
              <button className="btnEditeAddress" onClick={handleDelivery}>
                Choisir en livraison
              </button>
            ) : activeStep === 2 ? (
              <button className="btnEditeAddress" onClick={handleBilling}>
                Choisir en facturation
              </button>
            ) : null
          ) : (
            <button
              className="btnEditeAddress"
              onClick={() => setTogglePopup(true)}
            >
              {t("Delete")}
            </button>
          )}
          <a href={`/modification-adresse/${idAddress}`} rel="nofollow">
            <button className="btnEditeAddress">{t("Edite")}</button>
          </a>
        </div>
      )}
      <PopUp
        togglePopup={togglePopup}
        setTogglePopup={setTogglePopup}
        messagePopup={"Etes vous certain de vouloir supprimer cette addresse ?"}
        comeFrom={"userAddress"}
        onSubmite={deleteAddress}
      />
    </div>
  );
};

export default DeliveryCard;
