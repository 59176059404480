import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ParamContext } from "../contexts/ParamContexts";

const Privacypolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  const { nameCompany, statusCompany, emailContact, webSide } = useContext(ParamContext);

  return (
    <div className="polities-pages">
      <h1>{t("PrivacyCookies")}</h1>
      <div className="head-policies-pages">
        <div className="section-policies">
          <h3>Conditions d'utilisations</h3>
          <p>
            en utilisant {webSide} vous acceptez les termes et conditions
            d'utilisation de ce site Web. Si vous n'acceptez pas, veuillez ne
            pas utiliser ce site. {nameCompany} {statusCompany} se réserve le
            droit de modifier tout ou partie des présentes conditions
            d'utilisation à tout moment et il vous incombe en tout temps de
            vérifier nos conditions d'utilisation afin d'être informé de toutes
            les modifications. Tout le contenu, conception et la structure de ce
            site Internet est détenue, contrôlée ou sous licence par ou pour{" "}
            {nameCompany} {statusCompany} et est de ce fait protégé par toutes
            les lois internationales sur le copyright et la marque.
          </p>
        </div>
        <div className="section-policies">
          <h3>politique de confidentialité</h3>
          <p>
            Cette politique de confidentialité définit la façon dont nous
            recueillons et utilisons vos informations personnelles que nous
            recueillons lorsque vous utilisez ce site Web ou achetez un produit
            présenté sur ce site Web. Afin que vous puissiez utiliser ce site
            Web en toute confiance, {nameCompany} {statusCompany} observe
            strictement les lois qui protègent vos données personnelles, et
            c'est notre plus haute priorité de s'assurer que cette information
            est recueillie, utilisée et gérée de manière appropriée. Lorsque
            vous utilisez ce site Web, veuillez vous assurer que vous comprenez
            cette politique de confidentialité et fournir des informations
            personnelles uniquement si vous acceptez la politique de
            confidentialité.
          </p>
        </div>
      </div>
      <div className="body-policies-page">
        <div className="section-policies">
          <h3>I. Collecte de données personnelles</h3>
          <p>
            Nous entendons par « données personnelles », dans la présente
            politique de confidentialité tout élément correspondent aux
            informations qui vous identifient personnellement, comme votre nom,
            adresse, numéro de téléphone et adresse e-mail. Ce site Web peut
            recueillir vos données personnels lorsque vous commandez un produit
            ou un service, répondez à une campagne de promotion des ventes, une
            offre spéciale, ou demandez différents types de service.{" "}
            {nameCompany} {statusCompany} recueille vos renseignements
            personnels seulement lorsque vous les fournissez volontairement, et
            lorsque vous fournissez des renseignements personnels, nous
            comprenons que cela signifie que vous consentez à {nameCompany}{" "}
            {statusCompany} le droit d'utiliser ces renseignements personnels
            conformément à cette politique de confidentialité. Si vous ne
            consentez pas à cela, ne fournissez pas de renseignements
            personnels.
          </p>
        </div>
        <div className="section-policies">
          <h3>II. Utilisation des informations personnels recoltés</h3>
          <p>
            Lorsque nous recueillons des renseignements personnels, nous
            indiquons clairement le but pour lequel nous les recueillons. Nous
            utilisons les informations collectées uniquement si nécessaire pour
            atteindre les buts suivants.
          </p>
        </div>
        <table className="datas-table">
          <thead>
            <tr>
              <th>Types de données</th>
              <th>Finalités</th>
              <th>Bases legale</th>
              <th>Durée de conservation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Civilité, nom, prénom, adresse mail, numéro de téléphone, sujet,
                zone de texte libre
              </td>
              <td>
                Répondre lorsque vous nous contactez via le formulaire de
                contact
              </td>
              <td>Consentement</td>
              <td>Le temps de traiter votre demande</td>
            </tr>
            <tr>
              <td>Zone de texte libre</td>
              <td>
                Vous répondre lorsque vous nous contactez via le chat en ligne
              </td>
              <td>Consentement</td>
              <td>Le temps de traiter votre demand</td>
            </tr>
            <tr>
              <td>Adresse mail, civilité</td>
              <td>
                Vous envoyer notre newsletter à la suite de votre inscription
              </td>
              <td>Consentement</td>
              <td>Jusqu’à votre désabonnement</td>
            </tr>
            <tr>
              <td>Civilité, nom, prénom, adresse mail, numero de telephone.</td>
              <td>
                Favoriser la relation clients/prospects avec l’envoi de messages
                personnalisés pour l’anniversaire des personnes concernées
              </td>
              <td>Intérêt légitime</td>
              <td>
                3 ans à compter de la dernière marque d’intérêt de votre part /
                Jusqu’à la suppression de votre compte
              </td>
            </tr>
            <tr>
              <td>
                Adresse email, civilité, prénom, nom, société, adresse postale,
                numéro de téléphone, numéro de commande
              </td>
              <td>Gérer votre commande</td>
              <td>Contractuelle</td>
              <td>
                3 ans à compter de la dernière marque d’intérêt de votre part
              </td>
            </tr>
            <tr>
              <td>Coordonnées bancaires</td>
              <td>Payer votre commande</td>
              <td>Contractuelle</td>
              <td>Le temps de traiter le paiement de la commande</td>
            </tr>
            <tr>
              <td>Données de facturation</td>
              <td>Gestion comptable</td>
              <td>Obligation légale</td>
              <td>
                10 ans à compter de la date de facture en archivage
                intermédiaire
              </td>
            </tr>
            <tr>
              <td>
                Nom, prénom, adresse postale, adresse e-mail, téléphone,
                Coordonnées bancaires
              </td>
              <td>
                Effectuer des contrôles anti-fraude en cas d’achat par carte de
                crédit ou de débit
              </td>
              <td>Intérêt légitime</td>
              <td>Le temps d’effectuer le contrôle</td>
            </tr>
            <tr>
              <td>
                Adresse email, civilité, prénom, nom, société, adresse postale,
                numéro de téléphone, numéro de commande
              </td>
              <td>
                Service après-vente et mise en œuvre des garanties légales sur
                les produits
              </td>
              <td>Obligation légale</td>
              <td>5 ans en archivage pour le respect des garanties légales</td>
            </tr>
            <tr>
              <td>
                Nom, prénom, adresse postale, adresse email, données concernant
                la demande et la réponse apportées Copie d’un titre d’identité
                exclusivement en cas de doute
              </td>
              <td>
                Gestion des demandes d’accès, de rectification, d’opposition et
                de vos autres droits en vertu du Règlement européen
              </td>
              <td>Obligation légale</td>
              <td>
                Le temps nécessaire au traitement de la demande en base active,
                puis archivage pour conservation de la preuve du respect de nos
                obligations légales. Copie du titre d’identité : suppression
                après vérification de l’identité du demandeur
              </td>
            </tr>
          </tbody>
        </table>
        <div className="section-policies">
          <h3>III. Fichers de journaux d'acces</h3>
          <p>
            {nameCompany} {statusCompany} utilise les journaux d'accès pour
            analyser les tendances d'utilisation du site Web, la gestion du
            site, les tendances des utilisateurs et les données démographiques.
            D'après les résultats, nous sommes en mesure d'obtenir des données
            d'utilisation global site telles que les pages visitées, le temps
            passé sur chaque page, l'emplacement précédent où le site était
            accessible, comment les utilisateurs transitent entre les pages du
            navigateur et le système d'exploitation utilisé, mais nous
            n'utilisons pas cela pour recueillir des informations qui sont
            spécifiques à toute personne.
          </p>
        </div>
        <div className="section-policies">
          <h3>IV. Partage d'infprmations</h3>
          <p>
            {nameCompany} {statusCompany} ne partage pas vos renseignements
            personnels avec une tierce partie sans votre consentement. Lorsque
            nous vous expédions un produit ou réceptionnons un paiement de votre
            part, nous fournissons des renseignements personnels aux sociétés
            sollicitées pour ce service sans vous en informer explicitement,
            mais seulement comme nécessaire pour atteindre les buts spécifiques.{" "}
            {nameCompany} {statusCompany} interdit expressément ces sociétés
            d'utiliser ces renseignements personnels à d'autres fins ou de les
            révéler à un tiers. {nameCompany} {statusCompany} peut fournir vos
            renseignements personnels si nous avons ordre de le faire
            conformément aux lois du pays s'il y a lieu.
          </p>
        </div>
        <div className="section-policies">
          <h3>V. Supression des données</h3>
          <p>
            Lorsque l'objet déclaré pour lequel vos renseignements personnels
            ont été recueillis a été rempli, {nameCompany} {statusCompany}{" "}
            supprimera ces informations après un certain temps, et nous vous
            informons de ceci lorsque nous recueillons les informations. Soyez
            bien conscient que nous serons alors incapables de répondre aux
            demandes ultérieures, de vous fournir ou de modifier les données
            supprimées.
          </p>
        </div>
        <div className="section-policies">
          <h3>VI. Sites externes</h3>
          <p>
            Ce site comporte des liens vers des sites externes diverses, mais
            nous n'avons pas la même politique de confidentialité que ces sites.{" "}
            {nameCompany} {statusCompany} n'a aucune responsabilité sur les
            sites externes liés et leur façon de gérer vos informations
            personnelles, alors s'il vous plaît n'oubliez pas de lire les
            politiques de confidentialité de ces sites.
          </p>
        </div>
        <div className="section-policies">
          <h3>VII. Securité</h3>
          <p>
            Afin de vous offrir un meilleur service, ce site vous permet
            d'enregistrer des renseignements personnels au besoin. Lors de
            l'enregistrement de vos informations personnelles, {nameCompany}{" "}
            {statusCompany} utilise des technologies de chiffrement et des
            systèmes de sécurité tels que des pare-feu pour protéger les
            serveurs qui gèrent ces informations, et nous faisons tous les
            efforts pour empêcher tout accès non autorisé ou la fuite de ces
            données à l'extérieur.
          </p>
        </div>
        <div className="section-policies">
          <h3>VIII. Exceptions</h3>
          <p>
            {nameCompany} {statusCompany} ne fournira pas vos informations
            personnelles à une tierce partie sans votre consentement. Cependant
            si nous recevons une demande légalement valide d'un tribunal, un
            organisme d'application de loi, un organisme de protection des
            consommateurs ou un organisme d'autorité similaire, alors nous
            fournirons les informations en conséquence.
          </p>
        </div>
        <div className="section-policies">
          <h3>IX. Modifier vos informations</h3>
          <p>
            Si vos renseignements personnels détenus par {nameCompany}{" "}
            {statusCompany} sont inexacts, ou si vous souhaitez en supprimer les
            données, vous pouvez demander à ce que ces données soient corrigées,
            mises à jour ou supprimées. Pour plus de détails, veuillez contacter{" "}
            {emailContact}.
          </p>
        </div>
        <div className="section-policies">
          <h3>X. Cookies</h3>
          <p>
            Ce site utilise differents types de cookies pour améliorer
            l’interactivité de la plateforme. Un cookie (ou autre traceur) est
            un petit fichier placé sur le disque dur de l’ordinateur ou de
            l’appareil mobile de l’utilisateur lors de sa première visite sur le
            Site. Il permet par exemple d'indiquer la fréquentation des pages
            visitées et de reconnaître un utilisateur ayant déjà accédé au Site.
            Cela peut permettre une personnalisation des services et un meilleur
            confort dans la navigation. Vous trouverez ci-dessous la liste des
            cookies utilisés ce site, leur finalité et comment les désactiver. A
            l’exception des cookies nécessaires au fonctionnement du site, que
            vous ne pouvez pas désactiver, ces cookies peuvent être désactivés à
            tout moment, individuellement ou en totalité. Si vous refusez tout
            ou parties des cookies ci-dessous, certains éléments et
            fonctionnalités ne vous seront toutefois plus proposées.
          </p>
        </div>
        <table className="cookies-table">
          <thead>
            <tr>
              <th>Nom du cookie</th>
              <th>Finalité</th>
              <th>Durée de conservation</th>
              <th>Actif / inactif</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Google Analytics</td>
              <td>Statistique d'utilisation du site</td>
              <td>13 mois</td>
              <td>Actif</td>
            </tr>
          </tbody>
        </table>
        <div className="section-policies">
          <h3>XI. Mise a jour de notre politique de confidentialité</h3>
          <p>
            En cas de mise à jour de cette politique de confidentialité, nous
            vous informerons en incluant une notification dans a la page
            d'accueil de ce site Web. Vérifiez régulièrement la politique de
            confidentialité.
          </p>
        </div>
        <div className="section-policies">
          <h3>Observation sur la vie privée</h3>
          <p>
            Si vous avez des questions concernant la politique de
            confidentialité affichée sur ce site, contactez-nous en utilisant le
            formulaire ci-dessous. Questions sur la gestion du site Web :{" "}
            {emailContact}.
          </p>
        </div>
        <div className="section-policies">
          <h3>Mis a jour</h3>
          <p>
            Cette politique de confidentialité a été mis à jour le 20 septembre
            2023.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacypolicy;
