import React, { useContext, useState } from "react";
import { ParamContext } from "../contexts/ParamContexts";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const WelcomPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    facebook,
    instagram,
    tiktok,
    logoVar,
    nameCompany,
    handleLoginAdmin,
    setCurrentAdmin,
  } = useContext(ParamContext);

  const [toggleMessage, setToggleMessage] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const [message, setMessage] = useState("");
  const [openEyeLeft, setOpenEyeLeft] = useState(true);
  const [closeEyeLeft, setCloseEyeLeft] = useState(false);
  const [changeTypeLeft, setChangeTypeLeft] = useState(true);

  const handleLoginClick = () => {
    setShowLogin(!showLogin);
  };

  function handleSubmit(event) {
    event.preventDefault();

    const formData = new FormData(event.target);

    axios
      .post("https://api.sweatteeshop.com/front/users/subscription", formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          setToggleMessage(response.data.message);
          event.target.reset();
        } else {
          setToggleMessage(response.data.message);
        }
      })
      .catch((error) => {
        alert(error.data.message);
      });
  }

  const handleLogin = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const login = formData.get("email");
    const password = formData.get("password");

    axios
      .post(
        "https://api.sweatteeshop.com/back/login",
        { login, password },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          event.target.reset();
          setCurrentAdmin(response.data.idAdmin);
          handleLoginAdmin();
          navigate("/acceuil");
          sessionStorage.setItem("idAdmin", response.data.idAdmin);
        } else {
          setMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="coming-soon-container">
      {showLogin ? (
        <form className="content" onSubmit={handleLogin}>
          <h2>{t("Connection")}</h2>
          <div className="form-group">
            <input
            className="Input-config"
              type="text"
              id="email"
              name="email"
              required
              placeholder="Identifiant"
            />
          </div>
          <div className="form-group">
            <input
            className="Input-config"
              type={changeTypeLeft ? "password" : "text"}
              id="password"
              name="password"
              required
              placeholder={t("Password")}
            />
            <div className="eyes-container">
              <svg
                onClick={() => {
                  setCloseEyeLeft(!closeEyeLeft);
                  setOpenEyeLeft(!openEyeLeft);
                  setChangeTypeLeft(!changeTypeLeft);
                }}
                className={`openEye ${closeEyeLeft ? "active" : "inactive"}`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 122.88 65.06"
              >
                <path d="M0.95,30.01c2.92-3.53,5.98-6.74,9.15-9.63C24.44,7.33,41.46,0.36,59.01,0.01c17.51-0.35,35.47,5.9,51.7,19.29 c3.88,3.2,7.63,6.77,11.24,10.74c1.16,1.28,1.22,3.17,0.23,4.51c-4.13,5.83-8.88,10.82-14.07,14.96 C95.12,59.88,79.34,64.98,63.35,65.06c-15.93,0.07-32.06-4.86-45.8-14.57c-6.14-4.34-11.81-9.63-16.78-15.85 C-0.34,33.24-0.23,31.27,0.95,30.01L0.95,30.01z M61.44,26.46c0.59,0,1.17,0.09,1.71,0.24c-0.46,0.5-0.73,1.17-0.73,1.9 c0,1.56,1.26,2.82,2.82,2.82c0.77,0,1.46-0.3,1.97-0.8c0.2,0.6,0.3,1.24,0.3,1.9c0,3.35-2.72,6.07-6.07,6.07 c-3.35,0-6.07-2.72-6.07-6.07C55.37,29.18,58.09,26.46,61.44,26.46L61.44,26.46z M61.44,10.82c5.99,0,11.42,2.43,15.35,6.36 c3.93,3.93,6.36,9.35,6.36,15.35c0,5.99-2.43,11.42-6.36,15.35c-3.93,3.93-9.35,6.36-15.35,6.36c-5.99,0-11.42-2.43-15.35-6.36 c-3.93-3.93-6.36-9.35-6.36-15.35c0-5.99,2.43-11.42,6.36-15.35C50.02,13.25,55.45,10.82,61.44,10.82L61.44,10.82z M71.89,22.08 c-2.67-2.67-6.37-4.33-10.45-4.33c-4.08,0-7.78,1.65-10.45,4.33c-2.67,2.67-4.33,6.37-4.33,10.45c0,4.08,1.65,7.78,4.33,10.45 c2.67,2.67,6.37,4.33,10.45,4.33c4.08,0,7.78-1.65,10.45-4.33c2.67-2.67,4.33-6.37,4.33-10.45C76.22,28.45,74.56,24.75,71.89,22.08 L71.89,22.08z M14.89,25.63c-2.32,2.11-4.56,4.39-6.7,6.82c4.07,4.72,8.6,8.8,13.45,12.23c12.54,8.85,27.21,13.35,41.69,13.29 c14.42-0.07,28.65-4.67,40.37-14.02c4-3.19,7.7-6.94,11.03-11.25c-2.79-2.91-5.63-5.54-8.51-7.92C91.33,12.51,75,6.79,59.15,7.1 C43.34,7.42,27.93,13.76,14.89,25.63L14.89,25.63z" />
              </svg>
              <svg
                onClick={() => {
                  setCloseEyeLeft(!closeEyeLeft);
                  setOpenEyeLeft(!openEyeLeft);
                  setChangeTypeLeft(!changeTypeLeft);
                }}
                className={`closeEye ${openEyeLeft ? "active" : "inactive"}`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 122.879 79.699"
              >
                <path d="M0.955,37.326c2.922-3.528,5.981-6.739,9.151-9.625C24.441,14.654,41.462,7.684,59.01,7.334 c6.561-0.131,13.185,0.665,19.757,2.416l-5.904,5.904c-4.581-0.916-9.168-1.324-13.714-1.233 c-15.811,0.316-31.215,6.657-44.262,18.533l0,0c-2.324,2.115-4.562,4.39-6.702,6.82c4.071,4.721,8.6,8.801,13.452,12.227 c2.988,2.111,6.097,3.973,9.296,5.586l-5.262,5.262c-2.782-1.504-5.494-3.184-8.12-5.039c-6.143-4.338-11.813-9.629-16.78-15.85 C-0.338,40.563-0.228,38.59,0.955,37.326L0.955,37.326L0.955,37.326z M96.03,0l5.893,5.893L28.119,79.699l-5.894-5.895L96.03,0 L96.03,0z M97.72,17.609c4.423,2.527,8.767,5.528,12.994,9.014c3.877,3.196,7.635,6.773,11.24,10.735 c1.163,1.277,1.22,3.171,0.226,4.507c-4.131,5.834-8.876,10.816-14.069,14.963C95.119,67.199,79.338,72.305,63.352,72.377 c-6.114,0.027-9.798-3.141-15.825-4.576l3.545-3.543c4.065,0.705,8.167,1.049,12.252,1.031c14.421-0.064,28.653-4.668,40.366-14.02 c3.998-3.191,7.706-6.939,11.028-11.254c-2.787-2.905-5.627-5.543-8.508-7.918c-4.455-3.673-9.042-6.759-13.707-9.273L97.72,17.609 L97.72,17.609z M61.44,18.143c2.664,0,5.216,0.481,7.576,1.359l-5.689,5.689c-0.619-0.079-1.248-0.119-1.886-0.119 c-4.081,0-7.775,1.654-10.449,4.328c-2.674,2.674-4.328,6.369-4.328,10.45c0,0.639,0.04,1.268,0.119,1.885l-5.689,5.691 c-0.879-2.359-1.359-4.912-1.359-7.576c0-5.995,2.43-11.42,6.358-15.349C50.02,20.572,55.446,18.143,61.44,18.143L61.44,18.143z M82.113,33.216c0.67,2.09,1.032,4.32,1.032,6.634c0,5.994-2.43,11.42-6.357,15.348c-3.929,3.928-9.355,6.357-15.348,6.357 c-2.313,0-4.542-0.361-6.633-1.033l5.914-5.914c0.238,0.012,0.478,0.018,0.719,0.018c4.081,0,7.775-1.652,10.449-4.326 s4.328-6.369,4.328-10.449c0-0.241-0.006-0.48-0.018-0.72L82.113,33.216L82.113,33.216z" />
              </svg>
            </div>
          </div>
          <div className="form-group">
            <button className="btn-connect-config" type="submit">{t("Singin")}</button>
          </div>
          <div className="Create-account" onClick={handleLoginClick}>
            Retour
          </div>
          {message && <div className="message-conect-form">{message}</div>}
        </form>
      ) : (
        <div className="content">
          <div className="logo-container">
            <img src={logoVar} alt="logo" />
          </div>
          <h1>{nameCompany} Arrive Bientôt !</h1>
          <p>
            Préparez-vous à découvrir les dernières tendances et à exprimer
            votre style comme jamais auparavant.
          </p>
          <p>
            Rejoignez notre newsletter pour être le premier informé de
            l'ouverture et recevoir des offres exclusives !
          </p>
          <form className="newsletter-form" onSubmit={handleSubmit}>
            <input type="email" name="email" required />
            <button>S'inscrire</button>
          </form>
          <small className="alertSuccess">{toggleMessage}</small>
          <div className="social-media">
            <p>Suivez-nous sur les réseaux sociaux :</p>
            <div className="icons">
              <a href={facebook} target="_blank" rel="noreferrer">
                <svg
                  className="icon-rs-footer"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 640"
                >
                  <path d="M380.001 120.001h99.993V0h-99.993c-77.186 0-139.986 62.8-139.986 139.986v60h-80.009V320h79.985v320h120.013V320h99.994l19.996-120.013h-119.99v-60.001c0-10.843 9.154-19.996 19.996-19.996v.012z" />
                </svg>
              </a>
              <a href={instagram} target="_blank" rel="noreferrer">
                <svg
                  className="icon-rs-footer"
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  viewBox="0 0 640 640"
                >
                  <path d="M184.715 2.752h270.582C556.908 2.752 640 84.816 640 185.069v269.85c0 100.277-83.092 182.317-184.703 182.317H184.715C83.104 637.236 0 555.196 0 454.919v-269.85C0 84.816 83.103 2.752 184.715 2.752zm133.561 145.939c98.741 0 178.868 80.127 178.868 178.868 0 98.753-80.127 178.868-178.868 178.868-98.765 0-178.88-80.115-178.88-178.868 0-98.741 80.115-178.868 178.88-178.868zm0 60.414c65.387 0 118.454 53.056 118.454 118.454s-53.068 118.466-118.454 118.466c-65.41 0-118.466-53.067-118.466-118.466 0-65.398 53.056-118.454 118.466-118.454zM491.321 123.9c16.04 0 29.044 13.004 29.044 29.032 0 16.04-13.004 29.044-29.044 29.044-16.028 0-29.032-13.004-29.032-29.044 0-16.028 13.004-29.032 29.032-29.032zM206.825 54.58H433.21c85.005 0 154.526 69.178 154.526 153.712V435.81c0 84.532-69.52 153.699-154.526 153.699H206.825c-85.005 0-154.537-69.167-154.537-153.7V208.29c0-84.532 69.532-153.71 154.537-153.71z" />
                </svg>
              </a>
              <a href={tiktok} target="_blank" rel="noreferrer">
                <svg
                  className="icon-rs-footer"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 2859 3333"
                >
                  <path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z" />
                </svg>
              </a>
            </div>
          </div>
          <div className="Create-account" onClick={handleLoginClick}>
            S'identifer
          </div>
        </div>
      )}
    </div>
  );
};

export default WelcomPage;
