import NavSection from "../components/NavSection";
import NavbarBack from "../components/NavbarBack";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddressUserCard from "../components/AddressUserCard";
import { ParamContext } from "../contexts/ParamContexts";

const OderDeatailBack = () => {
  let { id } = useParams();
  const { idUser } = useContext(ParamContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [order, setOrder] = useState([]);
  const [billing, setBilling] = useState([]);
  const [delivery, setDelivery] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsBuy, setProductsBuy] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orderResponse = await axios.get(
          `https://api.sweatteeshop.com/back/orders/data/${id}`
        );
        const orderData = Object.values(orderResponse.data);
        setOrder(orderData[0]);
        if (orderData.length > 0) {
          const billingResponse = await axios.get(
            `https://api.sweatteeshop.com/front/users/Address_info/${orderData[0].idBilling}`
          );
          setBilling(Object.values(billingResponse.data));

          const deliveryResponse = await axios.get(
            `https://api.sweatteeshop.com/front/users/Address_info/${orderData[0].idDelivery}`
          );
          setDelivery(Object.values(deliveryResponse.data));
        }
        const productResponse = await axios.get(
          `https://api.sweatteeshop.com/back/orders/products/${id}`
        );
        setProducts(Object.values(productResponse.data));
        setProductsBuy(Object.values(productResponse.data));
        const colorsResponse = await axios.get(
          "https://api.sweatteeshop.com/front/colors"
        );
        setColors(Object.values(colorsResponse.data));

        const sizesResponse = await axios.get(
          "https://api.sweatteeshop.com/front/sizes"
        );
        setSizes(Object.values(sizesResponse.data));
      } catch (error) {
        console.log(error);
      } finally {
      }
    };

    fetchData();
  }, [id, idUser]);

  const printOrder = () => {
    setIsLoading(true);

    const formatData = {
      NumberOrder: order?.NumberOrder,
      dateOrder: order?.dateOrder,
      shipping: order?.shipping,
      totalPrice: order?.totalPrice,
      promoCode: order?.promoCode,

      firstNameDelivery: delivery[0]?.firstName,
      lastNameDelivery: delivery[0]?.lastName,
      emailDelivery: delivery[0]?.email,
      phoneDelivery: delivery[0]?.phone,
      addressDelivery: delivery[0]?.address,
      addressCompDelivery: delivery[0]?.addressComp,
      cityDelivery: delivery[0]?.city,
      zipDelivery: delivery[0]?.zipCode,
      countryDelivery: delivery[0]?.country,

      firstNameBilling: billing[0]?.firstName,
      lastNameBilling: billing[0]?.lastName,
      emailBilling: billing[0]?.email,
      phoneBilling: billing[0]?.phone,
      addressBilling: billing[0]?.address,
      addressCompBilling: billing[0]?.addressComp,
      cityBilling: billing[0]?.city,
      zipBilling: billing[0]?.zipCode,
      countryBilling: billing[0]?.country,

      productsBuy,
    };

    axios
      .post("https://api.sweatteeshop.com/back/orders/print", formatData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        // alert(response.data);
        const downloadLink = document.createElement("a");
        downloadLink.href = `https://api.sweatteeshop.com/${response.data}`;
        downloadLink.download = "commande.pdf";
        downloadLink.target = "_blank";
        downloadLink.click();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavSection />
        <div className="admin-content-page">
          <div className="header-content-admin">
            <NavLink to="/back/gestion-des-commandes">
              <button>Retour</button>
            </NavLink>
            <button onClick={printOrder} disabled={isLoading}>
              {isLoading ? "Chargement" : "imprimer la commande"}
            </button>
          </div>
          <ul className="order-info-container">
            <li>Numero de la commande : {order?.NumberOrder}</li>
            <li>Date de la commande : {order?.dateOrder}</li>
            <li>Status de la commande : {order?.orderStatus}</li>
            <li>Montant de livraison : {order?.shipping} €</li>
            <li>Montant du panier : {order?.totalPrice} €</li>
            <li>Code promo : {order?.promoCode}</li>
            <li>
              Valeur de la promo:{" "}
              {order?.discounValue ? order?.discounValue : 0} €
            </li>
          </ul>
          <div className="address-user-container">
            <div className="address-user">
              <span>{t("DeliveryAddress")}</span>
              <AddressUserCard
                idAddress={delivery[0]?.idAddress}
                firstName={delivery[0]?.firstName}
                lastName={delivery[0]?.lastName}
                email={delivery[0]?.email}
                phone={delivery[0]?.phone}
                address={delivery[0]?.address}
                addressComp={delivery[0]?.addressComp}
                city={delivery[0]?.city}
                zip={delivery[0]?.zipCode}
                country={delivery[0]?.country}
                isFromOrder={true}
              />
            </div>
            <div className="address-user">
              <span>{t("BillingAddress")}</span>
              <AddressUserCard
                idAddress={billing[0]?.idAddress}
                firstName={billing[0]?.firstName}
                lastName={billing[0]?.lastName}
                email={billing[0]?.email}
                phone={billing[0]?.phone}
                address={billing[0]?.address}
                addressComp={billing[0]?.addressComp}
                city={billing[0]?.city}
                zip={billing[0]?.zipCode}
                country={billing[0]?.country}
                isFromOrder={true}
              />
            </div>
          </div>
          <div className="product-table">
            <table>
              <thead>
                <tr>
                  <th>Ref</th>
                  <th>{t("NameProduct")}</th>
                  <th>{t("color")}</th>
                  <th>{t("size")}</th>
                  <th>{t("Price")}</th>
                  <th>{t("QTY")}</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => {
                  const colorName = colors.find(
                    (color) => color.idColor === product.idColor
                  )?.nameColor;
                  const sizeName = sizes.find(
                    (size) => size.idSize === product.idSize
                  )?.nameSize;
                  return (
                    <tr key={product.id}>
                      <td>
                        <p>Ref:</p>
                        {product.referenceProduct}
                      </td>
                      <td>
                        <p>{t("Product")}:</p>
                        <NavLink
                          to={`/produit/${product.idProduct}`}
                          rel="nofollow"
                        >
                          {product.nameProduct}
                        </NavLink>
                      </td>
                      <td>
                        <p>{t("color")}:</p>
                        {colorName}
                      </td>
                      <td>
                        <p>{t("size")}:</p>
                        {sizeName}
                      </td>
                      <td>
                        <p>{t("Price")}:</p>
                        {product.price} €
                      </td>
                      <td>
                        <p>{t("QTY")}:</p>
                        {product.quantity}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OderDeatailBack;
