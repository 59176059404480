import React, { useEffect, useRef } from "react";

const PopUp = ({
  togglePopup,
  setTogglePopup,
  messagePopup,
  comeFrom,
  onSubmite,
}) => {
  const myRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!myRef.current.contains(e.target)) {
        setTogglePopup(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div className={`bgPupop ${togglePopup ? "active" : "inactive"}`}>
      <div ref={myRef} className="popupContainer">
        <div className="popupContent">
          <p>{messagePopup}</p>
        </div>
        <div className="PopupContainer">
          <div className="subBtnPopopcontainer">
            {comeFrom && (
              <>
                <button onClick={() => setTogglePopup(false)}>ANNULER</button>
                <button
                  onClick={() => {
                    if (comeFrom === "userAddress") {
                      onSubmite();
                    } else if (comeFrom === "deleteAccount") {
                      onSubmite();
                    } else if (comeFrom === "orderDeletion") {
                      onSubmite();
                    } else if (comeFrom === "confirmOrder") {
                      onSubmite();
                    } else if (comeFrom === "BillDeletion") {
                      onSubmite();
                    } else if (comeFrom === "sendBill") {
                      onSubmite();
                    } else if (comeFrom === "cancelBill") {
                      onSubmite();
                    } else if (comeFrom === "productDeletion") {
                      onSubmite();
                    } else if (comeFrom === "toggleOnline") {
                      onSubmite();
                    } else if (comeFrom === "publishArticleFunc") {
                      onSubmite();
                    } else if (comeFrom === "articleBlocDeletion") {
                      onSubmite();
                    } else if (comeFrom === "adminDelete") {
                      onSubmite();
                    } else if (comeFrom === "orderDeletionUser") {
                      onSubmite();
                    } else if (comeFrom === "billDeletionUser") {
                      onSubmite();
                    }
                    setTogglePopup(false);
                  }}
                >
                  CONFIRMER
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
