import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ParamContext } from "../contexts/ParamContexts";
import { Helmet } from "react-helmet";

const ChangesAndReturns = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  const baseUrl = "https://api.sweatteeshop.com/public/images/";

  const { nameCompany, imgOpenTags, emailContact } = useContext(ParamContext);

  return (
    <div className="container-return-page">
      <Helmet>
        <title>{`${nameCompany} | Echanges et retours`}</title>
        <meta
          property="og:title"
          content={`${nameCompany} | Echanges et retours`}
        />
        <meta
          name="description"
          content="Consultez nos conditions d'echanges et de retours pour découvrir nos termes, modalités et plus encore."
        />
        <meta
          property="og:description"
          content="Consultez nos conditions d'echanges et de retours pour découvrir nos termes, modalités et plus encore."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta
          property="og:url"
          content="https://sweatteeshop.com/echanges-et-retours"
        />
        <meta property="og:site_name" content="sweatteeshop-officiel" />
        <link
          rel="canonical"
          href={`https://sweatteeshop.com/echanges-et-retours`}
        />
      </Helmet>
      <h1>{t("ChangesAndReturns")}</h1>
      <strong>{t("howToPrecessReturn")}</strong>
      <ul>
        <li>
          <strong>{t("ChangeAndReturnText3")}</strong>
        </li>
        <li>
          {t("ChangeAndReturnText2")} <a href={emailContact}>{emailContact}</a>
        </li>
      </ul>
      <ul>
        <li>
          <strong>{t("Withdrawal")}</strong>
        </li>
        <li>
          {t("ChangeAndReturnTex4")} <a href={emailContact}>{emailContact}</a>
        </li>
      </ul>
      <ul>
        <li>
          <strong>{t("prepareYourReturn")}</strong>
        </li>
        <li>{t("ChangeAndReturnTex5")}</li>
        <li>{t("ChangeAndReturnTex6")}</li>
        <li>{t("ChangeAndReturnTex7")}</li>
        <li>{t("ChangeAndReturnTex8")}</li>
        <li>{t("ChangeAndReturnTex9")}</li>
        <li>{t("ChangeAndReturnTex10")}</li>
      </ul>
      <ul>
        <li>
          <strong>{t("PROCESSINGTIMES")}</strong>
        </li>
        <li>{t("ChangeAndReturnTex11")}</li>
      </ul>
      <ul>
        <li>
          <strong>{t("ANYRETURNSHIPPINGCOSTS")}</strong>
        </li>
        <li>{t("ChangeAndReturnTex12")}</li>
        <li>{t("ChangeAndReturnTex13")}</li>
      </ul>
    </div>
  );
};

export default ChangesAndReturns;
