import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import CurtainFilter from "./CurtainFilter";
import { useTranslation } from "react-i18next";

const FilterBar = ({ onShare, setIsLoading }) => {
  const { t } = useTranslation();

  const [toggleSort, setToggleSort] = useState(false);
  const [toggleModels, setToggleModels] = useState(false);
  const [toggleSizes, setToggleSizes] = useState(false);
  const [toggleCollections, setToggleCollections] = useState(false);
  const [toggleFilterBar, setToggleFilterBar] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedCollection, setSelectedCollection] = useState("");
  const [products, setProducts] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [collections, setCollections] = useState([]);
  const [sorted, setSorted] = useState(null);
  const [filterColors, setFilterColors] = useState(null);
  const [filterSizes, setFilterSizes] = useState(null);
  const [filterCollections, setFilterCollections] = useState(null);
  const [togglefilter, setTogglefilter] = useState(false);
  const [showSorte, setShowSorte] = useState(false);
  const [showCollections, setShowCollections] = useState(false);
  const [showColors, setShowColors] = useState(false);
  const [showSizes, setShowSizes] = useState(false);

  const subMenuRefSort = useRef();
  const subMenuRefModels = useRef();
  const subMenuRefSizes = useRef();
  const subMenuRefCollections = useRef();
  const subMenuRefFilterBar = useRef();
  const curtainsFilterRef = useRef();

  const getProducts = async () => {
    const color = filterColors ? filterColors : "-1";
    const size = filterSizes ? filterSizes : "-1";
    const collection = filterCollections ? filterCollections : "-1";

    await axios
      .get(
        `https://api.sweatteeshop.com/front/products/${color}/${size}/${collection}`
      )
      .then((res) => {
        const productsObj = Object.values(res.data).sort(
          (a, b) => new Date(b.dateProduct) - new Date(a.dateProduct)
        );
        setProducts(productsObj);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getProducts(filterColors, filterSizes, filterCollections);

    const getColors = async () => {
      await axios
        .get("https://api.sweatteeshop.com/front/colors")
        .then((res) => {
          const colorsObj = Object.values(res.data);
          setColors(colorsObj);
        });
    };
    const getSizes = async () => {
      await axios.get("https://api.sweatteeshop.com/front/sizes").then((res) => {
        const sizesObj = Object.values(res.data);
        setSizes(sizesObj);
      });
    };
    const getCollections = async () => {
      await axios
        .get("https://api.sweatteeshop.com/front/collections")
        .then((res) => {
          const collectionsObj = Object.values(res.data);
          setCollections(collectionsObj);
        });
    };
    getProducts();
    getColors();
    getSizes();
    getCollections();
    setIsLoading(false);
  }, [filterColors, filterSizes, filterCollections]);

  const handleSortChangeAsc = () => {
    setSorted("ascending" ? "ascending" : "");
    const sortedProducts = [...products].sort((a, b) =>
      a.price > b.price ? 1 : -1
    );
    setProducts(sortedProducts);
  };

  const handleSortChangeDsc = () => {
    setSorted("descending" ? "descending" : "");
    const sortedProducts = [...products].sort((a, b) =>
      a.price < b.price ? 1 : -1
    );
    setProducts(sortedProducts);
  };

  const handleSelectColor = (idColor) => {
    setFilterColors(idColor);
  };

  const handleSelectSize = (idSize) => {
    setFilterSizes(idSize);
  };

  const handleSelectCollection = (idCollection) => {
    setFilterCollections(idCollection);
  };

  const resetSort = () => {
    setSelectedPrice(null);
    setSelectedColor(null);
    setSelectedSize(null);
    setSelectedCollection(null);
    setToggleFilterBar(false);
    setSorted("");

    window.location.reload();
  };

  useEffect(() => {
    let handler = (e) => {
      if (!subMenuRefSort.current.contains(e.target)) {
        setToggleSort(false);
      }
      if (!subMenuRefModels.current.contains(e.target)) {
        setToggleModels(false);
      }
      if (!subMenuRefSizes.current.contains(e.target)) {
        setToggleSizes(false);
      }
      if (!subMenuRefCollections.current.contains(e.target)) {
        setToggleCollections(false);
      }
      if (!curtainsFilterRef.current.contains(e.target)) {
        setTogglefilter(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [
    toggleSort,
    toggleModels,
    toggleSizes,
    toggleCollections,
    toggleFilterBar,
  ]);

  const toggleSortFunc = () => {
    setToggleSort(!toggleSort);
    if (toggleSort === false) {
      setToggleFilterBar(true);
    }
  };

  const toggleModelsFunc = () => {
    setToggleModels(!toggleModels);
    if (toggleModels === false) {
      setToggleFilterBar(true);
    }
  };

  const toggleSizesFunc = () => {
    setToggleSizes(!toggleSizes);
    if (toggleSizes === false) {
      setToggleFilterBar(true);
    }
  };

  const toggleCollectionsFunc = () => {
    setToggleCollections(!toggleCollections);
    if (toggleCollections === false) {
      setToggleFilterBar(true);
    }
  };

  useEffect(() => {
    onShare(products);
  }, [products]);

  return (
    <div className="filter-bar" ref={subMenuRefFilterBar}>
      <div className="curtain-filter-first-container" ref={curtainsFilterRef}>
        <div
          className="Curtain-filter"
          onClick={() => {
            setTogglefilter(!togglefilter);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 122.23 122.88"
            style={{ height: "15px", fill: "black" }}
          >
            <path
              className="st0"
              d="M122.23,12.35v10.54c0,1.29-1.21,2.35-2.69,2.35H77.85c-2.84,5.92-8.89,10.01-15.9,10.01 c-7,0-13.05-4.09-15.89-10.01H2.69C1.22,25.24,0,24.18,0,22.89V12.35C0,11.06,1.21,10,2.69,10h43.37c2.84-5.92,8.89-10,15.89-10 c7,0,13.05,4.09,15.89,10h41.69C121.02,10,122.23,11.06,122.23,12.35L122.23,12.35L122.23,12.35z M49.57,112.88 c-2.84,5.92-8.89,10-15.9,10c-7,0-13.05-4.08-15.89-10l-15.09,0c-1.48,0-2.69-1.06-2.69-2.35V99.99c0-1.29,1.21-2.35,2.69-2.35 l15.09,0c2.84-5.92,8.89-10.01,15.89-10.01c7,0,13.05,4.09,15.89,10.01h69.97c1.48,0,2.69,1.06,2.69,2.35v10.54 c0,1.29-1.22,2.35-2.69,2.35L49.57,112.88L49.57,112.88z M104.12,69.73c-2.84,5.92-8.89,10.01-15.89,10.01 c-7,0-13.05-4.09-15.9-10.01H2.69C1.22,69.73,0,68.67,0,67.38V56.85c0-1.29,1.21-2.35,2.69-2.35h69.64c2.84-5.92,8.89-10,15.89-10 c7,0,13.05,4.09,15.89,10h15.42c1.48,0,2.69,1.06,2.69,2.35v10.53c0,1.29-1.21,2.35-2.69,2.35H104.12L104.12,69.73z"
            />
          </svg>
          <span>{t("filter")}</span>
        </div>
        <CurtainFilter
          togglefilter={togglefilter}
          setTogglefilter={setTogglefilter}
          showCollections={showCollections}
          setShowCollections={setShowCollections}
          showColors={showColors}
          setShowColors={setShowColors}
          showSizes={showSizes}
          setShowSizes={setShowSizes}
          colors={colors}
          sizes={sizes}
          collections={collections}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
          handleSelectColor={handleSelectColor}
          selectedSize={selectedSize}
          setSelectedSize={setSelectedSize}
          handleSelectSize={handleSelectSize}
          selectedCollection={selectedCollection}
          setSelectedCollection={setSelectedCollection}
          handleSelectCollection={handleSelectCollection}
          selectedPrice={selectedPrice}
          setSelectedPrice={setSelectedPrice}
          handleSortChangeAsc={handleSortChangeAsc}
          handleSortChangeDsc={handleSortChangeDsc}
          showSorte={showSorte}
          setShowSorte={setShowSorte}
        />
        {(selectedPrice ||
          selectedSize ||
          selectedColor ||
          selectedCollection) && (
          <div className="cancelFilter">
            <span
              className="btnCancelFilter"
              onClick={() => {
                resetSort();
              }}
            >
              {t("removeFilter")}
            </span>
          </div>
        )}
      </div>
      <div
        className={`bg-curtains-filter ${togglefilter ? "active" : "inactive"}`}
      ></div>
      <ul className="filterbar-items-container">
        <li
          className={`title-Filter ${toggleFilterBar ? "active" : "inactive"}`}
          ref={subMenuRefSort}
        >
          <div onClick={toggleSortFunc}>
            <span>{t("sorteBy")}</span>
            <svg
              className={`arrow-icon ${toggleSort ? "active" : "inactive"}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 266.77"
            >
              <path d="M493.12 3.22c4.3-4.27 11.3-4.3 15.62-.04a10.85 10.85 0 0 1 .05 15.46L263.83 263.55c-4.3 4.28-11.3 4.3-15.63.05L3.21 18.64a10.85 10.85 0 0 1 .05-15.46c4.32-4.26 11.32-4.23 15.62.04L255.99 240.3 493.12 3.22z" />
            </svg>
          </div>
          <ul
            className={`items-filter-container ${
              toggleSort ? "active" : "inactive"
            }`}
          >
            <li className="filter-Item">
              <input
                type="radio"
                value="Prix croissants"
                id="PrixCroissants"
                checked={"Prix croissants" === selectedPrice}
                onChange={(e) => {
                  setSelectedPrice(e.target.value);
                  handleSortChangeAsc();
                }}
              />
              <label htmlFor="PrixCroissants">{t("IncreasingPrices")}</label>
              <svg
                className="checked-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 457.57"
              >
                <path d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z" />
              </svg>
            </li>
            <li className="filter-Item">
              <input
                type="radio"
                value="Prix decroissants"
                id="PrixDecroissants"
                checked={"Prix decroissants" === selectedPrice}
                onChange={(e) => {
                  setSelectedPrice(e.target.value);
                  handleSortChangeDsc();
                }}
              />
              <label htmlFor="PrixDecroissants">{t("decreasingPrices")}</label>
              <svg
                className="checked-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 457.57"
              >
                <path d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z" />
              </svg>
            </li>
          </ul>
        </li>
        <li
          className={`title-Filter ${toggleFilterBar ? "active" : "inactive"}`}
          ref={subMenuRefModels}
        >
          <div onClick={toggleModelsFunc}>
            <span>{t("colors")}</span>
            <svg
              className={`arrow-icon ${toggleModels ? "active" : "inactive"}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 266.77"
            >
              <path d="M493.12 3.22c4.3-4.27 11.3-4.3 15.62-.04a10.85 10.85 0 0 1 .05 15.46L263.83 263.55c-4.3 4.28-11.3 4.3-15.63.05L3.21 18.64a10.85 10.85 0 0 1 .05-15.46c4.32-4.26 11.32-4.23 15.62.04L255.99 240.3 493.12 3.22z" />
            </svg>
          </div>
          <ul
            className={`items-filter-container filter-items-color ${
              toggleModels ? "active" : "inactive"
            }`}
          >
            {colors.map((color) => {
              return (
                <li className="filter-Item" key={color.idColor}>
                  <input
                    type="checkbox"
                    value={color.nameColor}
                    id={color.nameColor}
                    checked={color.nameColor === selectedColor}
                    onChange={(e) => {
                      setSelectedColor(e.target.value);
                      handleSelectColor(color.idColor);
                    }}
                  />
                  <label htmlFor={color.nameColor}>
                    <div
                      htmlFor={color.nameColor}
                      style={{
                        backgroundColor: color.code,
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        border:
                          color.nameColor === selectedColor
                            ? "3px solid #aaa"
                            : "1px solid #eee",
                      }}
                    ></div>
                  </label>
                </li>
              );
            })}
          </ul>
        </li>
        <li
          className={`title-Filter ${toggleFilterBar ? "active" : "inactive"}`}
          ref={subMenuRefSizes}
        >
          <div onClick={toggleSizesFunc}>
            <span>{t("sizes")}</span>
            <svg
              className={`arrow-icon arrowDownIcon ${
                toggleSizes ? "active" : "inactive"
              }`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 266.77"
            >
              <path d="M493.12 3.22c4.3-4.27 11.3-4.3 15.62-.04a10.85 10.85 0 0 1 .05 15.46L263.83 263.55c-4.3 4.28-11.3 4.3-15.63.05L3.21 18.64a10.85 10.85 0 0 1 .05-15.46c4.32-4.26 11.32-4.23 15.62.04L255.99 240.3 493.12 3.22z" />
            </svg>
          </div>
          <ul
            className={`items-filter-container ${
              toggleSizes ? "active" : "inactive"
            }`}
          >
            {sizes
              .sort((a, b) => {
                const orderSizes = ["XS", "S", "M", "L", "XL", "2XL", "3XL"];
                return (
                  orderSizes.indexOf(a.nameSize) -
                  orderSizes.indexOf(b.nameSize)
                );
              })
              .map((size) => {
                return (
                  <li className="filter-Item" key={size.idSize}>
                    <input
                      type="checkbox"
                      value={size.nameSize}
                      id={size.nameSize}
                      checked={size.nameSize === selectedSize}
                      onChange={(e) => {
                        setSelectedSize(e.target.value);
                        handleSelectSize(size.idSize);
                      }}
                    />
                    <label htmlFor={size.nameSize}>{size.nameSize}</label>
                    <svg
                      className="checked-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 457.57"
                    >
                      <path d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z" />
                    </svg>
                  </li>
                );
              })}
          </ul>
        </li>
        <li
          className={`title-Filter ${toggleFilterBar ? "active" : "inactive"}`}
          ref={subMenuRefCollections}
        >
          <div onClick={toggleCollectionsFunc}>
            <span>Collections</span>
            <svg
              className={`arrow-icon arrowDownIcon ${
                toggleCollections ? "active" : "inactive"
              }`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 266.77"
            >
              <path d="M493.12 3.22c4.3-4.27 11.3-4.3 15.62-.04a10.85 10.85 0 0 1 .05 15.46L263.83 263.55c-4.3 4.28-11.3 4.3-15.63.05L3.21 18.64a10.85 10.85 0 0 1 .05-15.46c4.32-4.26 11.32-4.23 15.62.04L255.99 240.3 493.12 3.22z" />
            </svg>
          </div>
          <ul
            className={`items-filter-container ${
              toggleCollections ? "active" : "inactive"
            }`}
          >
            {collections.map((collection) => {
              return (
                <li className="filter-Item" key={collection.idCollection}>
                  <input
                    type="checkbox"
                    value={collection.nameCollection}
                    id={collection.nameCollection}
                    checked={collection.nameCollection === selectedCollection}
                    onChange={(e) => {
                      setSelectedCollection(e.target.value);
                      handleSelectCollection(collection.idCollection);
                    }}
                  />
                  <label htmlFor={collection.nameCollection}>
                    {collection.nameCollection}
                  </label>
                  <svg
                    className="checked-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 457.57"
                  >
                    <path d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z" />
                  </svg>
                </li>
              );
            })}
          </ul>
        </li>
        {(selectedPrice ||
          selectedSize ||
          selectedColor ||
          selectedCollection) && (
          <div className="cancelFilter">
            <span
              className="btnCancelFilter"
              onClick={() => {
                resetSort();
              }}
            >
              {t("removeFilter")}
            </span>
          </div>
        )}
      </ul>
    </div>
  );
};

export default FilterBar;
