import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ParamContext } from "../contexts/ParamContexts";
import { useTranslation } from "react-i18next";
import ContentCartCheckout from "../components/ContentCartCheckout";
import AddressUserCard from "../components/AddressUserCard";
import axios from "axios";
import CheckoutPayment from "../components/CheckoutPayment";

const CheckoutUser = ({ setIsLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    totalQuantity,
    usersDatas,
    idUser,
    setAddressDeliveryID,
    setAddressBillingID,
    addressDeliveryID,
    addressBillingID,
    setComeFrom,
  } = useContext(ParamContext);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        totalQuantity < 0 ||
        totalQuantity === null ||
        totalQuantity === undefined
      ) {
        navigate("/");
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [navigate, totalQuantity]);

  const [address, setAddress] = useState([]);
  const [isDsiplayed, setIsDsiplayed] = useState(false);
  const [activeStep, setActiveStep] = useState(
    parseInt(sessionStorage.getItem("activeStep")) || 1
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getAddressData = async (url, setAddressFunction) => {
      try {
        const response = await axios.get(url);
        const infoObj = Object.values(response.data);
        const billingData = infoObj.filter(
          (address) => address.isDefaultBilling === 1
        );
        const deliveryData = infoObj.filter(
          (address) => address.isDefaultDelivery === 1
        );
        setAddressFunction(infoObj);
        setAddressBillingID(billingData);
        setAddressDeliveryID(deliveryData);
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la récupération des données :",
          error
        );
      }
    };

    getAddressData(
      `https://api.sweatteeshop.com/front/users/Address_infos/${idUser}`,
      setAddress
    );
    setIsLoading(false);
  }, [idUser, setAddressBillingID, setAddressDeliveryID, setIsLoading]);

  let deliveryToDisplay;
  if (address.length > 0) {
    deliveryToDisplay = address
      .filter((address) => address.isDefaultDelivery === 0)
      .map((address) => (
        <AddressUserCard
          key={address.idAddress}
          idAddress={address.idAddress}
          firstName={address.firstName}
          lastName={address.lastName}
          email={address.email}
          phone={address.phone}
          address={address.address}
          addressComp={address.addressComp}
          city={address.city}
          zip={address.zipCode}
          country={address.country}
          isDefault={false}
          activeStep={activeStep}
        />
      ));
  } else {
    deliveryToDisplay = (
      <div className="contentEmpty">
        <h3>{t("NoAddress")}</h3>
      </div>
    );
  }

  let BillingToDisplay;
  if (address.length > 0) {
    BillingToDisplay = address
      .filter((address) => address.isDefaultBilling === 0)
      .map((address) => (
        <AddressUserCard
          key={address.idAddress}
          idAddress={address.idAddress}
          firstName={address.firstName}
          lastName={address.lastName}
          email={address.email}
          phone={address.phone}
          address={address.address}
          addressComp={address.addressComp}
          city={address.city}
          zip={address.zipCode}
          country={address.country}
          isDefault={false}
          activeStep={activeStep}
        />
      ));
  } else {
    BillingToDisplay = (
      <div className="contentEmpty">
        <h3>{t("NoAddress")}</h3>
      </div>
    );
  }

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
    setIsDsiplayed(false);
    sessionStorage.setItem("activeStep", activeStep + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
    setIsDsiplayed(false);
    sessionStorage.setItem("activeStep", activeStep - 1);
  };

  return (
    <div className="checkout-nd-page">
      <div className="left-container-nd-step">
        <div className="guess-forms-container">
          <p>{t("securePay")}</p>
          <div className="email-guess-container">
            <span>
              {t("contactEmail")}
              {" : "}
              {usersDatas?.email}
            </span>
          </div>
          <div>1. {t("DeliveryAddress")}.</div>
          {activeStep === 1 && (
            <>
              {addressDeliveryID.length > 0 && (
                <div className="address-user-checkout">
                  <AddressUserCard
                    idAddress={addressDeliveryID[0]?.idAddress}
                    firstName={addressDeliveryID[0]?.firstName}
                    lastName={addressDeliveryID[0]?.lastName}
                    email={addressDeliveryID[0]?.email}
                    phone={addressDeliveryID[0]?.phone}
                    address={addressDeliveryID[0]?.address}
                    addressComp={addressDeliveryID[0]?.addressComp}
                    city={addressDeliveryID[0]?.city}
                    zip={addressDeliveryID[0]?.zipCode}
                    country={addressDeliveryID[0]?.country}
                    isFromOrder={true}
                  />
                </div>
              )}
              <div className="address-user-checkout">
                {addressDeliveryID.length < 0 && (
                  <span>{t("AdditionalAddresses")}</span>
                )}
                <div className="btn-checkout-address">
                  <button
                    className="btn-display-address"
                    type="button"
                    onClick={() => setIsDsiplayed(!isDsiplayed)}
                  >
                    <span>{t("MoreAdrress")}</span>
                  </button>
                  <a href="/checkout/creer-une-adresse">
                    <button
                      className="btn-display-address"
                      type="button"
                      onClick={() => {
                        setComeFrom("delivery");
                        sessionStorage.setItem("comeFrom", "delivery");
                      }}
                    >
                      <span>{t("CreatingANewAddress")}</span>
                    </button>
                  </a>
                </div>
                {isDsiplayed && deliveryToDisplay}
              </div>
              <div className="btn-checkout-container">
                <a href="/panier">
                  <button type="button">
                    <span>{t("previous")}</span>
                  </button>
                </a>
                <button type="button" onClick={handleNextStep}>
                  <span>{t("Next")}</span>
                </button>
              </div>
            </>
          )}
          <div>2. {t("BillingAddress")}.</div>
          {activeStep === 2 && (
            <>
              <div className="address-user-checkout">
                <span>{t("BillingAddress")}</span>
                {addressBillingID.length > 0 && (
                  <div className="address-user-checkout">
                    <AddressUserCard
                      idAddress={addressBillingID[0]?.idAddress}
                      firstName={addressBillingID[0]?.firstName}
                      lastName={addressBillingID[0]?.lastName}
                      email={addressBillingID[0]?.email}
                      phone={addressBillingID[0]?.phone}
                      address={addressBillingID[0]?.address}
                      addressComp={addressBillingID[0]?.addressComp}
                      city={addressBillingID[0]?.city}
                      zip={addressBillingID[0]?.zipCode}
                      country={addressBillingID[0]?.country}
                      isFromOrder={true}
                    />
                  </div>
                )}
              </div>
              <div className="address-user-checkout">
                {addressBillingID.length < 0 && (
                  <span>{t("AdditionalAddresses")}</span>
                )}
                <div className="btn-checkout-address">
                  <button
                    className="btn-display-address"
                    type="button"
                    onClick={() => setIsDsiplayed(!isDsiplayed)}
                  >
                    <span>{t("MoreAdrress")}</span>
                  </button>
                  <a href="/checkout/creer-une-adresse">
                    <button
                      className="btn-display-address"
                      type="button"
                      onClick={() => {
                        setComeFrom("bill");
                        sessionStorage.setItem("comeFrom", "bill");
                      }}
                    >
                      <span>{t("CreatingANewAddress")}</span>
                    </button>
                  </a>
                </div>
                {isDsiplayed && BillingToDisplay}
              </div>
              <div className="btn-checkout-container">
                <button type="button" onClick={handlePreviousStep}>
                  <span>{t("previous")}</span>
                </button>
                <button type="button" onClick={handleNextStep}>
                  <span>{t("Next")}</span>
                </button>
              </div>
            </>
          )}
          <CheckoutPayment
            isActive={activeStep === 3}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
          />
        </div>
      </div>
      <div className="right-container-nd-step">
        <ContentCartCheckout setIsLoading={setIsLoading} />
      </div>
    </div>
  );
};

export default CheckoutUser;
