import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ParamContext } from "../contexts/ParamContexts";
import { Helmet } from "react-helmet";

const Delivery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  const baseUrl = "https://api.sweatteeshop.com/public/images/";

  const { nameCompany, imgOpenTags, thresholdAmount } =
    useContext(ParamContext);

  return (
    <div className="container-size-page">
      <Helmet>
        <title>{`${nameCompany} | Tarifs et délais de livraison`}</title>
        <meta
          property="og:title"
          content={`${nameCompany} | Tarifs et délais de livraison`}
        />
        <meta
          name="description"
          content="Consultez nos tarifs et délais de livraison pour découvrir nos termes, modalités et plus encore."
        />
        <meta
          property="og:description"
          content="Consultez nos tarifs et délais de livraison pour découvrir nos termes, modalités et plus encore."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta property="og:url" content="https://sweatteeshop.com/livraisons" />
        <meta property="og:site_name" content="sweatteeshop-officiel" />
        <link rel="canonical" href={`https://sweatteeshop.com/livraisons`} />
      </Helmet>
      <h1>{t("Delivery")}</h1>
      <ul>
        <li>
          <li>
            <strong>{t("FreeDelivery")}</strong>
          </li>
        </li>
        <li>
          {t("freeDeliveryPrice")} {thresholdAmount} €.
        </li>
      </ul>
      <li>
        <strong>{t("DeliveryMode")} </strong>
      </li>
      <ul>
        <li>
          <img
            className="img-colissimo"
            src="../img/klipartz.com.png"
            alt="Colissimo"
          />
        </li>
        <li>{t("ColissimoDelivery")}</li>
        <li>{t("DeliveryText1")}</li>
      </ul>
      <div className="size-guide">
        <li>
          <strong>{t("Cost")}</strong>
        </li>
        <table>
          <thead>
            <tr>
              <th>{t("WeightRange")}</th>
              <th>{t("ColissimoHomeDelivery")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0-0,75KG</td>
              <td>5,99€</td>
            </tr>
            <tr>
              <td>0,75-1KG</td>
              <td>6,99€</td>
            </tr>
            <tr>
              <td>1-3KG</td>
              <td>7,99€</td>
            </tr>
            <tr>
              <td>3-5KG</td>
              <td>8,99€</td>
            </tr>
            <tr>
              <td>{t("Beyond")} 5KG</td>
              <td>15€</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Delivery;
