import axios from "axios";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const ParamContext = createContext(null);

export const ParamContextProvider = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // website param
  const [logo, setLogo] = useState("");
  const [logoVar, setLogoVar] = useState("");
  const [products, setProducts] = useState([]);
  const [collections, setCollections] = useState([]);

  const [homeHero, setHomeHero] = useState("");
  const [homeTitle, setHomeTitle] = useState("");
  const [homeSubTitle, setHomeSubTitle] = useState("");
  const [imgHomeCover, SetimgHomeCover] = useState("");

  const [tshirtImg, setTshirtImg] = useState("");
  const [sweatImg, setSweatImg] = useState("");
  const [bagImg, setBagImg] = useState("");
  const [newInImg, setNewInImg] = useState("");
  const [imgOpenTags, SetimgOpenTags] = useState("");

  const [bande1, setBande1] = useState("");
  const [bande2, setBande2] = useState("");
  const [bande3, setBande3] = useState("");

  const [isDesplayBandeST, setIsDesplayBandeST] = useState("");
  const [isDesplayBandeND, setIsDesplayBandeND] = useState("");
  const [isDesplayBandeRD, setIsDesplayBandeRD] = useState("");

  const [nameCompany, setNameCompany] = useState("");
  const [statusCompany, setStatusCompany] = useState("");
  const [addressCompany, setAddressCompany] = useState("");
  const [zipCompany, setZipCompany] = useState("");
  const [cityCompany, setCityCompany] = useState("");
  const [countryCompany, setCountryCompany] = useState("");
  const [idCompany, setIdCompany] = useState("");
  const [capitalCompany, setCapitalCompany] = useState("");
  const [rcs, setRcs] = useState("");
  const [publisherCompany, setPublisherCompany] = useState("");
  const [vat, setVat] = useState("");

  const [nameContact, setNameContact] = useState("");
  const [emailContact, setEmailContact] = useState("");
  const [addressContact, setAddressContact] = useState("");
  const [zipContact, setZipContact] = useState("");
  const [cityContact, setCityContact] = useState("");
  const [countryContact, setCountryContact] = useState("");
  const [webSide, SetWebSide] = useState("");

  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [thresholdAmount, setThresholdAmount] = useState("");

  const [popupMessage, setPopupMessage] = useState("");
  const [isdisplaypfopup, SetIsdisplaypfopup] = useState("");

  const [loadingParam, setLoadingParam] = useState(false);

  const baseUrl = "https://api.sweatteeshop.com/public/images/";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [settingsResponse, productsResponse, collectionsRes] =
          await Promise.all([
            axios.get("https://api.sweatteeshop.com/back/settings/datas"),
            axios.get("https://api.sweatteeshop.com/front/products"),
            axios.get("https://api.sweatteeshop.com/front/collections"),
          ]);

        const settingsObj = Object.values(settingsResponse.data);
        settingsObj.forEach((setting) => {
          switch (setting.refSettings) {
            case "logo":
              setLogo(baseUrl + setting.settingValue);
              break;
            case "facebook":
              setFacebook(setting.settingValue);
              break;
            case "instagram":
              setInstagram(setting.settingValue);
              break;
            case "tiktok":
              setTiktok(setting.settingValue);
              break;
            case "adress company":
              setAddressCompany(setting.settingValue);
              break;
            case "zip company":
              setZipCompany(setting.settingValue);
              break;
            case "status company":
              setStatusCompany(setting.settingValue);
              break;
            case "city company":
              setCityCompany(setting.settingValue);
              break;
            case "country Company":
              setCountryCompany(setting.settingValue);
              break;
            case "id company":
              setIdCompany(setting.settingValue);
              break;
            case "share capital":
              setCapitalCompany(setting.settingValue);
              break;
            case "publisher":
              setPublisherCompany(setting.settingValue);
              break;
            case "name company":
              setNameCompany(setting.settingValue);
              break;
            case "name contact":
              setNameContact(setting.settingValue);
              break;
            case "email contact":
              setEmailContact(setting.settingValue);
              break;
            case "address contact":
              setAddressContact(setting.settingValue);
              break;
            case "zip contact":
              setZipContact(setting.settingValue);
              break;
            case "City contact":
              setCityContact(setting.settingValue);
              break;
            case "country contact":
              setCountryContact(setting.settingValue);
              break;
            case "home title":
              setHomeTitle(setting.settingValue);
              break;
            case "home sub title":
              setHomeSubTitle(setting.settingValue);
              break;
            case "Hero Home page":
              setHomeHero(baseUrl + setting.settingValue);
              break;
            case "T-shirt-img":
              setTshirtImg(`url(${baseUrl}${setting.settingValue})`);
              break;
            case "sweat-img":
              setSweatImg(`url(${baseUrl}${setting.settingValue})`);
              break;
            case "Accessories-img":
              setBagImg(`url(${baseUrl}${setting.settingValue})`);
              break;
            case "NewIn-img":
              setNewInImg(`url(${baseUrl}${setting.settingValue})`);
              break;
            case "imgOpenTags":
              SetimgOpenTags(baseUrl + setting.settingValue);
              break;
            case "rcs":
              setRcs(setting.settingValue);
              break;
            case "bandeST":
              setBande1(setting.settingValue);
              break;
            case "bandeND":
              setBande2(setting.settingValue);
              break;
            case "bandeRD":
              setBande3(setting.settingValue);
              break;
            case "isDesplayBandeST":
              setIsDesplayBandeST(setting.settingValue);
              break;
            case "isDesplayBandeND":
              setIsDesplayBandeND(setting.settingValue);
              break;
            case "isDesplayBandeRD":
              setIsDesplayBandeRD(setting.settingValue);
              break;
            case "imgHomeCover":
              SetimgHomeCover(`url(${baseUrl}${setting.settingValue})`);
              break;
            case "thresholdAmount":
              setThresholdAmount(setting.settingValue);
              break;
            case "popupMessage":
              setPopupMessage(setting.settingValue);
              break;
            case "isdisplaypfopup":
              SetIsdisplaypfopup(setting.settingValue);
              break;
            case "website":
              SetWebSide(setting.settingValue);
              break;
            case "vat":
              setVat(setting.settingValue);
              break;
            case "Logo-variation":
              setLogoVar(baseUrl + setting.settingValue);
              break;
            default:
              break;
          }
        });

        const productsObj = Object.values(productsResponse.data);
        const collectionsObj = Object.values(collectionsRes.data);
        setProducts(productsObj);
        setCollections(collectionsObj);
        setLoadingParam(true);
      } catch (error) {
        console.error("Une erreur s'est produite : ", error);
        setLoadingParam(true);
      }
    };

    fetchData();
  }, []);

  // Admin Authentification
  const [idAdmin, setAdminId] = useState(
    JSON.parse(sessionStorage.getItem("idAdmin")) || ""
  );

  const setCurrentAdmin = (id) => {
    setAdminId(id);
    sessionStorage.setItem("idAdmin", id);
  };

  const [adminStatue, setAdminStatue] = useState(
    sessionStorage.getItem("adminStatue") || false
  );

  const [adminData, setAdminData] = useState({});

  const handleLoginAdmin = () => {
    setAdminStatue(true);
    sessionStorage.setItem("adminStatue", adminStatue);
  };

  const handleLogoutAdmin = () => {
    setAdminStatue(false);
    setAdminData({});
    setAdminId("");
    navigate("/back/login");
    window.location.reload();
  };

  useEffect(() => {
    const getAdmin = async () => {
      await axios
        .get(`https://api.sweatteeshop.com/back/admin/data/${idAdmin}`)
        .then((res) => {
          const infoObj = Object.values(res.data);
          setAdminData(infoObj[0]);
        });
    };
    getAdmin();
  }, [idAdmin, adminStatue]);

  // Users Param

  const [idUser, setIdUser] = useState(
    JSON.parse(sessionStorage.getItem("idUser")) || ""
  );

  const setCurrentUser = (id) => {
    setIdUser(id);
  };

  const [userStatue, setUserStatue] = useState(
    JSON.parse(sessionStorage.getItem("userStatue")) || false
  );
  const [usersDatas, setUsersDatas] = useState([]);

  const handleLoginUser = () => {
    setUserStatue(true);
  };

  const handleLogoutUser = () => {
    setUserStatue(false);
    setUsersDatas([]);
    setIdUser("");
    navigate("/");
    sessionStorage.removeItem("idAdmin");
    sessionStorage.removeItem("transferCompleted");
    window.location.reload();
  };

  useEffect(() => {
    const getUser = async () => {
      await axios
        .get(`https://api.sweatteeshop.com/front/users/data/${idUser}`)
        .then((res) => {
          const infoObj = Object.values(res.data);
          setUsersDatas(infoObj[0]);
        });
    };
    getUser();
  }, [idUser]);

  useEffect(() => {
    sessionStorage.setItem("idUser", JSON.stringify(idUser));
  }, [idUser]);

  // Cart management
  const getDefaultCart = () => {
    let cart = {};
    for (let i = 1; i < products.length + 1; i++) {
      cart[i] = { quantity: 0, color: "", size: "" };
    }
    return cart;
  };

  const getCartItemCount = () => {
    let quantity = 0;
    for (const item in cartItems) {
      if (cartItems[item] && cartItems[item].quantity > 0) {
        quantity += cartItems[item].quantity;
      }
    }
    return quantity;
  };

  const [cartItems, setCartItems] = useState(
    JSON.parse(sessionStorage.getItem("cartItems")) || getDefaultCart()
  );
  const [cartItemCount, setCartItemCount] = useState(getCartItemCount());
  const [promoCode, setPromoCode] = useState(
    sessionStorage.getItem("promoCode") || ""
  );
  const [codeName, setCodeName] = useState(
    sessionStorage.getItem("codeName") || ""
  );
  const [discount, setDiscount] = useState(
    sessionStorage.getItem("discount") || 0
  );
  const [rateDiscount, setRateDiscount] = useState(
    sessionStorage.getItem("rateDiscount") || 0
  );
  const [typeDiscount, setTypeDiscount] = useState(
    sessionStorage.getItem("typeDiscount") || ""
  );

  const [selectedColor, setSelectedColor] = useState();
  const [selectedSize, setSelectedSize] = useState();
  const [validPromoCodes, setValidPromoCodes] = useState([]);

  const shareColor = (datas) => {
    setSelectedColor(datas);
  };

  const shareSize = (datas) => {
    setSelectedSize(datas);
  };

  useEffect(() => {
    const getProducts = async () => {
      await axios
        .get(`https://api.sweatteeshop.com/front/products`)
        .then((res) => {
          const productsObj = Object.values(res.data);
          setProducts(productsObj);
        });
    };
    const getPromosCodes = async () => {
      await axios
        .get(`https://api.sweatteeshop.com/front/promos_codes`)
        .then((res) => {
          const codeObj = Object.values(res.data);
          setValidPromoCodes(codeObj);
        });
    };
    getProducts();
    getPromosCodes();
  }, []);

  useEffect(() => {
    sessionStorage.setItem("cartItems", JSON.stringify(cartItems));
    sessionStorage.setItem("discount", discount);
    sessionStorage.setItem("rateDiscount", rateDiscount);
    sessionStorage.setItem("typeDiscount", typeDiscount);
    sessionStorage.setItem("userStatue", JSON.stringify(userStatue));
  }, [cartItems, discount, rateDiscount, typeDiscount, userStatue]);

  // Guess cart
  const [shippingPriceGuess, setShippingPriceGuess] = useState(
    sessionStorage.getItem("shippingPriceGuess") || 0
  );

  const putOnCart = (itemId, itemPrice, itemWeigth, itemSize, itemColor) => {
    const uniqueKey = `${itemId}-${itemSize}-${itemColor}`;
    let storedCartItems = JSON.parse(sessionStorage.getItem("cartItems"));
    let cartItemsCopy = { ...storedCartItems };

    itemPrice = parseFloat(itemPrice);
    itemWeigth = parseFloat(itemWeigth);

    if (!cartItemsCopy[uniqueKey]) {
      cartItemsCopy[uniqueKey] = {
        quantity: 1,
        idProduct: itemId,
        price: itemPrice,
        weigth: itemWeigth,
        idSize: itemSize,
        idColor: itemColor,
      };
    } else {
      cartItemsCopy[uniqueKey] = {
        ...cartItemsCopy[uniqueKey],
        quantity: cartItemsCopy[uniqueKey].quantity + 1,
        price: cartItemsCopy[uniqueKey].price + itemPrice,
        weigth: cartItemsCopy[uniqueKey].weigth + itemWeigth,
      };
    }

    sessionStorage.setItem("cartItems", JSON.stringify(cartItemsCopy));
    setCartItems(cartItemsCopy);
    setCartItemCount((prevCount) => prevCount + 1);
    setPromoCode("");
    setCodeName("");
    setDiscount(0);
  };

  const addToCart = (uniqueKey, price, weigth) => {
    let storedCartItems = JSON.parse(sessionStorage.getItem("cartItems"));

    if (storedCartItems[uniqueKey]) {
      storedCartItems[uniqueKey].quantity++;
      storedCartItems[uniqueKey].price =
        price * storedCartItems[uniqueKey].quantity;
      storedCartItems[uniqueKey].weigth =
        weigth * storedCartItems[uniqueKey].quantity;
    }

    sessionStorage.setItem("cartItems", JSON.stringify(storedCartItems));

    setCartItems(storedCartItems);
    setCartItemCount((prevCount) => prevCount + 1);
    setPromoCode("");
    setCodeName("");
    setDiscount(0);
  };

  const subtractFromCart = (uniqueKey, price, weigth) => {
    let storedCartItems = JSON.parse(sessionStorage.getItem("cartItems"));
    if (storedCartItems[uniqueKey]) {
      storedCartItems[uniqueKey].quantity--;
      storedCartItems[uniqueKey].price =
        storedCartItems[uniqueKey].price - price;
      storedCartItems[uniqueKey].weigth =
        storedCartItems[uniqueKey].weigth - weigth;
    }
    sessionStorage.setItem("cartItems", JSON.stringify(storedCartItems));
    setCartItems(storedCartItems);
    setCartItemCount((prevCount) => prevCount - 1);
    setPromoCode("");
    setCodeName("");
    setDiscount(0);
  };

  const removeFromCart = (uniqueKey) => {
    let storedCartItems = JSON.parse(sessionStorage.getItem("cartItems"));
    if (storedCartItems[uniqueKey]) {
      delete storedCartItems[uniqueKey];
      sessionStorage.setItem("cartItems", JSON.stringify(storedCartItems));
    }
    sessionStorage.setItem("cartItems", JSON.stringify(storedCartItems));
    setCartItems(storedCartItems);
    setCartItemCount(() => {
      let count = 0;
      for (const item in storedCartItems) {
        if (storedCartItems[item].quantity > 0) {
          count += storedCartItems[item].quantity;
        }
      }
      return count;
    });
    setPromoCode("");
    setCodeName("");
    setDiscount(0);
  };

  const emptyCart = () => {
    setCartItems("");
    setPromoCode("");
    setCodeName("");
    setDiscount(0);
    setCartItemCount("");
    sessionStorage.removeItem("totalAmountCart");
    sessionStorage.removeItem("cartItems");
    sessionStorage.removeItem("shipAmount");
    sessionStorage.removeItem("promoCode");
    sessionStorage.removeItem("codeName");
    sessionStorage.removeItem("discount");
    sessionStorage.removeItem("rateDiscount");
    sessionStorage.removeItem("typeDiscount");
  };

  // Cart User
  const [cartsProducts, setCartsProducts] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState("");
  const [shippingUser, setShippingUser] = useState(
    sessionStorage.getItem("shippingUser") || 0
  );
  const [cartsProductsVersion, setCartsProductsVersion] = useState(0);
  const [moreOnCart, setMoreOnCart] = useState(0);
  const [lessOnCart, setLessOnCart] = useState(0);
  const [deleteOnCart, setDeleteOnCart] = useState(0);
  const [deleteCart, setDeleteCart] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(
    sessionStorage.getItem("discountAmount") || 0
  );
  const [valueDiscountUser, setValueDiscountUser] = useState(
    sessionStorage.getItem("valueDiscountUser") || 0
  );
  const [typeDiscountUser, setTypeDiscountUser] = useState(
    sessionStorage.getItem("typeDiscountUser") || ""
  );
  const [pricesProduct, setPricesProduct] = useState(0);
  const [codePromoMessage, setCodePromoMessage] = useState("");
  const [addressDeliveryID, setAddressDeliveryID] = useState("");
  const [addressBillingID, setAddressBillingID] = useState("");
  const [totalPrice, setTotalPrice] = useState(
    sessionStorage.getItem("totalPrice") || 0
  );

  const [code, setCode] = useState(
    JSON.parse(sessionStorage.getItem("promoCodeUser")) || ""
  );

  const [cartsProductsStored] = useState(
    JSON.parse(sessionStorage.getItem("cartsProducts")) || []
  );

  const shipAmountUser = 0;

  const API_URL = "https://api.sweatteeshop.com/front/promos_codes";

  const checkPromoCodeValidity = async (promoCodeUser) => {
    try {
      const response = await axios.get(
        `${API_URL}?codePromo=${promoCodeUser}&isActive=true`
      );
      const promoCodesDatas = Object.values(response.data);
      const currentDate = new Date();

      for (const promoCodeData of promoCodesDatas) {
        if (
          promoCodeData.codePromo === promoCodeUser &&
          promoCodeData.isActive === 1 &&
          currentDate >= new Date(promoCodeData.startingDate) &&
          currentDate <= new Date(promoCodeData.endingDate)
        ) {
          if (userStatue) {
            setCodePromoMessage("");
            setCode(promoCodeUser);
          } else {
            setCodePromoMessage("");
            setPromoCode(promoCodeUser);
            setCodeName(promoCodeUser);
            window.location.reload();
          }
          return { valid: true, promoCodeData };
        }
        if (!promoCodeUser) {
          setCodePromoMessage("");
          sessionStorage.removeItem("promoCode");
          sessionStorage.removeItem("codeName", codeName);
          setCode("");
          setPromoCode("");
          setCodeName("");
          setDiscount(0);
          window.location.reload();
          return { valid: true, promoCodeData };
        }
      }
      setCodePromoMessage("Code promo invalide");
      sessionStorage.removeItem("promoCode");
      sessionStorage.removeItem("codeName");
      setCode("");
      setPromoCode("");
      setCodeName("");
      setDiscount(0);
      return { valid: false, message: t("InvalidPromoCode") };
    } catch (error) {
      console.error(error);
      return { valid: false, message: "Erreur de connexion au serveur." };
    }
  };

  useEffect(() => {
    const getCart = async () => {
      await axios
        .get(
          `https://api.sweatteeshop.com/front/users/carts/products/${idUser}/${code}`
        )
        .then((res) => {
          const productsObj = Object.values(res.data);
          setCartsProducts(productsObj);
          setTotalQuantity(productsObj[0]?.totalQuantity);
          setTotalPrice(parseFloat(productsObj[0]?.totalPrice.toFixed(2)));
          setPricesProduct(productsObj[0]?.totalPriceProduct);
          setDiscountAmount(
            parseFloat(productsObj[0]?.discountAmount.toFixed(2))
          );
          setTypeDiscountUser(productsObj[0]?.typeDiscount);
          setValueDiscountUser(parseFloat(productsObj[0]?.valueDiscountUser));
          setShippingUser(parseFloat(productsObj[0]?.shippingPrice));
        });
    };
    getCart();
  }, [
    idUser,
    cartsProductsVersion,
    code,
    moreOnCart,
    lessOnCart,
    deleteOnCart,
    deleteCart,
  ]);

  useEffect(() => {
    sessionStorage.setItem("totalPrice", JSON.stringify(totalPrice));
    sessionStorage.setItem("promoCodeUser", JSON.stringify(code));
    sessionStorage.setItem("cartsProducts", JSON.stringify(cartsProducts));
    sessionStorage.setItem("shippingUser", JSON.stringify(shippingUser));
    sessionStorage.setItem("discountAmount", JSON.stringify(discountAmount));
    sessionStorage.setItem(
      "valueDiscountUser",
      JSON.stringify(valueDiscountUser)
    );
    sessionStorage.setItem(
      "typeDiscountUser",
      JSON.stringify(typeDiscountUser)
    );
    sessionStorage.setItem(
      "shippingPriceGuess",
      JSON.stringify(shippingPriceGuess)
    );
    sessionStorage.setItem("promoCode", promoCode);
    sessionStorage.setItem("codeName", codeName);
  }, [
    cartsProducts,
    code,
    codeName,
    discountAmount,
    promoCode,
    shippingPriceGuess,
    shippingUser,
    totalPrice,
    typeDiscountUser,
    valueDiscountUser,
  ]);

  function submiteOnCart(
    idUser,
    itemId,
    itemPrice,
    itemWeigth,
    itemSize,
    itemColor
  ) {
    const formatData = {
      idUser: idUser,
      idProduct: itemId,
      PriceProduct: itemPrice,
      Weigth: itemWeigth,
      selectedSize: itemSize,
      selectedColor: itemColor,
    };

    axios
      .post("https://api.sweatteeshop.com/front/users/carts/put", formatData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        setCartsProductsVersion(cartsProductsVersion + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onSubmitaddToCart(
    idProduct,
    idColor,
    idSize,
    itemPrice,
    itemWeigth
  ) {
    const formatData = {
      idUser: idUser,
      idProduct: idProduct,
      selectedColor: idColor,
      selectedSize: idSize,
      PriceProduct: itemPrice,
      Weigth: itemWeigth,
    };

    axios
      .post("https://api.sweatteeshop.com/front/users/carts/add", formatData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        setMoreOnCart(moreOnCart + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onSubmitSubstractToCart(
    idProduct,
    idColor,
    idSize,
    itemPrice,
    itemWeigth
  ) {
    const formatData = {
      idUser: idUser,
      idProduct: idProduct,
      selectedColor: idColor,
      selectedSize: idSize,
      PriceProduct: itemPrice,
      Weigth: itemWeigth,
    };
    axios
      .post(
        "https://api.sweatteeshop.com/front/users/carts/substact",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        setLessOnCart(lessOnCart + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onSubmitremovToCart(idProduct, idColor, idSize) {
    const formatData = {
      idUser: idUser,
      idProduct: idProduct,
      selectedColor: idColor,
      selectedSize: idSize,
    };

    axios
      .post(
        "https://api.sweatteeshop.com/front/users/carts/remove",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        setDeleteOnCart(deleteOnCart + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onSubmitEmptyCart() {
    const formatData = {
      idUser: idUser,
    };

    axios
      .post(
        "https://api.sweatteeshop.com/front/users/carts/delete",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        setDeleteCart(deleteCart + 1);
        setCode("");
        setCodeName("");
        sessionStorage.removeItem("totalPrice");
        sessionStorage.removeItem("promoCodeUser");
        sessionStorage.removeItem("cartsProducts");
        sessionStorage.removeItem("promoCode");
        sessionStorage.removeItem("codeName");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Cart calculator

  const subtotalGuessCart = useMemo(() => {
    const total = Object.values(cartItems).reduce((accumulator, cartItem) => {
      return accumulator + cartItem.price;
    }, 0);

    return total.toFixed(2);
  }, [cartItems]);

  const wigthGuessCart = useMemo(() => {
    const total = Object.values(cartItems).reduce((accumulator, cartItem) => {
      return accumulator + cartItem.weigth;
    }, 0);

    return total.toFixed(2);
  }, [cartItems]);

  const calcDeliveryCostGuess = (totalWeight, thresholdAmount) => {
    const weightTiers = [
      { maxWeight: 0.75, price: 5.99 },
      { maxWeight: 1, price: 6.99 },
      { maxWeight: 2, price: 7.99 },
      { maxWeight: 5, price: 8.99 },
      { maxWeight: Infinity, price: 15 },
    ];

    let deliveryPrice = 0;
    for (const tier of weightTiers) {
      if (totalWeight <= tier.maxWeight) {
        deliveryPrice = tier.price;
        break;
      }
    }

    const freeDeliveryThreshold = thresholdAmount;
    if (subtotalGuessCart >= freeDeliveryThreshold) {
      deliveryPrice = 0;
    }

    return deliveryPrice.toFixed(2);
  };

  const deliveryPriceGuess = calcDeliveryCostGuess(
    wigthGuessCart,
    parseFloat(thresholdAmount)
  );

  const totalCartGuess = useMemo(() => {
    const totalPrice =
      parseFloat(subtotalGuessCart) + parseFloat(deliveryPriceGuess) - discount;

    return totalPrice.toFixed(2);
  }, [subtotalGuessCart, deliveryPriceGuess, discount]);

  useEffect(() => {
    const code = validPromoCodes?.find(
      (promo) => promo.codePromo === promoCode
    );

    let newDiscountValue = 0;

    if (code) {
      if (code.type === "rate") {
        newDiscountValue = subtotalGuessCart * (code.value / 100);
      } else {
        const calc = subtotalGuessCart - code.value;
        newDiscountValue = subtotalGuessCart - calc;
      }
      setDiscount(Number(newDiscountValue.toFixed(2)));
      setRateDiscount(code.value);
      setTypeDiscount(code.type === "rate" ? "rate" : "fixed");
    }
    setShippingPriceGuess(parseFloat(deliveryPriceGuess));
  }, [validPromoCodes, promoCode, deliveryPriceGuess, subtotalGuessCart]);

  // Checkout guess
  const [emailGuess, setEmailGuess] = useState(
    JSON.parse(sessionStorage.getItem("emailGuess")) || []
  );

  const [deliveryformData, setDeliveryFormData] = useState(
    JSON.parse(sessionStorage.getItem("deliveryformData")) || {}
  );

  const [billingFormData, setBillingFormData] = useState(
    JSON.parse(sessionStorage.getItem("billingFormData")) || {}
  );

  const [isSomeData, setIsSomeData] = useState(
    JSON.parse(sessionStorage.getItem("isSomeData"))
  );

  const [billingFormDataStores, setBillingFormDataStores] = useState(
    JSON.parse(sessionStorage.getItem("billingFormDataStores")) || {}
  );

  const [dataOrder, setDataOrder] = useState(
    JSON.parse(sessionStorage.getItem("dataOrder")) || {}
  );

  const [selectedShipping, setSelectedShipping] = useState(
    JSON.parse(sessionStorage.getItem("selectedShipping")) || {}
  );

  const [paidFor, setPaidFor] = useState(
    JSON.parse(sessionStorage.getItem("paidFor")) || false
  );

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (window.location.pathname !== "/confirmation-de-paiement") {
        setPaidFor(false);
        sessionStorage.setItem("paidFor", false);
      }
    };
    handleBeforeUnload();
  }, []);

  // customer data

  useEffect(() => {
    const dataActiveUser = {
      DeliveryId: addressDeliveryID[0]?.idAddress,
      BillingId: addressBillingID[0]?.idAddress,
    };

    const dataGuess = {
      emailGuess: emailGuess,
      guessDeliveryData: [deliveryformData],
      guessBillingData: [billingFormData],
    };

    if (userStatue) {
      setDataOrder(dataActiveUser);
    } else {
      setDataOrder(dataGuess);
    }
  }, [
    addressBillingID,
    addressDeliveryID,
    billingFormData,
    deliveryformData,
    emailGuess,
    userStatue,
  ]);

  useEffect(() => {
    sessionStorage.setItem("dataOrder", JSON.stringify(dataOrder));
  }, [dataOrder]);

  const [comeFrom, setComeFrom] = useState(
    sessionStorage.getItem("comeFrom") || ""
  );

  // transfer cart

  const [transferCompleted, setTransferCompleted] = useState(
    JSON.parse(sessionStorage.getItem("transferCompleted")) || false
  );

  function CartTransfer(id) {
    if (!transferCompleted && id && cartItems) {
      const formatData = {
        idUser: id,
        cartItems: cartItems,
      };

      axios
        .post(
          "https://api.sweatteeshop.com/front/users/carts/transfer",
          formatData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          // console.log(response);
          setCartItems("");
          setTransferCompleted(true);
          sessionStorage.removeItem("cartItems");
          sessionStorage.setItem("transferCompleted", true);
        })
        .catch((error) => {
          alert(error.data.message);
        });
    }
  }

  const contextValue = {
    logo,
    logoVar,
    products,
    collections,
    facebook,
    instagram,
    tiktok,
    nameCompany,
    statusCompany,
    addressCompany,
    zipCompany,
    cityCompany,
    countryCompany,
    idCompany,
    capitalCompany,
    rcs,
    vat,
    publisherCompany,
    nameContact,
    emailContact,
    addressContact,
    zipContact,
    cityContact,
    countryContact,
    homeHero,
    homeTitle,
    homeSubTitle,
    webSide,
    tshirtImg,
    sweatImg,
    bagImg,
    newInImg,
    imgOpenTags,
    bande1,
    bande2,
    bande3,
    isDesplayBandeST,
    isDesplayBandeND,
    isDesplayBandeRD,
    loadingParam,
    imgHomeCover,
    handleLoginUser,
    setCurrentUser,
    userStatue,
    putOnCart,
    addToCart,
    subtractFromCart,
    removeFromCart,
    emptyCart,
    onSubmitEmptyCart,
    shareColor,
    shareSize,
    getCartItemCount,
    cartItemCount,
    cartItems,
    discount,
    validPromoCodes,
    selectedColor,
    selectedSize,
    codeName,
    rateDiscount,
    checkPromoCodeValidity,
    typeDiscount,
    setCodeName,
    emailGuess,
    setEmailGuess,
    deliveryformData,
    setDeliveryFormData,
    billingFormData,
    setBillingFormData,
    isSomeData,
    setIsSomeData,
    billingFormDataStores,
    setBillingFormDataStores,
    shipAmountUser,
    setLessOnCart,
    dataOrder,
    cartsProductsStored,
    subtotalGuessCart,
    wigthGuessCart,
    deliveryPriceGuess,
    shippingPriceGuess,
    totalCartGuess,
    handleLogoutUser,
    usersDatas,
    idUser,
    submiteOnCart,
    onSubmitaddToCart,
    onSubmitSubstractToCart,
    onSubmitremovToCart,
    popupMessage,
    isdisplaypfopup,
    selectedShipping,
    setSelectedShipping,
    paidFor,
    setPaidFor,
    thresholdAmount,
    comeFrom,
    setComeFrom,
    // cart user
    cartsProducts,
    totalQuantity,
    cartsProductsVersion,
    moreOnCart,
    lessOnCart,
    deleteOnCart,
    deleteCart,
    discountAmount,
    valueDiscountUser,
    typeDiscountUser,
    pricesProduct,
    codePromoMessage,
    totalPrice,
    shippingUser,
    code,
    setAddressDeliveryID,
    setAddressBillingID,
    addressDeliveryID,
    addressBillingID,
    CartTransfer,
    // admin Param
    handleLoginAdmin,
    handleLogoutAdmin,
    setCurrentAdmin,
    adminData,
    adminStatue,
    idAdmin,
  };

  return (
    <ParamContext.Provider value={contextValue}>
      {props.children}
    </ParamContext.Provider>
  );
};
