import React, { useContext, useEffect, useState } from "react";
import NavBarUserAccount from "../components/NavBarUserAccount";
import { useTranslation } from "react-i18next";
import { ParamContext } from "../contexts/ParamContexts";
import axios from "axios";
import BillsUserCard from "../components/BillsUserCard";

const BillsUser = ({ setIsLoading }) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { idUser } = useContext(ParamContext);

  const [Bills, setBills] = useState([]);
  const [resultsPage, setResultsPage] = useState(10);
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreData = () => {
    if (!hasMore) return;
    setResultsPage(resultsPage + 10);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const shareProducts = () => {
    setHasMore(Bills.length > 10);
  };

  const sliceData = Bills.slice(0, resultsPage);

  useEffect(() => {
    const getAddressData = async (url, setAddressFunction) => {
      try {
        const response = await axios.get(url);
        const infoObj = Object.values(response.data).sort(
          (b, a) => a.idBill - b.idBill
        );
        setAddressFunction(infoObj);
        setIsLoading(false);
        shareProducts();
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la récupération des données :",
          error
        );
        setIsLoading(false);
      }
    };

    getAddressData(
      `https://api.sweatteeshop.com/front/users/bills/${idUser}`,
      setBills
    );
  }, [idUser, setIsLoading, shareProducts]);

  let dataToDisplay;
  if (sliceData.length > 0) {
    dataToDisplay = sliceData.map((Bill) => (
      <BillsUserCard
        key={Bill.idBill}
        idBill={Bill.idBill}
        dateBill={Bill.dateBill}
        totalPrice={Bill.totalPrice}
        pathBill={Bill.pathBill}
        firstNameBill={Bill.firstNameBill}
        lastNameBill={Bill.lastNameBill}
        orderBill={Bill.orderBill}
      />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>{t("NoInvoice")}</h3>
      </div>
    );
  }

  return (
    <div className="user-account-page">
      <div className="left-side-user-account">
        <NavBarUserAccount />
      </div>
      <div className="rigth-user-account">
        <div className="header-user-account">
          <p>{t("MyBills")}</p>
        </div>
        <div className="body-user-account">
          <div className="address-user">
            {dataToDisplay}
            {hasMore && (
              <div className="loadMoreButtonContainer">
                <button className="loadMoreButton" onClick={fetchMoreData}>
                  Plus de commandes
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillsUser;
