import React, { useContext, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ParamContext } from "../contexts/ParamContexts";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const {
    facebook,
    instagram,
    tiktok,
    nameCompany,
    statusCompany,
    emailContact,
    nameContact,
    addressContact,
    zipContact,
    cityContact,
    countryContact,
  } = useContext(ParamContext);

  const currentYear = new Date().getFullYear();
  const location = useLocation();

  const browserLanguage = navigator.language.split("-")[0];

  const [activeLanguage, setActiveLanguage] = useState(
    JSON.parse(sessionStorage.getItem("activeLanguage")) || browserLanguage
  );

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
    sessionStorage.setItem("activeLanguage", JSON.stringify(lng));
  };

  const [isFocused, setIsFocused] = useState(false);
  const [toggleMessage, setToggleMessage] = useState("");

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  function handleSubmit(event) {
    event.preventDefault();

    const formData = new FormData(event.target);

    axios
      .post("https://api.sweatteeshop.com/front/users/subscription", formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          setToggleMessage(response.data.message);
          event.target.reset();
        } else {
          setToggleMessage(response.data.message);
        }
      })
      .catch((error) => {
        alert(error.data.message);
      });
  }

  return (
    !(
      location.pathname.startsWith("/back/") ||
      location.pathname === "/desinscription-newsletter" ||
      location.pathname === "/"
    ) && (
      <footer>
        <div className="newsletter-signup">
          <h2>{t("Newsletter")}</h2>
          <form className="form-newsletter" onSubmit={handleSubmit}>
            <div
              className={`input-container-news ${isFocused ? "focused" : ""}`}
            >
              <label htmlFor="email-news">E-mail</label>
              <input
                type="email"
                id="email-news"
                name="email"
                required
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
            <small className="alertSuccess">{toggleMessage}</small>
            <div className="input-Check-news">
              <input
                type="checkbox"
                id="conditionDutil"
                name="acceptTerms"
                required
              />
              <div className="label-news">
                <label htmlFor="conditionDutil">
                  {t("Agree")} <a href="/termes-et-conditions">{t("CGV")}</a>
                </label>
              </div>
            </div>
            <button className="submit-news">{t("SubscibeBTN")}</button>
          </form>
        </div>
        <div className="engagement-container">
          <div className="engagement">
            <div className="flag-container">
              <div className="blue"></div>
              <div className="withe"></div>
              <div className="red"></div>
            </div>
            <div className="text-container-eng">
              <span>{t("Made")}</span>
            </div>
          </div>
          <div className="engagement">
            <img src="../img/quality-icon.png" alt="quality-icon" />
            <div className="text-container-eng">
              <span>{t("Quality")}</span>
            </div>
          </div>
          <div className="engagement">
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 122.88 85.57"
            >
              <title>mail</title>
              <path d="M3.8,0,62.48,47.85,118.65,0ZM0,80.52,41.8,38.61,0,4.53v76ZM46.41,42.37,3.31,85.57h115.9L78,42.37,64.44,53.94h0a3,3,0,0,1-3.78.05L46.41,42.37Zm36.12-3.84,40.35,42.33V4.16L82.53,38.53Z" />
            </svg>
            <div className="text-container-eng">
              <span>{t("CustomerCare")}</span>
            </div>
          </div>
        </div>
        <div className="down-footer">
          <div className="footer-content">
            <div className="first-part-footer">
              <h3>Contacts</h3>
              <span>
                <a href={`mailto:${emailContact}`}>{emailContact}</a>
              </span>
              <div className="address-footer">
                <span>{nameContact}</span>
                <span>{addressContact}</span>
                <span>
                  {zipContact}, {cityContact} - {countryContact}
                </span>
              </div>
              <div className="social-link">
                <a href={facebook} target="_blank" rel="noreferrer">
                  <svg
                    className="icon-rs-footer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 640"
                  >
                    <path d="M380.001 120.001h99.993V0h-99.993c-77.186 0-139.986 62.8-139.986 139.986v60h-80.009V320h79.985v320h120.013V320h99.994l19.996-120.013h-119.99v-60.001c0-10.843 9.154-19.996 19.996-19.996v.012z" />
                  </svg>
                </a>
                <a href={instagram} target="_blank" rel="noreferrer">
                  <svg
                    className="icon-rs-footer"
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                    viewBox="0 0 640 640"
                  >
                    <path d="M184.715 2.752h270.582C556.908 2.752 640 84.816 640 185.069v269.85c0 100.277-83.092 182.317-184.703 182.317H184.715C83.104 637.236 0 555.196 0 454.919v-269.85C0 84.816 83.103 2.752 184.715 2.752zm133.561 145.939c98.741 0 178.868 80.127 178.868 178.868 0 98.753-80.127 178.868-178.868 178.868-98.765 0-178.88-80.115-178.88-178.868 0-98.741 80.115-178.868 178.88-178.868zm0 60.414c65.387 0 118.454 53.056 118.454 118.454s-53.068 118.466-118.454 118.466c-65.41 0-118.466-53.067-118.466-118.466 0-65.398 53.056-118.454 118.466-118.454zM491.321 123.9c16.04 0 29.044 13.004 29.044 29.032 0 16.04-13.004 29.044-29.044 29.044-16.028 0-29.032-13.004-29.032-29.044 0-16.028 13.004-29.032 29.032-29.032zM206.825 54.58H433.21c85.005 0 154.526 69.178 154.526 153.712V435.81c0 84.532-69.52 153.699-154.526 153.699H206.825c-85.005 0-154.537-69.167-154.537-153.7V208.29c0-84.532 69.532-153.71 154.537-153.71z" />
                  </svg>
                </a>
                <a href={tiktok} target="_blank" rel="noreferrer">
                  <svg
                    className="icon-rs-footer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 2859 3333"
                  >
                    <path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="sec-part-footer">
              <ul>
                <h3>SWEATTEESHOP</h3>
                <li>
                  <NavLink to="/guide">{t("sizeGuide")}</NavLink>
                </li>
                <li>
                  <NavLink to="/echanges-et-retours">
                    {t("ChangesAndReturns")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/livraisons">{t("Delivery")}</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/a-propos">{t("AboutUs")}</NavLink>
                </li> */}
              </ul>
            </div>
            <div className="third-part-footer">
              <ul>
                <h3>{t("Company")}</h3>
                <li>
                  <NavLink to="/protection-des-données">
                    {t("PrivacyCookies")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/termes-et-conditions">{t("CGV")}</NavLink>
                </li>
                <li>
                  <NavLink to="/mentions-legales">{t("LegalNotice")}</NavLink>
                </li>
                <li>
                  <header className="languages-container">
                    <span
                      className={activeLanguage === "fr" ? "active" : ""}
                      onClick={() => changeLanguage("fr")}
                    >
                      FR
                    </span>
                    <p>/</p>
                    <span
                      className={activeLanguage === "en" ? "active" : ""}
                      onClick={() => changeLanguage("en")}
                    >
                      EN
                    </span>
                  </header>
                </li>
              </ul>
            </div>
          </div>
          <small>
            Copyright © {nameCompany} {statusCompany} {currentYear} -{" "}
            {t("Copyright")}
          </small>
        </div>
      </footer>
    )
  );
};

export default Footer;
