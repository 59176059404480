import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          News: "NEW IN",
          Products: "PRODUCTS",
          Product: "Product",
          Accessories: "ACCESSORIES",
          LegalNotice: "Legals informations",
          PrivacyCookies: "Privacy policy & cookies",
          CGV: "Terms and conditions",
          sizeGuide: "Size guide and wash care",
          ChangesAndReturns: "Changes and returns",
          Delivery: "Delivery times",
          AboutUs: "About us",
          Copyright: "all rights reserved",
          Exit: "Back",
          Visit: "Visit",
          OurProduct: "OUR PRODUCTS",
          Newsletter: "JOIN OUR NEWSLETTER",
          SubscibeBTN: "Subscibe",
          Agree: "Accept",
          Made: "Made in france",
          CustomerCare: "Customer care",
          Quality: "Premium quality",
          NoProduct: "No products available",
          OurNewIn: "Discover our latest products",
          seeMore: "More Products",
          filter: "Filter",
          removeFilter: "Remove all",
          sorteBy: "sorte by",
          IncreasingPrices: "Increasing prices",
          decreasingPrices: "Decreasing prices",
          colors: "Colors",
          color: "Color",
          sizes: "Sizes",
          size: "Size",
          QTY: "Quantity",
          Close: "Close",
          OurTshirts: "Original, artistic T-shirts",
          OurSweat: "Stylish and comfortable sweatshirts",
          OurAccessories: "Elegant and practical accessories",
          Connection: "Login",
          Password: "Password",
          PasswordForgot: "Password forgotten",
          Singin: "Sign in",
          Logout: "Log out",
          CreateAccount: "Create account",
          resetPassMess: "We will send you an email to reset your password.",
          submit: "Submit",
          Cancel: "Cancel",
          AvailableLink: "Link expired, please try again",
          PasswordReset: "Password reset",
          FirstName: "First name",
          LastName: "Last name",
          newCheckbox: "I wish to receive the newsletter",
          TryAgain: "A problem occurred during operation, please try again !",
          ActiveEmail:
            "Your e-mail address has been confirmed. Your account has been activated.",
          SizeHelp: "Size Help",
          Add: "Add to cart",
          ProductDetails: "Product details",
          DeliveryReturns: "Delivery & returns",
          chest: "chest",
          waist: "waist",
          hip: "hip",
          shipMessage: "DELIVERY - 4-6 working days.",
          ProductOutOfStock: "Product out of stock",
          PreventMe: "Prevent me",
          selectSizeColor: "Please select a size and a color.",
          selectSize: "Please select a size.",
          selectColor: "Please select a color.",
          EmptyCart: "Empty the cart",
          SubTotal: "Sub-total",
          Shipping: "Shipping",
          Free: "Free",
          CodeStored: "Registered promo code",
          Remove: "Remove",
          Apply: "Apply",
          Discount: "Discount",
          checkout: "Validate my basket",
          CartIsEmpty: "Your cart is empty",
          InvalidPromoCode: "Invalid or expired promo code.",
          CodePromo: "Promo Code",
          item: "Item",
          YourCart: "Your Shopping Bag",
          Gess: "Gess",
          ContinueAsAguest: "Continue as a guest",
          orderSumary: "Your order summary",
          Phone: "Phone",
          Address: "Address",
          Line: "Line",
          City: "City",
          Zip: "Zip",
          missing: "missing",
          invalid: "invalid",
          Next: "Next",
          shippingInfo: "Shipping information",
          billingInfo: "Billing information",
          previous: "previous",
          Payment: "Payment",
          securePay: "Secure checkout",
          contactEmail: "Contact email",
          change: "Change",
          sameAddress: "Use the same billing address",
          orderRegistered: "Your order has been registered.",
          orderNumber: "Your order number",
          confirmationSend: "A confirmation has just been emailed to you.",
          backToHomePage: "Back to home page",
          ThankYou: "Thank You",
          PayNow: "Pay now",
          PaymentSucceeded: "Payment succeeded!",
          PaymentProcessing: "Your payment is processing.",
          payNotSuccess: "Your payment was not successful, please try again.",
          somethingWrong: "Something went wrong.",
          CalculatedAtNextStep: "Calculated at next step",
          Addressbook: "Address book",
          MyOrders: "My Orders",
          Settings: "Settings",
          MyAccount: "My Account",
          Hello: "Hello",
          EditingAccountInformation: "Editing account information",
          save: "Save",
          indiqueModif: "Please indicate new modifications",
          dontUsSpecialCaracteres:
            "First and last names may not contain special characters",
          NoAddress: "No address",
          NoOrders: "No order",
          NoInvoice: "No invoice",
          old: "Old",
          new: "New",
          deleteAccount: "delete account",
          OrderNumber: "Order number",
          OrderDate: "Order date",
          TotalAmount: "Total Amount",
          Status: "Status",
          SeeOrderDetails: "See order details",
          DeliveryAddress: "Delivery address",
          BillingAddress: "Billing address",
          NameProduct: "Name Product",
          Price: "Price",
          AddAnAddress: "Add an address",
          DefaultDeliveryAddress: "Default delivery address",
          DefaultBillingAddress: "Default billing address",
          AdditionalAddresses: "Additional addresses",
          Delete: "Delete",
          Edite: "Edite",
          CreatingANewAddress: "Creating a new address",
          UseAsDefaultBillingAddress: "Use as default billing address",
          UseAsDefaultDeliveryAddress: "Use as default delivery address",
          AddressModification: "Address modification",
          MoreAdrress: "More addresses",
          understand: "Understand",
          Company: "The Company",
          GuideSizeText1:
            "To determine the right size for you, please take your measurements by following the instructions below:",
          ChestSize: "Chest size",
          Length: "Length",
          GuideSizeText2:
            "Measure horizontally around the strongest part of your chest, under your arms and over your shoulder blades.",
          GuideSizeText3:
            "Measure from the top of the shoulder to the waist for T-shirts and sweatshirt.",
          GuideSizeText4:
            "The sizes indicated in this guide are European sizes.",
          WashingCare: "Washing care",
          GuideSizeText5:
            "Our products are made from top-quality materials, but special care is taken to ensure a long service life.",
          GuideSizeText6:
            "Use a cold wash or a maximum water temperature of 30°C. Wash and iron inside out. Do not tumble dry or wash with bleach.",
          ChangeAndReturnText1:
            "We give you 30 days to return your order. As long as your garment is not worn, washed and in good conditions.",
          howToPrecessReturn: "HOW TO PROCEED FOR A RETURN ?",
          ChangeAndReturnText2:
            "If the product arrived damaged or defective, we will return the product to you free of charge and cover shipping costs after examining it, and concludes has a SWAETTEESHOP.COM error. You must notify us of any problem within 48 hours of receiving your package. please contact us at the following address: ",
          ChangeAndReturnText3: "DEFECTIVE, DAMAGED OR NON-CONFORMING PRODUCTS",
          Withdrawal: "WITHDRAWAL",
          ChangeAndReturnTex4:
            "you have a cooling-off period of 14 days from the date of receipt of your to inform us of your decision to withdraw from your purchase. your purchase. Once we have received your retraction request instructions for returning your product. product. Please note that only undamaged products that are suitable for resale can be returned. To exercise your right of withdrawal, please contact us at the following address:",
          addInfoReturn: "Additional information on returns:",
          prepareYourReturn: "PREPARATION OF YOUR RETURN PACKAGE",
          ChangeAndReturnTex5:
            "Choose a standard Post Office box, or re-use the box you received the products in.",
          ChangeAndReturnTex6:
            "Place the products you wish to return inside the parcel in their original packaging.",
          ChangeAndReturnTex7:
            "Check that the information on the returns form you received by e-mail is correct, and place it in the parcel.",
          ChangeAndReturnTex8:
            "Close your parcel carefully to avoid any opening during transport.",
          ChangeAndReturnTex9:
            "Attach the pre-paid returns label to the parcel on a clearly visible side.",
          ChangeAndReturnTex10:
            "Drop off your return parcel at a post office or post office drop-off point, depending on your returns slip.",
          PROCESSINGTIMES: "PROCESSING TIMES",
          ChangeAndReturnTex11:
            "Returns are processed within 5 to 7 days of receipt. In accordance with our general conditions of sale, only items returned in their original packaging will be accepted. Consequently, returns of damaged, washed and/or worn products will be refused (except in the case of exceptional appreciation by SWEATTEESHOP.COM, e.g. in the event of a proven product defect).",
          ANYRETURNSHIPPINGCOSTS: "ANY RETURN SHIPPING COSTS",
          ChangeAndReturnTex12:
            "Depending on the reason for return, a prepaid label fee of 5.99 euros (incl. VAT) may be deducted from your refund.",
          ChangeAndReturnTex13:
            "In the case of a return due to an error on the part of SWEATTEESHOP.COM, the cost of return will be borne entirely by SWEATTEESHOP.COM.",
          DeliveryMode: "Delivery mode",
          AtHome: "At home",
          FreeDelivery: "Free delivery",
          freeDeliveryPrice: "Free delivery in mainland France from ",
          ColissimoDelivery: "48H DELIVERY - COLISSIMO DOMICILE (LA POSTE)",
          DeliveryText1:
            "Contactless parcel delivered directly to your home. If you are absent, a delivery notice will be left in your letterbox, allowing you to collect the parcel from your nearest post office.",
          Cost: "Costs",
          WeightRange: "Weight range",
          ColissimoHomeDelivery: "Colissimo home delivery",
          Beyond: "Beyond",
          MyBills: "My Bills",
          back: "Back",
        },
      },
      fr: {
        translation: {
          News: "NOUVEAUTÉS",
          Products: "PRODUITS",
          Product: "Produit",
          Accessories: "ACCESSOIRES",
          LegalNotice: "Mentions legales",
          PrivacyCookies: "Protection des données & cookies",
          CGV: "Termes et conditions",
          sizeGuide: "Guide des tailles et entretien",
          ChangesAndReturns: "Echanges et retours",
          Delivery: "Temps de livraison",
          AboutUs: "A propos",
          Copyright: "tous droits réservés",
          Exit: "Retour",
          Visit: "Explorer",
          OurProduct: "NOS PRODUITS",
          Newsletter: "REJOIGNEZ NOTRE NEWSLETTER",
          SubscibeBTN: "S'inscrire",
          Agree: "J'accepte les",
          Made: "Fabriqué en France",
          CustomerCare: "Service client",
          Quality: "Qualité premium",
          NoProduct: "Aucun produit disponible",
          OurNewIn: "Decouvrez nos nouveautés",
          seeMore: "Voir plus",
          filter: "Filtre",
          removeFilter: "Retirer les filtres",
          sorteBy: "Trier par",
          IncreasingPrices: "Prix croisants",
          decreasingPrices: "Prix décroisants",
          colors: "Couleurs",
          color: "Couleur",
          sizes: "Tailles",
          size: "Taille",
          QTY: "Quantité",
          Close: "Fermer",
          OurTshirts: "T-shirts artistiques et originaux",
          OurSweat: "Sweat-shirts design et confortables",
          OurAccessories: "Nos accessoires élégants et pratiques",
          Connection: "Connexion",
          Password: "Mot de passe",
          PasswordForgot: "Mot de passe oublié",
          Singin: "Se connecter",
          CreateAccount: "Creer un compte",
          resetPassMess:
            "Nous vous enverrons un e-mail pour réinitialiser votre mot de passe.",
          submit: "Envoyer",
          Cancel: "Annuler",
          AvailableLink: "Lien expiré, veuillez recommencer l'operation",
          PasswordReset: "Réinitialisation de mot de passe",
          FirstName: "Prenom",
          LastName: "Nom",
          newCheckbox: "Je souhaite recevoir la newsletter",
          TryAgain:
            "Un problème est survenu lors de l'opération, veuillez réessayer !",
          ActiveEmail:
            " Votre adresse e-mail a bien été confirmée. Votre compte a été activé.",
          SizeHelp: "Aide à la taille",
          Add: "Ajouter au panier",
          ProductDetails: "Details du produit",
          DeliveryReturns: "Livraison & retour",
          chest: "Poitrine",
          waist: "Tour de taille",
          hip: "Hanche",
          shipMessage: "LIVRAISON - Délai de 3-6 jours ouvrables.",
          ProductOutOfStock: "Produit en rupture",
          PreventMe: "Me prevenir",
          selectSizeColor: "Veuillez sélectionner une taille et une couleur.",
          selectSize: "Veuillez sélectionner une taille.",
          selectColor: "Veuillez sélectionner une couleur.",
          EmptyCart: "Vider le panier",
          SubTotal: "Sous-total",
          Shipping: "Livraison",
          Free: "Gratuit",
          CodeStored: "Code promo enregistré",
          Remove: "Retirer",
          Apply: "Appliquer",
          Discount: "Remise",
          checkout: "Valider mon panier",
          CartIsEmpty: "Votre panier est vide",
          InvalidPromoCode: "Code promo invalide ou expiré.",
          CodePromo: "Code Promo",
          item: "Article",
          YourCart: "Votre panier",
          Gess: "Invité",
          ContinueAsAguest: "Continuer en tant qu'invité",
          orderSumary: "Récapitulatif de votre commande",
          Phone: "Téléphone",
          Address: "Adresse",
          Line: "Ligne",
          City: "Ville",
          Zip: "Code postal",
          addAddress: "Ajouter cette adresse",
          missing: "manquant",
          invalid: "invalide",
          Next: "Suivant",
          shippingInfo: "Informations de livraison",
          billingInfo: "Informations de facturation",
          previous: "Précédent",
          Payment: "Paiement",
          securePay: "Paiement securisé",
          contactEmail: "Email de contact",
          change: "changer",
          sameAddress: "Utiliser la même adresse pour la facturation",
          orderRegistered: "Votre commande a été enregistrée.",
          orderNumber: "Numéro de votre commande",
          confirmationSend:
            "Une confirmation vient de vous etre envoyé par email.",
          backToHomePage: "Retour a l'acceuil",
          ThankYou: "Merci",
          PayNow: "Payer maintenant",
          PaymentSucceeded: "Le paiement a été effectué !",
          PaymentProcessing: "Votre paiement est en cours.",
          payNotSuccess: "Votre paiement n'a pas abouti, veuillez réessayer.",
          somethingWrong: "Une erreur est survenu",
          CalculatedAtNextStep: "Calculé à l'étape suivante",
          Addressbook: "Adresses enregistrées",
          MyOrders: "Mes commandes",
          Settings: "Parametres",
          MyAccount: "Mon compte",
          Hello: "Bonjour",
          Logout: "Déconnexion",
          EditingAccountInformation: "Modification des informations du compte",
          save: "Enregistrer",
          indiqueModif: "Veuillez indiquez de nouvelles modifications",
          dontUsSpecialCaracteres:
            "Le nom et le prénom ne peuvent pas contenir de caractères spéciaux",
          NoAddress: "Aucune adresse",
          NoOrders: "Aucune commande",
          NoInvoice: "Aucune facture",
          old: "Ancien",
          new: "Nouveau",
          deleteAccount: "Supprimer le compte",
          OrderNumber: "Numéro de commande",
          OrderDate: "Date de commande",
          TotalAmount: "Total amount",
          Status: "Statut",
          SeeOrderDetails: "Voir les détails de la commande",
          DeliveryAddress: "Adresse de livraison",
          BillingAddress: "Adresse de facturation",
          NameProduct: "Nom du produit",
          Price: "Prix",
          AddAnAddress: "Ajouter une adresse",
          DefaultDeliveryAddress: "Livraison par default",
          DefaultBillingAddress: "Facturation par default",
          AdditionalAddresses: "Adresses additionnelles",
          Delete: "Supprimer",
          Edite: "Modifier",
          CreatingANewAddress: "Creer une nouvelle adresse",
          UseAsDefaultBillingAddress:
            "Utiliser comme adresse de facturation par défaut",
          UseAsDefaultDeliveryAddress:
            "Utiliser comme adresse de livraison par défaut",
          AddressModification: "Modification d'Address",
          MoreAdrress: "Afficher plus d'adresses",
          understand: "J'ai compris",
          Company: "L'ENTREPRISE",
          GuideSizeText1:
            "Pour déterminer la taille qui vous convient, veuillez prendre vos mesures en suivant les instructions ci-dessous :",
          ChestSize: "Tour de poitrine",
          Length: "Longueur",
          GuideSizeText2:
            "Mesurez horizontalement autour de la partie la plus forte de votre poitrine, sous les bras et sur les omoplates.",
          GuideSizeText3:
            "Mesurez depuis le haut de l'épaule jusqu'à la taille pour les T-shirts et les sweat-shirt.",
          GuideSizeText4:
            "Les tailles indiquées dans ce guide sont des tailles européennes.",
          WashingCare: "Entretien lavage",
          GuideSizeText5:
            "Nos produits sont fabriqués à partir de matériaux de la qualité, mais un soin particulier contribuera à garantir une longue durée de vie.",
          GuideSizeText6:
            "Laver à froid ou à une température maximale de 30°C. Laver et repasser à l'envers. Ne pas sécher au sèche-linge ni laver avec de l'eau de Javel.",
          ChangeAndReturnText1:
            "Nous vous donnons 30 jours pour retourner votre commande. Pour autant que votre vêtement ne soit pas porté, lavé et en bon état.",
          howToPrecessReturn: "COMMENT EFFECTUER UN RETOUR ?",
          ChangeAndReturnText2:
            "Si le produit est arrivé endommagé ou défectueux, nous vous renverrons gratuitement le produit et couvrirons les frais d'expédition après avoir examiné celui-ci, et conclue a une erreur de SWAETTEESHOP.COM. Vous devez nous signaler tout problème dans les 48 heures suivant la réception de votre colis. veuillez nous contacter à l'adresse suivante : ",
          ChangeAndReturnText3:
            "PRODUITS DEFECTUEUX, ENDOMAGÉS OU NON CONFORMES",
          Withdrawal: "RETRACTATION",
          ChangeAndReturnTex4:
            "vous disposez d'un délai de rétractation de 14 jours à compter de la réception de votre commande pour nous informer de votre décision de vous rétracter de votre achat. Une fois votre demande de rétractation reçue, nous vous fournirons les instructions pour le retour de votre produit. Il convient de précisez que seul les produit non endommagés et propre a la revente peuvent faire l'objet d'une retractation. Pour exercer votre droit de rétractation, veuillez nous contacter à l'adresse suivante :",
          addInfoReturn: "Informations complémentaires sur les retours :",
          prepareYourReturn: "PRÉPARATION DE VOTRE COLIS RETOUR",
          ChangeAndReturnTex5:
            "Choisissez un carton normalisé de la Poste, ou réutilisez le carton avec lequel les produits vous sont parvenus.",
          ChangeAndReturnTex6:
            "Placez les produits que vous souhaitez retourner à l'intérieur du colis dans leur emballage d'origine.",
          ChangeAndReturnTex7:
            "Vérifiez l'exactitude des informations renseignées sur le bon de retour que vous aurez reçu par email, et placez celui-ci dans le colis.",
          ChangeAndReturnTex8:
            "Refermez votre colis soigneusement pour éviter toute ouverture pendant le transport.",
          ChangeAndReturnTex9:
            "Collez l'étiquette de retour prépayée fournie sur le colis sur une face bien visible.",
          ChangeAndReturnTex10:
            "Rendez vous en point relais ou dans un bureau de poste en fonction de votre bon retour pour déposer votre colis retour.",
          PROCESSINGTIMES: "DÉLAIS DE TRAITEMENT",
          ChangeAndReturnTex11:
            "Les retours sont traités dans les 5 à 7 jours suivant la réception. Conformément à nos conditions générales de vente, seuls les articles retournés dans leur emballage d'origine seront acceptés. En conséquence, les retours de produits abîmés, lavés et/ou portés seront refusés (sauf appréciation exceptionnelle de SWEATTEESHOP.COM, par exemple en cas de défaut avéré du produit).",
          ANYRETURNSHIPPINGCOSTS: "FRAIS ÉVENTUELS DE RETOUR",
          ChangeAndReturnTex12:
            "Selon le motif de retour, un montant forfaitaire de 5,99 euros TTC pour l'étiquette prépayée pourra être déduit de votre remboursement",
          ChangeAndReturnTex13:
            "Dans le cas d'un retour conséquent à une erreur de SWEATTEESHOP.COM, les frais de retour seront intégralement à la charge de SWEATTEESHOP.COM",
          DeliveryMode: "Mode de livraison",
          AtHome: "A domicile",
          FreeDelivery: "Livraison gratuite",
          freeDeliveryPrice:
            "Livraison gratuite en France Métropolitaine a partir de",
          ColissimoDelivery: "LIVRAISON 48H - COLISSIMO DOMICILE (LA POSTE)",
          DeliveryText1:
            "Colis remis sans contact directement à votre domicile, en cas d'absence un avis de passage vous sera déposé en boite aux lettres, afin de retirer le colis dans le bureau de Poste le plus proche de chez vous.",
          Cost: "Tarifs",
          WeightRange: "Tranche de poids",
          ColissimoHomeDelivery: "Livraison colissimo domicile",
          Beyond: "Au délà de",
          MyBills: "Mes factures",
          back: "Retour",
        },
      },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
