import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

const FilterBarBack = ({ onShare }) => {
  const [toggleSortBackProduct, setToggleSortBackProduct] = useState(false);
  const [toggleFilterBackProduct, setToggleFilterBackProduct] = useState(false);
  const [toggleArrowDate, setToggleArrowDate] = useState(false);
  const [toggleArrowPrice, setToggleArrowPrice] = useState(false);
  const [DateGrowing, setDateGrowing] = useState(false);
  const [PriceGrowing, setPriceGrowing] = useState(false);
  const [DateOrder, setDateOrder] = useState("descending");
  const [PriceOrder, setPriceOrder] = useState("descending");
  const [SortTerm, setSortTerm] = useState("");
  const [SearchTerm, setSearchTerm] = useState("");
  const [orders, setOrders] = useState([]);

  const myRef = useRef();
  const myRefFilter = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!myRef.current.contains(e.target)) {
        setToggleSortBackProduct(false);
      }
      if (!myRefFilter.current.contains(e.target)) {
        setToggleFilterBackProduct(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [toggleFilterBackProduct]);

  const toggleDateFunc = () => {
    setDateGrowing(!DateGrowing);
    setToggleArrowDate(true);
    setToggleArrowPrice(false);
    handleSortChangeDate();
  };

  const togglePriceFunc = () => {
    setPriceGrowing(!PriceGrowing);
    setToggleArrowDate(false);
    setToggleArrowPrice(true);
    handleSortChangePrice();
  };

  const [toggleWaiting, setToggleWaiting] = useState(false);
  const [toggleProcess, setToggleProcess] = useState(false);
  const [toggleDelivered, setToggleDelivered] = useState(false);
  const [toggleCanceled, setToggleCanceled] = useState(false);

  const toggleWaitingFunc = () => {
    setToggleWaiting(true);
    setToggleProcess(false);
    setToggleDelivered(false);
    setToggleCanceled(false);
    setSortTerm("En attente");
  };

  const toggleInProcessFunc = () => {
    setToggleWaiting(false);
    setToggleProcess(true);
    setToggleDelivered(false);
    setToggleCanceled(false);
    setSortTerm("En traitement");
  };

  const toggleDeliveredFunc = () => {
    setToggleWaiting(false);
    setToggleProcess(false);
    setToggleDelivered(true);
    setToggleCanceled(false);
    setSortTerm("Livrée");
  };

  const toggleCanceledFunc = () => {
    setToggleWaiting(false);
    setToggleProcess(false);
    setToggleDelivered(false);
    setToggleCanceled(true);
    setSortTerm("Annulée");
  };

  const resetSort = () => {
    setToggleWaiting(null);
    setToggleProcess(null);
    setToggleDelivered(null);
    setToggleCanceled(null);
    setSortTerm("");

    window.location.reload();
  };

  const handleSortChangeDate = () => {
    const newSortOrder = DateOrder === "ascending" ? "descending" : "ascending";
    setDateOrder(newSortOrder);
    const sortedOrders = [...orders].sort((a, b) => {
      if (newSortOrder === "ascending") {
        return a.dateOrder > b.dateOrder ? 1 : -1;
      } else {
        return a.dateOrder < b.dateOrder ? 1 : -1;
      }
    });
    setOrders(sortedOrders);
  };

  const handleSortChangePrice = () => {
    const newSortOrder =
      PriceOrder === "ascending" ? "descending" : "ascending";
    setPriceOrder(newSortOrder);

    const sortedOrders = [...orders].sort((a, b) => {
      return newSortOrder === "ascending"
        ? a.totalPrice - b.totalPrice
        : b.totalPrice - a.totalPrice;
    });

    setOrders(sortedOrders);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [ordersRes] = await Promise.all([
          axios.get("https://api.sweatteeshop.com/back/orders/datas"),
        ]);

        const ordersObj = Object.values(ordersRes.data).sort(
          (a, b) => new Date(b.NumberOrder) - new Date(a.NumberOrder)
        );

        let filteredOrders = ordersObj;
        if (SortTerm) {
          filteredOrders = ordersObj.filter(
            (order) => order.orderStatus === SortTerm
          );
        }

        if (SearchTerm) {
          filteredOrders = filteredOrders?.filter(
            (order) =>
              order?.orderStatus
                ?.toLowerCase()
                .includes(SearchTerm.toLowerCase()) ||
              order?.totalPrice
                ?.toString()
                .includes(SearchTerm.toLowerCase()) ||
              order?.NumberOrder?.toString().includes(
                SearchTerm.toLowerCase()
              ) ||
              order?.promoCode?.toLowerCase().includes(SearchTerm.toLowerCase())
          );
        }

        setOrders(filteredOrders);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [
    toggleWaiting,
    toggleProcess,
    toggleDelivered,
    toggleCanceled,
    SortTerm,
    SearchTerm,
  ]);

  useEffect(() => {
    onShare(orders);
  }, [onShare, orders]);

  return (
    <div className="filter-bar-back-container">
      <div ref={myRefFilter} className="filterOrderBackContainer">
        <div
          onClick={() => setToggleFilterBackProduct(!toggleFilterBackProduct)}
        >
          FILTRER
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 266.77">
            <path d="M493.12 3.22c4.3-4.27 11.3-4.3 15.62-.04a10.85 10.85 0 0 1 .05 15.46L263.83 263.55c-4.3 4.28-11.3 4.3-15.63.05L3.21 18.64a10.85 10.85 0 0 1 .05-15.46c4.32-4.26 11.32-4.23 15.62.04L255.99 240.3 493.12 3.22z" />
          </svg>
        </div>
        <div
          className={`SubMenuFilterBack ${
            toggleFilterBackProduct ? "active" : "inactive"
          }`}
        >
          <ul>
            <li onClick={() => toggleWaitingFunc()}>
              En attente{" "}
              {toggleWaiting && (
                <svg
                  className="arrowIconSortOrder"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 457.57"
                >
                  <path d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z" />
                </svg>
              )}
            </li>
            <li onClick={() => toggleInProcessFunc()}>
              En traitement{" "}
              {toggleProcess && (
                <svg
                  className="arrowIconSortOrder"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 457.57"
                >
                  <path d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z" />
                </svg>
              )}
            </li>
            <li onClick={() => toggleDeliveredFunc()}>
              Livrée{" "}
              {toggleDelivered && (
                <svg
                  className="arrowIconSortOrder"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 457.57"
                >
                  <path d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z" />
                </svg>
              )}
            </li>
            <li onClick={() => toggleCanceledFunc()}>
              Annulée{" "}
              {toggleCanceled && (
                <svg
                  className="arrowIconSortOrder"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 457.57"
                >
                  <path d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z" />
                </svg>
              )}
            </li>
          </ul>
        </div>
      </div>
      <div ref={myRef} className="sortOrderBackContainer">
        <div onClick={() => setToggleSortBackProduct(!toggleSortBackProduct)}>
          TRIER PAR
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 266.77">
            <path d="M493.12 3.22c4.3-4.27 11.3-4.3 15.62-.04a10.85 10.85 0 0 1 .05 15.46L263.83 263.55c-4.3 4.28-11.3 4.3-15.63.05L3.21 18.64a10.85 10.85 0 0 1 .05-15.46c4.32-4.26 11.32-4.23 15.62.04L255.99 240.3 493.12 3.22z" />
          </svg>
        </div>
        <div
          className={`SubMenuFilterBack ${
            toggleSortBackProduct ? "active" : "inactive"
          }`}
        >
          <ul>
            <li onClick={() => toggleDateFunc()}>
              Dates{" "}
              {toggleArrowDate && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 327 512.23"
                  className={`arrowSortProduct ${DateGrowing ? "growing" : ""}`}
                >
                  <path
                    fillRule="nonzero"
                    d="M301.64 204.53c10.06-.51 17.22-3.9 21.37-10.2 10.52-15.76-1.96-31.28-12.06-42.65L183.12 9.5c-11.46-12.67-27.78-12.67-39.23 0L18.97 148.45c-10.7 11.9-26.4 28.79-15 45.88 4.18 6.3 11.34 9.69 21.39 10.2h76.34v301.36c0 3.49 2.86 6.34 6.34 6.34h110.93c3.47 0 6.33-2.86 6.33-6.34V204.53h76.34z"
                  />
                </svg>
              )}
            </li>
            <li onClick={() => togglePriceFunc()}>
              Montant{" "}
              {toggleArrowPrice && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 327 512.23"
                  className={`arrowSortProduct ${
                    PriceGrowing ? "growing" : ""
                  }`}
                >
                  <path
                    fillRule="nonzero"
                    d="M301.64 204.53c10.06-.51 17.22-3.9 21.37-10.2 10.52-15.76-1.96-31.28-12.06-42.65L183.12 9.5c-11.46-12.67-27.78-12.67-39.23 0L18.97 148.45c-10.7 11.9-26.4 28.79-15 45.88 4.18 6.3 11.34 9.69 21.39 10.2h76.34v301.36c0 3.49 2.86 6.34 6.34 6.34h110.93c3.47 0 6.33-2.86 6.33-6.34V204.53h76.34z"
                  />
                </svg>
              )}
            </li>
          </ul>
        </div>
      </div>
      {(toggleArrowDate ||
        toggleArrowPrice ||
        toggleWaiting ||
        toggleProcess ||
        toggleDelivered ||
        toggleCanceled) && (
        <div className="cancelFilter">
          <span
            className="btnCancelFilter"
            onClick={() => {
              resetSort();
            }}
          >
            Retirer les filtres
          </span>
        </div>
      )}
      <div className="searech-bar">
        <input
          type="search"
          placeholder="Rechercher N°, status, code..."
          value={SearchTerm}
          onChange={handleSearchChange}
        />
      </div>
    </div>
  );
};

export default FilterBarBack;
