import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ParamContext } from "../contexts/ParamContexts";
import axios from "axios";

const ContentCartCheckout = ({ setIsLoading }) => {
  const {
    cartItems,
    getCartItemCount,
    userStatue,
    cartsProducts,
    totalQuantity,
    shippingUser,
    discount,
    totalPrice,
    discountAmount,
    pricesProduct,
    checkPromoCodeValidity,
    codePromoMessage,
    subtotalGuessCart,
    deliveryPriceGuess,
    totalCartGuess,
  } = useContext(ParamContext);

  const { t } = useTranslation();

  const baseUrl = "https://api.sweatteeshop.com/public/images/";

  const itemCount = getCartItemCount();

  const [products, setProducts] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [promoCode, setPromoCode] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productsResponse = await axios.get(
          "https://api.sweatteeshop.com/front/products"
        );
        const colorsResponse = await axios.get(
          "https://api.sweatteeshop.com/front/colors"
        );
        const sizesResponse = await axios.get(
          "https://api.sweatteeshop.com/front/sizes"
        );

        setProducts(Object.values(productsResponse.data));
        setColors(Object.values(colorsResponse.data));
        setSizes(Object.values(sizesResponse.data));
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [setIsLoading]);

  const handlePromoCodeChange = (event) => {
    setPromoCode(event.target.value);
  };

  const onSubmitCode = (event) => {
    event.preventDefault();
    checkPromoCodeValidity(promoCode);
  };

  return (
    <div className="centent-cart-checkout">
      <div className="upside-sumary-checkout">
        <p>
          {t("orderSumary")} ({userStatue ? totalQuantity : itemCount}{" "}
          {t("item")}
          (s))
        </p>
        {userStatue
          ? cartsProducts.map((product) => {
              return (
                <div className="card-checkout-container" key={product.idCart}>
                  <div className="img-container-card">
                    <img src={baseUrl + product?.imagesProduct} alt="product" />
                  </div>
                  <div className="card-checkout">
                    <div className="card-info-checkout">
                      <p>{product.nameProduct}</p>
                      <span>{product.price} &euro;</span>
                    </div>
                    <div className="cartItemInfosCartCartPage">
                      <span>
                        {" "}
                        {t("color")}:{" "}
                        {
                          colors.find(
                            (color) => color.idColor === product.idColor
                          )?.nameColor
                        }
                      </span>
                      <span>
                        {" "}
                        {t("size")}:{" "}
                        {
                          sizes.find((size) => size.idSize === product.idSize)
                            ?.nameSize
                        }
                      </span>
                      <span>
                        {t("QTY")}: {product.quantity}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          : Object.entries(cartItems).map(([uniqueKey, cartItem]) => {
              const product = products.find(
                (p) => p.idProduct === cartItem.idProduct
              );
              if (product && cartItem.quantity > 0) {
                return (
                  <div className="card-checkout-container" key={uniqueKey}>
                    <div className="img-container-card">
                      <img
                        src={baseUrl + product?.mediaProduct[0].nameMedia}
                        alt="product"
                      />
                    </div>
                    <div className="card-checkout">
                      <div className="card-info-checkout">
                        <p>{product.nameProduct}</p>
                        <span>{product.PriceProduct} &euro;</span>
                      </div>
                      <div className="cartItemInfosCartCartPage">
                        <span>
                          {" "}
                          {t("color")}:{" "}
                          {
                            colors.find(
                              (color) => color.idColor === cartItem.idColor
                            )?.nameColor
                          }
                        </span>
                        <span>
                          {" "}
                          {t("size")}:{" "}
                          {
                            sizes.find(
                              (size) => size.idSize === cartItem.idSize
                            )?.nameSize
                          }
                        </span>
                        <span>
                          {t("QTY")}: {cartItems[uniqueKey].quantity}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
      </div>
      <div className="separator-line-checkout-nd"></div>
      <div className="checkout-form-code">
        {userStatue ? (
          discountAmount > 0 ? (
            <form className="checkout-row" onSubmit={onSubmitCode}>
              <span>{t("CodeStored")}</span>
              <button onClick={() => setPromoCode("")}>{t("Remove")}</button>
            </form>
          ) : (
            <form className="checkout-row" onSubmit={onSubmitCode}>
              <input
                type="text"
                id="promoCode"
                name="promoCodeGuess"
                placeholder={t("CodePromo")}
                value={promoCode}
                onChange={handlePromoCodeChange}
              />
              <button>{t("Apply")}</button>
            </form>
          )
        ) : subtotalGuessCart > 0 ? (
          discount > 0 ? (
            <form className="checkout-row" onSubmit={onSubmitCode}>
              <span>{t("CodeStored")}</span>
              <button onClick={() => setPromoCode("")}>{t("Remove")}</button>
            </form>
          ) : (
            <form className="checkout-row" onSubmit={onSubmitCode}>
              <input
                type="text"
                id="promoCode"
                name="promoCodeGuess"
                placeholder={t("CodePromo")}
                value={promoCode}
                onChange={handlePromoCodeChange}
              />
              <button>{t("Apply")}</button>
            </form>
          )
        ) : null}
        <small className="errorInputConfig">{codePromoMessage}</small>
      </div>
      <div className="rigthSideCart">
        {userStatue ? (
          totalPrice > 0 ? (
            <>
              <div className="checkout-value-container">
                <div className="checkout-value">
                  <div className="checkout-row">
                    <span>{t("SubTotal")}</span>
                    <span>{pricesProduct} &euro;</span>
                  </div>
                  <div className="checkout-row">
                    <span>{t("Shipping")}</span>
                    <span>
                      {shippingUser > 0 ? shippingUser : t("Free")}{" "}
                      {shippingUser > 0 ? "€" : ""}
                    </span>
                  </div>
                  {discountAmount > 0 && (
                    <div className="checkout-row">
                      <span>{t("Discount")}</span>
                      <span>-{discountAmount} &euro;</span>
                    </div>
                  )}
                  <div className="checkout-row">
                    <span>Total</span>
                    <span>{totalPrice} &euro;</span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="containerEmptyCartCartPage">
              <div className="spanEmpty">
                <span>{t("CartIsEmpty")}</span>
              </div>
            </div>
          )
        ) : subtotalGuessCart > 0 ? (
          <>
            <div className="checkout-value-container">
              <div className="checkout-value">
                <div className="checkout-row">
                  <span>{t("SubTotal")}</span>
                  <span>{subtotalGuessCart} &euro;</span>
                </div>
                <div className="checkout-row">
                  <span>{t("Shipping")}</span>
                  <span>
                    {deliveryPriceGuess > 0 ? deliveryPriceGuess : t("Free")}{" "}
                    {deliveryPriceGuess > 0 ? "€" : ""}
                  </span>
                </div>
                {discount > 0 && (
                  <div className="checkout-row">
                    <span>{t("Discount")}</span>
                    <span>-{discount} &euro;</span>
                  </div>
                )}
                <div className="checkout-row">
                  <span>Total</span>
                  <span>{totalCartGuess} &euro;</span>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ContentCartCheckout;
