import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ProductCard = ({ idProduct, setIsLoading }) => {
  const { t } = useTranslation();
  const baseUrl = "https://api.sweatteeshop.com/public/images/";

  const [product, setProduct] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productsResponse = await axios.get(
          `https://api.sweatteeshop.com/front/product/${idProduct}`
        );

        const productsData = Object.values(productsResponse.data);
        setProduct(productsData);
        setIsLoading(false);
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la récupération des données.",
          error
        );
        setIsLoading(false);
      }
    };

    fetchData();
  }, [idProduct, setIsLoading]);

  return (
    <a href={`/produit/${idProduct}`} rel="nofollow">
      <div className="product-card">
        <img
          src={
            baseUrl +
            product[0]?.mediaProduct.find((media) => media.isCardImg === 1)
              ?.nameMedia
          }
          alt={product?.nameProduct}
          className="product-card-img"
        />
        <div className="product-details">
          <p className="product-name">{product[0]?.nameProduct}</p>
          <p className="product-price">{product[0]?.PriceProduct} €</p>
        </div>
      </div>
    </a>
  );
};

export default ProductCard;
