import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";

const ArticlePage = ({ setIsLoading }) => {
  let { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [articles, setArticles] = useState([]);

  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const totalHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const footerHeight = document.querySelector("footer")?.offsetHeight || 0;
      const adjustedTotalHeight = totalHeight - footerHeight;

      const progress = (window.scrollY / adjustedTotalHeight) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.sweatteeshop.com/back/newsletter/data/${id}`
        );
        setArticles(Object.values(response.data));
        setIsLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, setIsLoading]);

  return (
    <div className="article-page">
      <div className="header-content-admin">
        <NavLink to="/back/gestion-des-newsletter">
          <button>Retour</button>
        </NavLink>
      </div>
      {articles[0]?.content ? (
        <div>
          <div dangerouslySetInnerHTML={{ __html: articles[0]?.content }}></div>
          <progress
            value={scrollProgress}
            max="100"
            className="progress-bar"
          ></progress>
        </div>
      ) : (
        <p>Contenu vide</p>
      )}
    </div>
  );
};

export default ArticlePage;
