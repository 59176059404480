import React, { useContext, useEffect, useState } from "react";
import { ParamContext } from "../contexts/ParamContexts";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Cart = ({ setIsLoading }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const {
    cartItems,
    getCartItemCount,
    removeFromCart,
    addToCart,
    subtractFromCart,
    emptyCart,
    totalPrice,
    userStatue,
    totalQuantity,
    cartsProducts,
    onSubmitaddToCart,
    onSubmitSubstractToCart,
    onSubmitremovToCart,
    onSubmitEmptyCart,
    pricesProduct,
    subtotalGuessCart,
  } = useContext(ParamContext);

  const { t } = useTranslation();

  const baseUrl = "https://api.sweatteeshop.com/public/images/";

  const itemCount = getCartItemCount();

  const [products, setProducts] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productsResponse = await axios.get(
          "https://api.sweatteeshop.com/front/products"
        );
        const colorsResponse = await axios.get(
          "https://api.sweatteeshop.com/front/colors"
        );
        const sizesResponse = await axios.get(
          "https://api.sweatteeshop.com/front/sizes"
        );

        setProducts(Object.values(productsResponse.data));
        setColors(Object.values(colorsResponse.data));
        setSizes(Object.values(sizesResponse.data));
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [setIsLoading]);

  return (
    <div className="cartPageContainer">
      <div className="headerCartPageContainer">
        <h3>
          {t("YourCart")} ({userStatue ? totalQuantity : itemCount} {t("item")}
          (s))
        </h3>
      </div>
      <div
        className={
          userStatue
            ? totalPrice > 0
              ? "cartPage"
              : "cartPageEmpty"
            : subtotalGuessCart > 0
            ? "cartPage"
            : "cartPageEmpty"
        }
      >
        <div className="leftSideCart">
          {userStatue
            ? cartsProducts.map((product) => {
                return (
                  <div className="cartItemPageCartPage" key={product.idCart}>
                    <div className="img-container-cart">
                      <img
                        src={baseUrl + product?.imagesProduct}
                        alt="product"
                      />
                    </div>
                    <div className="descriptionProductCartPage">
                      <div className="left-card-cart">
                        <p>{product.nameProduct}</p>
                        <div className="cartItemInfosCartCartPage">
                          <span>
                            {" "}
                            {t("color")}:{" "}
                            {
                              colors.find(
                                (color) => color.idColor === product.idColor
                              )?.nameColor
                            }
                          </span>
                          <span>
                            {" "}
                            {t("size")}:{" "}
                            {
                              sizes.find(
                                (size) => size.idSize === product.idSize
                              )?.nameSize
                            }
                          </span>
                        </div>
                        <div className="btnCountHandlerCartPage">
                          <button
                            onClick={() =>
                              onSubmitSubstractToCart(
                                product.idProduct,
                                product.idColor,
                                product.idSize,
                                product.price,
                                product.productWeigth
                              )
                            }
                          >
                            &minus;
                          </button>
                          <span>{product.quantity}</span>
                          <button
                            onClick={() =>
                              onSubmitaddToCart(
                                product.idProduct,
                                product.idColor,
                                product.idSize,
                                product.price,
                                product.productWeigth
                              )
                            }
                          >
                            {" "}
                            +{" "}
                          </button>
                        </div>
                      </div>
                      <div className="right-card-cart">
                        <span>{product.price} &euro;</span>
                        <svg
                          onClick={() =>
                            onSubmitremovToCart(
                              product.idProduct,
                              product.idColor,
                              product.idSize
                            )
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 105.7 122.88"
                        >
                          <path d="M30.46,14.57V5.22A5.18,5.18,0,0,1,32,1.55v0A5.19,5.19,0,0,1,35.68,0H70a5.22,5.22,0,0,1,3.67,1.53l0,0a5.22,5.22,0,0,1,1.53,3.67v9.35h27.08a3.36,3.36,0,0,1,3.38,3.37V29.58A3.38,3.38,0,0,1,102.32,33H98.51l-8.3,87.22a3,3,0,0,1-2.95,2.69H18.43a3,3,0,0,1-3-2.95L7.19,33H3.37A3.38,3.38,0,0,1,0,29.58V17.94a3.36,3.36,0,0,1,3.37-3.37Zm36.27,0V8.51H39v6.06ZM49.48,49.25a3.4,3.4,0,0,1,6.8,0v51.81a3.4,3.4,0,1,1-6.8,0V49.25ZM69.59,49a3.4,3.4,0,1,1,6.78.42L73,101.27a3.4,3.4,0,0,1-6.78-.43L69.59,49Zm-40.26.42A3.39,3.39,0,1,1,36.1,49l3.41,51.8a3.39,3.39,0,1,1-6.77.43L29.33,49.46ZM92.51,33.38H13.19l7.94,83.55H84.56l8-83.55Z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                );
              })
            : Object.entries(cartItems).map(([uniqueKey, cartItem]) => {
                const product = products.find(
                  (p) => p.idProduct === cartItem.idProduct
                );
                if (product && cartItem.quantity > 0) {
                  return (
                    <div className="cartItemPageCartPage" key={uniqueKey}>
                      <div className="img-container-cart">
                        <img
                          src={baseUrl + product?.mediaProduct[0].nameMedia}
                          alt="product"
                        />
                      </div>
                      <div className="descriptionProductCartPage">
                        <div className="left-card-cart">
                          <p>{product.nameProduct}</p>
                          <div className="cartItemInfosCartCartPage">
                            <span>
                              {" "}
                              {t("color")}:{" "}
                              {
                                colors.find(
                                  (color) => color.idColor === cartItem.idColor
                                )?.nameColor
                              }
                            </span>
                            <span>
                              {" "}
                              {t("size")}:{" "}
                              {
                                sizes.find(
                                  (size) => size.idSize === cartItem.idSize
                                )?.nameSize
                              }
                            </span>
                          </div>
                          <div className="btnCountHandlerCartPage">
                            <button
                              onClick={() =>
                                subtractFromCart(
                                  [uniqueKey],
                                  product.PriceProduct,
                                  product.productWeigth
                                )
                              }
                            >
                              &minus;
                            </button>
                            <span>{cartItems[uniqueKey].quantity}</span>
                            <button
                              onClick={() =>
                                addToCart(
                                  [uniqueKey],
                                  product.PriceProduct,
                                  product.productWeigth
                                )
                              }
                            >
                              {" "}
                              +{" "}
                            </button>
                          </div>
                        </div>
                        <div className="right-card-cart">
                          <span>{product.PriceProduct} &euro;</span>
                          <svg
                            onClick={() => removeFromCart([uniqueKey])}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 105.7 122.88"
                          >
                            <path d="M30.46,14.57V5.22A5.18,5.18,0,0,1,32,1.55v0A5.19,5.19,0,0,1,35.68,0H70a5.22,5.22,0,0,1,3.67,1.53l0,0a5.22,5.22,0,0,1,1.53,3.67v9.35h27.08a3.36,3.36,0,0,1,3.38,3.37V29.58A3.38,3.38,0,0,1,102.32,33H98.51l-8.3,87.22a3,3,0,0,1-2.95,2.69H18.43a3,3,0,0,1-3-2.95L7.19,33H3.37A3.38,3.38,0,0,1,0,29.58V17.94a3.36,3.36,0,0,1,3.37-3.37Zm36.27,0V8.51H39v6.06ZM49.48,49.25a3.4,3.4,0,0,1,6.8,0v51.81a3.4,3.4,0,1,1-6.8,0V49.25ZM69.59,49a3.4,3.4,0,1,1,6.78.42L73,101.27a3.4,3.4,0,0,1-6.78-.43L69.59,49Zm-40.26.42A3.39,3.39,0,1,1,36.1,49l3.41,51.8a3.39,3.39,0,1,1-6.77.43L29.33,49.46ZM92.51,33.38H13.19l7.94,83.55H84.56l8-83.55Z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          {userStatue
            ? totalPrice > 0 && (
                <div className="btnEmptyContainerCartPage">
                  <button
                    onClick={() => {
                      onSubmitEmptyCart();
                    }}
                  >
                    {t("EmptyCart")}
                  </button>
                </div>
              )
            : subtotalGuessCart > 0 && (
                <div className="btnEmptyContainerCartPage">
                  <button
                    onClick={() => {
                      emptyCart();
                    }}
                  >
                    {t("EmptyCart")}
                  </button>
                </div>
              )}
        </div>
        <div className="rigthSideCart">
          {userStatue ? (
            totalPrice > 0 ? (
              <>
                <div className="checkout-value-container">
                  <div className="checkout-value">
                    <div className="checkout-row">
                      <span>{t("SubTotal")}</span>
                      <span>{pricesProduct} &euro;</span>
                    </div>
                    <div className="checkout-row">
                      <span>{t("Shipping")}</span>
                      <span>{t("CalculatedAtNextStep")}</span>
                    </div>
                    <div className="checkout-row">
                      <span>Total</span>
                      <span>{pricesProduct} &euro;</span>
                    </div>
                  </div>
                  <div className="Checkout-btn-container">
                    <a href="/checkout/user">
                      <button>{t("checkout")}</button>
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <div className="containerEmptyCartCartPage">
                <div className="spanEmpty">
                  <span>{t("CartIsEmpty")}</span>
                </div>
              </div>
            )
          ) : subtotalGuessCart > 0 ? (
            <>
              <div className="checkout-value-container">
                <div className="checkout-value">
                  <div className="checkout-row">
                    <span>{t("SubTotal")}</span>
                    <span>{subtotalGuessCart} &euro;</span>
                  </div>
                  <div className="checkout-row">
                    <span>{t("Shipping")}</span>
                    <span>{t("CalculatedAtNextStep")}</span>
                  </div>
                  <div className="checkout-row">
                    <span>Total</span>
                    <span>{subtotalGuessCart} &euro;</span>
                  </div>
                </div>
                <div className="Checkout-btn-container">
                  <a href="/checkout">
                    <button>{t("checkout")}</button>
                  </a>
                </div>
              </div>
            </>
          ) : (
            <div className="containerEmptyCartCartPage">
              <div className="spanEmpty">
                <span>{t("CartIsEmpty")}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
