import React, { useContext, useEffect, useState } from "react";
import NavbarBack from "../components/NavbarBack";
import NavSection from "../components/NavSection";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import countriesFr from "../countriesFr.json";
import countriesEn from "../countriesEn.json";
import { ParamContext } from "../contexts/ParamContexts";
import { useNavigate } from "react-router-dom";

const CreateBill = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const language = i18n.language || "fr";
  const jsonFile = language === "fr" ? countriesFr : countriesEn;

  const { adminData } = useContext(ParamContext);

  useEffect(() => {
    if (adminData.adminAccess === 0) {
      navigate("/back/gestion-des-commandes");
    }
  }, [adminData, navigate]);

  const [products, setProducts] = useState([]);

  function addInput() {
    const newInput = {
      nameProduct: "",
      quantity: "",
      price: "",
      vat: "20",
    };
    setProducts([...products, newInput]);
  }

  const [message, setMessage] = useState("");
  const [isloading, setIsloading] = useState(false);

  const validationSchema = Yup.object().shape({
    country: Yup.string().test(
      "Pays",
      "Ce champ est obligatoire",
      function (country) {
        return country !== "Pays *";
      }
    ),
    firstName: Yup.string().required(t("LastName") + " " + t("missing")),
    lastName: Yup.string().required(t("FirstName") + " " + t("missing")),
    email: Yup.string()
      .required("Email " + t("missing"))
      .email("Email " + t("invalid") + " (sweatteeshop@gmail.com)"),
    phone: Yup.string()
      .required(t("Phone") + " " + t("missing"))
      .matches(/([0-9])/, t("Phone") + " " + t("invalid")),
    address: Yup.string().required(t("Address") + " " + t("missing")),
    city: Yup.string().required(t("City") + " " + t("missing")),
    zip: Yup.string()
      .required(t("Zip") + " " + t("missing"))
      .matches(/([0-9])/, t("Zip") + " " + t("invalid")),
    idOrder: Yup.string()
      .required("N° de commande manquant")
      .matches(/([0-9])/, "Veuillez saisir un numero valide"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  function onSubmit(data) {
    setIsloading(true);
    let subtotal = 0;

    products.forEach((product) => {
      const qty = parseFloat(product.quantity);
      const price = parseFloat(product.price);
      const vat = parseFloat(product.vat);

      if (!isNaN(qty) && !isNaN(price) && !isNaN(vat)) {
        const totalPriceWithoutVAT = qty * price;
        const totalVAT = totalPriceWithoutVAT * (vat / 100);
        subtotal += totalPriceWithoutVAT + totalVAT;
      }
    });

    const shipping = parseFloat(data.shipping);
    if (!isNaN(shipping)) {
      subtotal += shipping;
    }

    let discountAmount = 0;
    const discountValue = parseFloat(data.DiscountValue);
    if (!isNaN(discountValue)) {
      if (data.discountType === "Rate") {
        discountAmount = (subtotal * discountValue) / 100;
      } else {
        discountAmount = discountValue;
      }
    }

    const totalPrice = (subtotal - discountAmount).toFixed(2);

    const formatData = {
      idOrder: data.idOrder,
      dateOrder: data.dateOrder,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      address: data.address,
      addressComp: data.addressComp,
      city: data.city,
      zip: data.zip,
      country: data.country,
      shipping: data.shipping,
      typeDiscount: data.discountType,
      discountValue: data.DiscountValue,
      contentOrder: products,
      totalPrice,
    };

    axios
      .post("https://api.sweatteeshop.com/back/bills/create", formatData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        console.log(response);
        // alert(response.data);
        setMessage(response.data.message);
        if (response.data.success === true) {
          reset();
        }
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false);
      });
  }

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavSection />
        <div className="admin-content-page">
          <div className="content-back-container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="checkout-form-container">
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input
                      id="lastName"
                      name="lastName"
                      placeholder={t("LastName")}
                      {...register("lastName")}
                    />
                    {errors.lastName && (
                      <small className="errorInputConfig">
                        {errors.lastName?.message}
                      </small>
                    )}
                  </div>
                  <div className="input-checkout-form-nd">
                    <input
                      id="firstName"
                      name="firstName"
                      placeholder={t("FirstName")}
                      {...register("firstName", {})}
                    />
                    {errors.firstName && (
                      <small className="errorInputConfig">
                        {errors.firstName?.message}
                      </small>
                    )}
                  </div>
                </div>
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input
                      id="email"
                      name="email"
                      placeholder="Email"
                      {...register("email")}
                    />
                    {errors.email && (
                      <small className="errorInputConfig">
                        {errors.email?.message}
                      </small>
                    )}
                  </div>
                  <div className="input-checkout-form-nd">
                    <input
                      id="phone"
                      name="phone"
                      placeholder={t("Phone")}
                      {...register("phone")}
                    />
                    <small className="errorInputConfig">
                      {errors.phone?.message}
                    </small>
                  </div>
                </div>
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input
                      id="address"
                      name="address"
                      placeholder={t("Address") + " " + t("Line") + " 1"}
                      {...register("address")}
                    />
                    {errors.address && (
                      <small className="errorInputConfig">
                        {errors.address?.message}
                      </small>
                    )}
                  </div>
                </div>
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input
                      id="addressComp"
                      name="addressComp"
                      placeholder={t("Address") + " " + t("Line") + " 2"}
                      {...register("addressComp")}
                    />
                  </div>
                </div>
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input
                      id="city"
                      name="city"
                      placeholder={t("City")}
                      {...register("city")}
                    />
                    {errors.city && (
                      <small className="errorInputConfig">
                        {errors.city?.message}
                      </small>
                    )}
                  </div>
                  <div className="input-checkout-form-nd">
                    <input
                      type="text"
                      id="zip"
                      name="zip"
                      placeholder={t("Zip")}
                      {...register("zip")}
                    />
                    {errors.zip && (
                      <small className="errorInputConfig">
                        {errors.zip?.message}
                      </small>
                    )}
                  </div>
                  <div className="selectCase-checkout">
                    <select
                      className="ItemSelect"
                      name="country"
                      {...register("country")}
                    >
                      {jsonFile.map((country) => (
                        <option key={country.code}>{country.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="items-container">
                  <div className="items-product">
                    <div className="item">
                      <label htmlFor="shipping">N° de commande associée</label>
                      <input
                        id="idOrder"
                        name="idOrder"
                        {...register("idOrder")}
                      />
                      {errors.idOrder && (
                        <small className="errorInputConfig">
                          {errors.idOrder?.message}
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="items-product">
                    <div className="item">
                      <label htmlFor="dateOrder">
                        Date de la commande associée
                      </label>
                      <input
                        type="date"
                        id="dateOrder"
                        name="dateOrder"
                        {...register("dateOrder")}
                      />
                      {errors.dateOrder && (
                        <small className="errorInputConfig">
                          {errors.dateOrder?.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="items-container">
                  <h4>Produits *</h4>
                  {products.map((input, index) => (
                    <div className="items-product" key={index}>
                      <div className="item">
                        <label htmlFor={`Service-select-${index}`}>
                          Produit *
                        </label>
                        <textarea
                          className={
                            products[index].nameProduct.length > 65
                              ? "text-too-long"
                              : ""
                          }
                          name="nameProduct"
                          cols="35"
                          rows="3"
                          maxLength={65}
                          id={`Service-select-${index}`}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue.length <= 65) {
                              const newInputs = [...products];
                              newInputs[index].nameProduct = inputValue;
                              setProducts(newInputs);
                            } else {
                              const tronqueeValue =
                                inputValue.substring(0, 65) + "...";
                              const newInputs = [...products];
                              newInputs[index].nameProduct = tronqueeValue;
                              setProducts(newInputs);
                            }
                          }}
                        />
                        <small>65 caractères maximum</small>
                      </div>
                      <div className="item">
                        <label htmlFor={`qty-input-${index}`}>Quantité *</label>
                        <input
                          name="quantity"
                          id={`qty-input-${index}`}
                          value={input.qtyProduct}
                          onChange={(e) => {
                            const newInputs = [...products];
                            newInputs[index].quantity = e.target.value;
                            setProducts(newInputs);
                          }}
                          min="0"
                          step="1"
                        />
                      </div>
                      <div className="item">
                        <label htmlFor={`price-select-${index}`}>PU.HT *</label>
                        <input
                          name="price"
                          id={`price-select-${index}`}
                          onChange={(e) => {
                            const newInputs = [...products];
                            newInputs[index].price = e.target.value;
                            setProducts(newInputs);
                          }}
                        />
                      </div>
                      <div className="item">
                        <label htmlFor={`vat-select-${index}`}>TVA *</label>
                        <select
                          name="presta"
                          id={`vat-select-${index}`}
                          defaultValue="20"
                          onChange={(e) => {
                            const newInputs = [...products];
                            newInputs[index].vat = e.target.value;
                            setProducts(newInputs);
                          }}
                        >
                          <option value="5.5">5.5 %</option>
                          <option value="10">10 %</option>
                          <option value="20">20 %</option>
                        </select>
                      </div>
                      <div>
                        <button
                          onClick={() => {
                            const newInputs = [...products];
                            newInputs.splice(index, 1);
                            setProducts(newInputs);
                          }}
                        >
                          Retirer
                        </button>
                      </div>
                    </div>
                  ))}
                  <div>
                    <button
                      type="button"
                      className="black-button"
                      onClick={addInput}
                    >
                      Ajouter une product
                    </button>
                  </div>
                </div>
                <div className="items-container">
                  <h4>Livraison</h4>
                  <div className="items-product">
                    <div className="item">
                      <label htmlFor="shipping">Frais de livraison</label>
                      <input
                        id="shipping"
                        name="shipping"
                        {...register("shipping")}
                      />
                    </div>
                  </div>
                </div>
                <div className="items-container">
                  <h4>Remise</h4>
                  <div className="items-product">
                    <div className="item">
                      <label>Type de remise</label>
                      <select name="discountType" {...register("discountType")}>
                        <option value="Rate">Pourcentage</option>
                        <option value="Fixed">Soutraction</option>
                      </select>
                    </div>
                    <div className="item">
                      <label htmlFor="DiscountValue">Valeur de la remise</label>
                      <input
                        id="DiscountValue"
                        name="DiscountValue"
                        {...register("DiscountValue")}
                      />
                    </div>
                  </div>
                </div>
                <div className="btn-checkout-container">
                  <button type="submit" disabled={isloading}>
                    <span>{isloading ? "Chargement" : t("save")}</span>
                  </button>
                </div>
              </div>
            </form>
            <div>{message}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBill;
