import axios from "axios";
import React, { useEffect, useState } from "react";
import PopUp from "./PopUp";
import { NavLink } from "react-router-dom";

const NewsletterCard = ({ idArticle }) => {
  const [articles, setArticles] = useState([]);
  const [togglePopup, setTogglePopup] = useState(false);
  const [messagePopup, setMessagePopup] = useState("");
  const [comeFrom, setComeFrom] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getArticles = async () => {
      await axios
        .get(`https://api.sweatteeshop.com/back/newsletter/data/${idArticle}`)
        .then((res) => {
          const ordersObj = Object.values(res.data);
          setArticles(ordersObj[0]);
        });
    };
    getArticles();
  }, [idArticle]);

  const datePerser = (date) => {
    if (date) {
      const monthNames = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];

      const newDate = new Date(date);
      const day = newDate.getDate().toString().padStart(2, "0");
      const month = monthNames[newDate.getMonth()];
      const year = newDate.getFullYear();

      return `${day} ${month} ${year}`;
    } else {
      return "";
    }
  };

  const HandlePopupDeletion = () => {
    setMessagePopup(
      "La suppression est irreversible, êtes-vous certain de vouloir supprimer cet article ?"
    );
    setComeFrom("articleBlocDeletion");
    setTogglePopup(true);
  };

  const HandlePublish = () => {
    setMessagePopup(
      "Confirmez-vous l'envoie de l'article a la base newsletter ?"
    );
    setComeFrom("publishArticleFunc");
    setTogglePopup(true);
  };

  const PublishArticle = () => {
    setIsLoading(true);

    const formatData = {
      idArticle,
    };

    axios
      .post("https://api.sweatteeshop.com/back/newsletter/publish", formatData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        setIsLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const deleteArticle = () => {
    const formatData = {
      idArticle,
    };

    axios
      .post("https://api.sweatteeshop.com/back/newsletter/delete", formatData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let btnBill = null;

  if (articles?.isPublished === 1) {
    btnBill = "Publier à nouveau";
  } else {
    btnBill = "Publier";
  }

  return (
    <div>
      <div className="blog-card">
        <div className="card-content">
          <small>{datePerser(articles?.dateArticle)}</small>
          <h2>{articles?.title}</h2>
          <p>{articles?.description}</p>
          <small>
            Publié par {articles?.firstNameAuthor} {articles?.lastNameAuthor}
          </small>
          <button
            className="black-button"
            onClick={HandlePublish}
            disabled={isLoading}
          >
            {isLoading ? "Chargement..." : btnBill}
          </button>
          <NavLink to={`/back/newsletter/article/${idArticle}`}>
            <button className="black-button">
              <span>Lire l'article</span>
            </button>
          </NavLink>
          <NavLink to={`/back/newsletter/edition/${idArticle}`}>
            <button className="black-button">
              <span>Modifier</span>
            </button>
          </NavLink>
          <button className="black-button" onClick={HandlePopupDeletion}>
            Supprimer
          </button>
        </div>
      </div>
      <PopUp
        togglePopup={togglePopup}
        setTogglePopup={setTogglePopup}
        onSubmite={
          comeFrom === "publishArticleFunc" ? PublishArticle : deleteArticle
        }
        messagePopup={messagePopup}
        comeFrom={comeFrom}
      />
    </div>
  );
};

export default NewsletterCard;
