import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ParamContext } from "../contexts/ParamContexts";
import GuideSize from "../components/GuideSize";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const PageProduct = ({ setIsLoading }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  const baseUrl = "https://api.sweatteeshop.com/public/images/";

  const [product, setProduct] = useState();
  const [indexColor, setIndexColor] = useState(0);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [displayNotif, setDisplayNotif] = useState(false);

  const [message, setMessage] = useState("");
  const [toggleSizeGuide, setToggleSizeGuide] = useState("");
  const [comeFromValue, setComeFromValue] = useState("");

  let { id } = useParams();

  const {
    putOnCart,
    shareColor,
    shareSize,
    userStatue,
    submiteOnCart,
    idUser,
    nameCompany,
    imgOpenTags,
  } = useContext(ParamContext);

  useEffect(() => {
    shareSize(selectedSize);
    shareColor(selectedColor);
  }, [selectedSize, selectedColor, shareSize, shareColor]);

  const myRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!myRef.current.contains(e.target)) {
        setToggleSizeGuide(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    const color = selectedColor ? selectedColor : "-1";
    const size = selectedSize ? selectedSize : "-1";

    const fetchData = async () => {
      const response = await axios.get(
        `https://api.sweatteeshop.com/front/product/${id}/${color}/${size}`
      );
      setProduct(Object.values(response.data));
      setIsLoading(false);
    };
    fetchData();
  }, [id, selectedColor, selectedSize, setIsLoading]);

  if (!product) {
    return <p>Loading...</p>;
  }

  const handleSelectSize = (size) => {
    setSelectedSize(size.idSize);
  };

  const handleSelectColor = (color) => {
    setSelectedColor(color.idColor);
  };

  const handleTabColor = (index) => {
    setIndexColor(index);
  };
  
  return (
    <div className="page-detail-container">
      <Helmet>
        <title>{`${nameCompany} | ${product[0]?.nameProduct}`}</title>
        <meta
          property="og:title"
          content={`${nameCompany} | ${product[0]?.nameProduct}`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta property="og:url" content="https://sweatteeshop.com" />
        <meta property="og:site_name" content="sweatteeshop-officiel" />
        <link rel="canonical" href={`https://sweatteeshop.com`} />
      </Helmet>
      <div className="img-product-container">
        {product[0]?.mediaProduct.map((img) => (
          <div className="product-img-container" key={img.idImage}>
            <div
              className="product-img"
              style={{ backgroundImage: `url(${baseUrl}${img.nameMedia})` }}
              alt="product"
            ></div>
          </div>
        ))}
      </div>
      <div className="box-right-page-product">
        <div className="row">
          <h1>{product[0]?.nameProduct}</h1>
          <span>Ref: {product[0]?.referenceProduct}</span>
          <div></div>
          <span className="price-product-detail-page">
            {product[0]?.PriceProduct}&euro;
          </span>
        </div>
        <div className="colors-container">
          <span>
            {t("color")}: {product[0]?.colorProduct[indexColor].nameColor}
          </span>
          <div className="color-btn">
            {product[0]?.colorProduct?.map((color, indexColor) => {
              const isColorAvailable = product[0]?.stocks.some(
                (stock) =>
                  stock.idColor === color.idColor && stock.stockProduct > 0
              );
              return (
                <button
                  key={indexColor}
                  className={`color-product ${
                    color.idColor === selectedColor ? "selected" : ""
                  } ${isColorAvailable ? "" : "notAvailableColor"}`}
                  style={{ background: color.CodeColor }}
                  onClick={() => {
                    if (isColorAvailable) {
                      handleSelectColor(color);
                      handleTabColor(indexColor);
                    }
                  }}
                >
                  {!isColorAvailable && <div className="cross"></div>}
                </button>
              );
            })}
          </div>
        </div>
        <div className="sizes-container">
          {product[0]?.sizeProduct
            .sort((a, b) => {
              const orderSizes = [
                "XS",
                "S",
                "M",
                "L",
                "XL",
                "2XL",
                "3XL",
                "UNI",
              ];
              return (
                orderSizes.indexOf(a.nameSize) - orderSizes.indexOf(b.nameSize)
              );
            })
            .map((size, indexSize) => {
              const isSizeAvailable = product[0]?.stocks.some(
                (stock) =>
                  stock.idSize === size.idSize && stock.stockProduct > 0
              );
              return (
                <div
                  className={`size-product ${
                    size.idSize === selectedSize ? "selected" : ""
                  } ${isSizeAvailable ? "" : "notAvailableSize"}`}
                  key={indexSize}
                  onClick={() => {
                    if (isSizeAvailable) {
                      handleSelectSize(size);
                    }
                  }}
                >
                  {size.nameSize}
                </div>
              );
            })}
        </div>
        <div className="guide-size-container" ref={myRef}>
          {product[0].catProduct !== "Accessories" && (
            <span
              onClick={() => {
                setToggleSizeGuide(!toggleSizeGuide);
                setComeFromValue(product[0].catProduct);
              }}
            >
              {t("SizeHelp")}
            </span>
          )}
        </div>
        <div className="btn-add-cart">
          {product[0].stocks.every((stock) => stock.stockProduct <= 0) ? (
            <div className="empty-stock-container">
              <div>
                <span className="notAvailableSize">{t("ProductOutOfStock")}</span>
              </div>
              {/* <button
                onClick={() => {
                  setDisplayNotif(!displayNotif);
                }}
              >
                {t("PreventMe")}
              </button> */}
            </div>
          ) : userStatue ? (
            <button
              onClick={() => {
                if (!selectedSize && !selectedColor) {
                  setMessage(t("selectSizeColor"));
                } else if (!selectedSize) {
                  setMessage(t("selectSize"));
                } else if (!selectedColor) {
                  setMessage(t("selectColor"));
                } else {
                  submiteOnCart(
                    idUser,
                    product[0].idProduct,
                    product[0].PriceProduct,
                    product[0].productWeigth,
                    selectedSize,
                    selectedColor
                  );
                  setMessage("");
                }
              }}
            >
              {t("Add")}
            </button>
          ) : (
            <button
              onClick={() => {
                if (!selectedSize && !selectedColor) {
                  setMessage(t("selectSizeColor"));
                } else if (!selectedSize) {
                  setMessage(t("selectSize"));
                } else if (!selectedColor) {
                  setMessage(t("selectColor"));
                } else {
                  putOnCart(
                    product[0].idProduct,
                    product[0].PriceProduct,
                    product[0].productWeigth,
                    selectedSize,
                    selectedColor
                  );
                  setMessage("");
                }
              }}
            >
              {t("Add")}
            </button>
          )}
          {message && <div className="message-conect-form">{message}</div>}
        </div>
        <div className="describe-product-container">
          <span
            onClick={() => {
              setToggleSizeGuide(!toggleSizeGuide);
              setComeFromValue("descProduct");
            }}
          >
            {t("ProductDetails")}
          </span>
          <span
            onClick={() => {
              setToggleSizeGuide(!toggleSizeGuide);
              setComeFromValue("ShipInfo");
            }}
          >
            {t("DeliveryReturns")}
          </span>
        </div>
      </div>
      <GuideSize
        toggleSizeGuide={toggleSizeGuide}
        setToggleSizeGuide={setToggleSizeGuide}
        comeFrom={comeFromValue}
        des={product[0]?.descriptionProduct}
      />
    </div>
  );
};

export default PageProduct;
