import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import countriesFr from "../countriesFr.json";
import countriesEn from "../countriesEn.json";
import { useContext } from "react";
import { ParamContext } from "../contexts/ParamContexts";
import { NavLink } from "react-router-dom";

const CheckoutDeliveryGuess = ({
  isActive,
  handleNextStep,
  handlePreviousStep,
}) => {
  const { t, i18n } = useTranslation();

  const language = i18n.language || "fr";
  const jsonFile = language === "fr" ? countriesFr : countriesEn;

  const {
    deliveryformData,
    setDeliveryFormData,
    isSomeData,
    setBillingFormData,
    setBillingFormDataStores,
    // selectedShipping,
  } = useContext(ParamContext);

  const validationSchema = Yup.object().shape({
    country: Yup.string().test(
      "Pays",
      "Ce champ est obligatoire",
      function (country) {
        return country !== "Pays *";
      }
    ),
    firstName: Yup.string().required(t("LastName") + " " + t("missing")),
    lastName: Yup.string().required(t("FirstName") + " " + t("missing")),
    email: Yup.string()
      .email("Email " + t("invalid") + " (sweatteeshop@gmail.com)")
      .required("Email " + t("missing")),
    phone: Yup.string()
      .required(t("Phone") + " " + t("missing"))
      .matches(/([0-9])/, t("Phone") + " " + t("invalid")),
    address: Yup.string().required(t("Address") + " " + t("missing")),
    city: Yup.string().required(t("City") + " " + t("missing")),
    zip: Yup.string()
      .required(t("Zip") + " " + t("missing"))
      .matches(/([0-9])/, t("Zip") + " " + t("invalid")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  function onSubmitHome(data) {
    const formatData = {
      firstNameDelivery: data.firstName,
      lastNameDelivery: data.lastName,
      emailDelivery: data.email,
      phoneDelivery: data.phone,
      addressDelivery: data.address,
      adressCompDelivery: data.adressComp,
      cityDelivery: data.city,
      zipDelivery: data.zip,
      countryDelivery: data.country,
    };

    if (isSomeData) {
      setBillingFormData(formatData);
      setBillingFormDataStores(formatData);
      sessionStorage.setItem("billingFormData", JSON.stringify(formatData));
      sessionStorage.setItem(
        "billingFormDataStores",
        JSON.stringify(formatData)
      );
    }

    setDeliveryFormData(formatData);
    handleNextStep();
    sessionStorage.setItem("deliveryformData", JSON.stringify(formatData));
  }

  // Ralay

  // function onSubmitRalay(event) {
  //   event.preventDefault();
  //   handleNextStep();
  // }

  return (
    <div className="form-delivery">
      <div>1. {t("shippingInfo")}.</div>
      {
        isActive && (
          // (selectedShipping === "Ralay" ? (
          //   <form className="checkout-form-container" onSubmit={onSubmitRalay}>
          //     <div></div>
          //     <div className="btn-checkout-container">
          //       <button type="button" onClick={handlePreviousStep}>
          //         <span>{t("previous")}</span>
          //       </button>
          //       <button type="submit">
          //         <span>{t("Next")}</span>
          //       </button>
          //     </div>
          //   </form>
          // ) : (
          <form
            className="checkout-form-container"
            onSubmit={handleSubmit(onSubmitHome)}
          >
            <div className="checkout-form-container">
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="lastName"
                    name="lastName"
                    defaultValue={deliveryformData.lastNameDelivery || ""}
                    placeholder={t("LastName")}
                    {...register("lastName")}
                  />
                  {errors.lastName && (
                    <small className="errorInputConfig">
                      {errors.lastName?.message}
                    </small>
                  )}
                </div>
                <div className="input-checkout-form-nd">
                  <input
                    id="firstName"
                    name="firstName"
                    defaultValue={deliveryformData.firstNameDelivery || ""}
                    placeholder={t("FirstName")}
                    {...register("firstName", {})}
                  />
                  {errors.firstName && (
                    <small className="errorInputConfig">
                      {errors.firstName?.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="email"
                    name="email"
                    defaultValue={deliveryformData.emailDelivery || ""}
                    placeholder="Email"
                    {...register("email")}
                  />
                  {errors.email && (
                    <small className="errorInputConfig">
                      {errors.email?.message}
                    </small>
                  )}
                </div>
                <div className="input-checkout-form-nd">
                  <input
                    id="phone"
                    name="phone"
                    defaultValue={deliveryformData.phoneDelivery || ""}
                    placeholder={t("Phone")}
                    {...register("phone")}
                  />
                  <small className="errorInputConfig">
                    {errors.phone?.message}
                  </small>
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="address"
                    name="address"
                    defaultValue={deliveryformData.addressDelivery || ""}
                    placeholder={t("Address") + " " + t("Line") + " 1"}
                    {...register("address")}
                  />
                  {errors.address && (
                    <small className="errorInputConfig">
                      {errors.address?.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="adressComp"
                    name="adressComp"
                    defaultValue={deliveryformData.addressCompDelivery || ""}
                    placeholder={t("Address") + " " + t("Line") + " 2"}
                    {...register("adressComp")}
                  />
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="city"
                    name="city"
                    defaultValue={deliveryformData.cityDelivery || ""}
                    placeholder={t("City")}
                    {...register("city")}
                  />
                  {errors.city && (
                    <small className="errorInputConfig">
                      {errors.city?.message}
                    </small>
                  )}
                </div>
                <div className="input-checkout-form-nd">
                  <input
                    type="text"
                    id="zip"
                    name="zip"
                    defaultValue={deliveryformData.zipDelivery || ""}
                    placeholder={t("Zip")}
                    {...register("zip")}
                  />
                  {errors.zip && (
                    <small className="errorInputConfig">
                      {errors.zip?.message}
                    </small>
                  )}
                </div>
                <div className="selectCase-checkout">
                  <select
                    className="ItemSelect"
                    name="country"
                    defaultValue={deliveryformData.countryDelivery || "France"}
                    {...register("country")}
                  >
                    <option disabled>{deliveryformData.countryDelivery}</option>
                    {jsonFile.map((country) => (
                      <option key={country.code}>{country.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="btn-checkout-container">
              <NavLink to="/panier">
                <button type="button">
                  <span>{t("previous")}</span>
                </button>
              </NavLink>
              <button type="submit">
                <span>{t("Next")}</span>
              </button>
            </div>
          </form>
        )
        // ))
      }
    </div>
  );
};

export default CheckoutDeliveryGuess;
