import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavBarUserAccount from "../components/NavBarUserAccount";
import AddressUserCard from "../components/AddressUserCard";
import { ParamContext } from "../contexts/ParamContexts";

const DetailsOrder = ({ setIsLoading }) => {
  let { id } = useParams();
  const { idUser } = useContext(ParamContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [billing, setBilling] = useState([]);
  const [delivery, setDelivery] = useState([]);
  const [products, setProducts] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orderResponse = await axios.get(
          `https://api.sweatteeshop.com/front/users/order/${id}`
        );
        const orderData = Object.values(orderResponse.data);

        if (orderData.length > 0) {
          const billingResponse = await axios.get(
            `https://api.sweatteeshop.com/front/users/Address_info/${orderData[0].idBilling}`
          );
          setBilling(Object.values(billingResponse.data));

          const deliveryResponse = await axios.get(
            `https://api.sweatteeshop.com/front/users/Address_info/${orderData[0].idDelivery}`
          );
          setDelivery(Object.values(deliveryResponse.data));
        }

        const productResponse = await axios.get(
          `https://api.sweatteeshop.com/front/users/product_ordered/${idUser}/${id}`
        );
        setProducts(Object.values(productResponse.data));

        const colorsResponse = await axios.get(
          "https://api.sweatteeshop.com/front/colors"
        );
        setColors(Object.values(colorsResponse.data));

        const sizesResponse = await axios.get(
          "https://api.sweatteeshop.com/front/sizes"
        );
        setSizes(Object.values(sizesResponse.data));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, idUser, setIsLoading]);

  return (
    <div className="user-account-page">
      <div className="left-side-user-account">
        <NavBarUserAccount />
      </div>
      <div className="rigth-user-account">
        <div className="header-user-account">
          <p>{t("MyOrders")}</p>
        </div>
        <div className="body-user-account">
          <div className="address-user-container">
            <div className="address-user">
              <span>{t("DeliveryAddress")}</span>
              <AddressUserCard
                idAddress={delivery[0]?.idAddress}
                firstName={delivery[0]?.firstName}
                lastName={delivery[0]?.lastName}
                email={delivery[0]?.email}
                phone={delivery[0]?.phone}
                address={delivery[0]?.address}
                addressComp={delivery[0]?.addressComp}
                city={delivery[0]?.city}
                zip={delivery[0]?.zipCode}
                country={delivery[0]?.country}
                isFromOrder={true}
              />
            </div>
            <div className="address-user">
              <span>{t("BillingAddress")}</span>
              <AddressUserCard
                idAddress={billing[0]?.idAddress}
                firstName={billing[0]?.firstName}
                lastName={billing[0]?.lastName}
                email={billing[0]?.email}
                phone={billing[0]?.phone}
                address={billing[0]?.address}
                addressComp={billing[0]?.addressComp}
                city={billing[0]?.city}
                zip={billing[0]?.zipCode}
                country={billing[0]?.country}
                isFromOrder={true}
              />
            </div>
          </div>
          <div className="product-table">
            <table>
              <thead>
                <tr>
                  <th>Ref</th>
                  <th>{t("NameProduct")}</th>
                  <th>{t("color")}</th>
                  <th>{t("size")}</th>
                  <th>{t("Price")}</th>
                  <th>{t("QTY")}</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr key={product.id}>
                    <td>
                      <p>Ref:</p>
                      {product.referenceProduct}
                    </td>
                    <td>
                      <p>{t("Product")}:</p>
                      <NavLink
                        to={`/produit/${product.idProduct}`}
                        rel="nofollow"
                      >
                        {product.nameProduct}
                      </NavLink>
                    </td>
                    <td>
                      <p>{t("color")}:</p>
                      {
                        colors.find(
                          (color) => color.idColor === product.idColor
                        )?.nameColor
                      }
                    </td>
                    <td>
                      <p>{t("size")}:</p>
                      {
                        sizes.find((size) => size.idSize === product.idSize)
                          ?.nameSize
                      }
                    </td>
                    <td>
                      <p>{t("Price")}:</p>
                      {product.price} €
                    </td>
                    <td>
                      {" "}
                      <p>{t("QTY")}:</p>
                      {product.quantity}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsOrder;
