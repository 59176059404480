import React from "react";
import { useTranslation } from "react-i18next";

const HomePageCard = ({ tshirtImg, nameCat }) => {
  const { t } = useTranslation();

  return (
    <div className="catCardContainer">
      <div className="warpper">
        <div
          className="imgcatContainer"
          style={{ backgroundImage: tshirtImg }}
          alt="product"
        ></div>
        <p className="catCardText">{nameCat}</p>
        <div className="btn-visit">
          <p>{t("Visit")}</p>
        </div>
      </div>
    </div>
  );
};

export default HomePageCard;
