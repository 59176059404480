import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ParamContext } from "../contexts/ParamContexts";
import NavbarBack from "../components/NavbarBack";
import NavSection from "../components/NavSection";
import axios from "axios";

const MediaManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const { adminData } = useContext(ParamContext);

  useEffect(() => {
    if (adminData.adminAccess === 0) {
      navigate("/back/gestion-des-commandes");
    }
  }, [adminData, navigate]);

  const baseUrl = "https://api.sweatteeshop.com/public/images/";

  const [logo, setLogo] = useState("");
  const [ImgHomeCover, setImgHomeCover] = useState("");
  const [ImgHomePage, setImgHomePage] = useState("");
  const [imgOpenTag, setImgOpenTag] = useState("");
  const [TshirtIllustration, setTshirtIllustration] = useState("");
  const [HoodieIllustration, setHoodieIllustration] = useState("");
  const [BagIllustration, setBagIllustration] = useState("");
  const [NweInIllustration, setNweInIllustration] = useState("");
  const [signature, setSignature] = useState("");

  const [logoInput, setLogoInput] = useState("");
  const [imgHome, setImgHome] = useState("");
  const [imgHomeInput, setImgHomeInput] = useState("");
  const [imgOpenTagFile, setImgOpenTagFile] = useState("");
  const [TshirtIllustrationInput, setTshirtIllustrationInput] = useState("");
  const [HoodieIllustrationInput, setHoodieIllustrationInput] = useState("");
  const [BagIllustrationInput, setBagIllustrationInput] = useState("");
  const [NweInIllustrationInput, setNweInIllustrationInput] = useState("");
  const [signatureInput, setSignatureInput] = useState("");

  useEffect(() => {
    const getOrder = async () => {
      await axios
        .get(`https://api.sweatteeshop.com/back/settings/datas`)
        .then((res) => {
          const SettingssObj = Object.values(res.data);
          const settingsObj = Object.values(SettingssObj);
          settingsObj.forEach((setting) => {
            switch (setting.refSettings) {
              case "Hero Home page":
                setImgHome(setting.settingValue);
                break;
              case "logo":
                setLogo(setting.settingValue);
                break;
              case "imgHomeCover":
                setImgHomeCover(setting.settingValue);
                break;
              case "imgOpenTags":
                setImgOpenTag(setting.settingValue);
                break;
              case "T-shirt-img":
                setTshirtIllustration(setting.settingValue);
                break;
              case "sweat-img":
                setHoodieIllustration(setting.settingValue);
                break;
              case "Accessories-img":
                setBagIllustration(setting.settingValue);
                break;
              case "NewIn-img":
                setNweInIllustration(setting.settingValue);
                break;
              case "signature":
                setSignature(setting.settingValue);
                break;
              default:
                break;
            }
          });
        });
    };
    getOrder();
  }, []);

  const handleChangeLogo = (event) => {
    const newFiles = event.target.files;
    setLogoInput([...newFiles]);
  };

  const handleChangeImgHomePage = (event) => {
    const newFiles = event.target.files;
    setImgHomePage([...newFiles]);
  };

  const handleChangeHomeCover = (event) => {
    const newFiles = event.target.files;
    setImgHomeInput([...newFiles]);
  };

  const handleChangeOpenTag = (event) => {
    const newFiles = event.target.files;
    setImgOpenTagFile([...newFiles]);
  };

  const handleChangeTshirt = (event) => {
    const newFiles = event.target.files;
    setTshirtIllustrationInput([...newFiles]);
  };

  const handleChangeHoodie = (event) => {
    const newFiles = event.target.files;
    setHoodieIllustrationInput([...newFiles]);
  };

  const handleChangeBag = (event) => {
    const newFiles = event.target.files;
    setBagIllustrationInput([...newFiles]);
  };

  const handleChangeNewIn = (event) => {
    const newFiles = event.target.files;
    setNweInIllustrationInput([...newFiles]);
  };

  const handleChangeSign = (event) => {
    const newFiles = event.target.files;
    setSignatureInput([...newFiles]);
  };

  const ChangeLogo = async () => {
    const filesData = new FormData();

    filesData.append("logo", logo);
    for (let i = 0; i < logoInput.length; i++) {
      filesData.append("files", logoInput[i]);
    }

    try {
      //   const response =
      await axios.post(
        "https://api.sweatteeshop.com/back/settings/imgLogo",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const ChangeImgHomePage = async () => {
    const filesData = new FormData();

    filesData.append("imgHome", imgHome);
    for (let i = 0; i < ImgHomePage.length; i++) {
      filesData.append("files", ImgHomePage[i]);
    }

    try {
      //   const response =
      await axios.post(
        "https://api.sweatteeshop.com/back/settings/imgHome",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const changeHomeCover = async () => {
    const filesData = new FormData();

    filesData.append("ImgHomeCover", ImgHomeCover);
    for (let i = 0; i < imgHomeInput.length; i++) {
      filesData.append("files", imgHomeInput[i]);
    }

    try {
      // const response =
      await axios.post(
        "https://api.sweatteeshop.com/back/settings/ImgHomeCover",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      // alert(response.data);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const ChangeOpenTag = async () => {
    const filesData = new FormData();

    filesData.append("imgOpenTag", imgOpenTag);
    for (let i = 0; i < imgOpenTagFile.length; i++) {
      filesData.append("files", imgOpenTagFile[i]);
    }

    try {
      // const response =
      await axios.post(
        "https://api.sweatteeshop.com/back/settings/imgOpenTag",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const ChangeTshirt = async () => {
    const filesData = new FormData();

    filesData.append("TshirtIllustration", TshirtIllustration);
    for (let i = 0; i < TshirtIllustrationInput.length; i++) {
      filesData.append("files", TshirtIllustrationInput[i]);
    }

    try {
      //   const response =
      await axios.post(
        "https://api.sweatteeshop.com/back/settings/TshirtIllustration",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const ChangeHoodie = async () => {
    const filesData = new FormData();

    filesData.append("HoodieIllustration", HoodieIllustration);
    for (let i = 0; i < HoodieIllustrationInput.length; i++) {
      filesData.append("files", HoodieIllustrationInput[i]);
    }

    try {
      //   const response =
      await axios.post(
        "https://api.sweatteeshop.com/back/settings/HoodieIllustration",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const ChangeBag = async () => {
    const filesData = new FormData();

    filesData.append("BagIllustration", BagIllustration);
    for (let i = 0; i < BagIllustrationInput.length; i++) {
      filesData.append("files", BagIllustrationInput[i]);
    }

    try {
      //   const response =
      await axios.post(
        "https://api.sweatteeshop.com/back/settings/BagIllustration",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const ChangeNewIn = async () => {
    const filesData = new FormData();

    filesData.append("NweInIllustration", NweInIllustration);
    for (let i = 0; i < NweInIllustrationInput.length; i++) {
      filesData.append("files", NweInIllustrationInput[i]);
    }

    try {
      //   const response =
      await axios.post(
        "https://api.sweatteeshop.com/back/settings/NweInIllustration",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const ChangeSign = async () => {
    const filesData = new FormData();

    filesData.append("signature", signature);
    for (let i = 0; i < signatureInput.length; i++) {
      filesData.append("files", signatureInput[i]);
    }

    try {
      //   const response =
      await axios.post(
        "https://api.sweatteeshop.com/back/settings/signature",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavSection />
        <div className="admin-content-page">
          <div className="media-man-bloc">
            <div className="img-back-container">
              <h2>Logo</h2>
              <div className="media-visu">
                {logoInput && logoInput[0] && logoInput[0].name ? (
                  <img src={URL.createObjectURL(logoInput[0])} alt="media" />
                ) : (
                  <img src={baseUrl + logo} alt="media" />
                )}
              </div>
              <div className="btn-edite-img-contanier">
                <div className="upload-button">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleChangeLogo}
                    className="file-input"
                  />
                  <button className="btn-style">Parcourir</button>
                </div>
                {logoInput && logoInput[0] && logoInput[0].name && (
                  <div>
                    <button className="btn-style" onClick={ChangeLogo}>
                      Valider
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="img-back-container">
              <h2>Image acceuil</h2>
              <div className="media-visu">
                {ImgHomePage && ImgHomePage[0] && ImgHomePage[0].name ? (
                  <img src={URL.createObjectURL(ImgHomePage[0])} alt="media" />
                ) : (
                  <img src={baseUrl + imgHome} alt="media" />
                )}
              </div>
              <div className="btn-edite-img-contanier">
                <label htmlFor="uploadBannerL">
                  <div className="upload-button">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleChangeImgHomePage}
                      className="file-input"
                    />
                    <button className="btn-style">Parcourir</button>
                  </div>
                </label>
                {ImgHomePage && ImgHomePage[0] && ImgHomePage[0].name && (
                  <div>
                    <button className="btn-style" onClick={ChangeImgHomePage}>
                      Valider
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="img-back-container">
              <h2>Bannière acceuil</h2>
              <div className="media-visu">
                {imgHomeInput && imgHomeInput[0] && imgHomeInput[0].name ? (
                  <img src={URL.createObjectURL(imgHomeInput[0])} alt="media" />
                ) : (
                  <img src={baseUrl + ImgHomeCover} alt="media" />
                )}
              </div>
              <div className="btn-edite-img-contanier">
                <label htmlFor="uploadBannerSM">
                  <div className="upload-button">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleChangeHomeCover}
                      className="file-input"
                    />
                    <button className="btn-style">Parcourir</button>
                  </div>
                </label>
                {imgHomeInput && imgHomeInput[0] && imgHomeInput[0].name && (
                  <div>
                    <button className="btn-style" onClick={changeHomeCover}>
                      Valider
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="img-back-container">
              <h2>Open Tags (Facebook)</h2>
              <div className="media-visu">
                {imgOpenTagFile &&
                imgOpenTagFile[0] &&
                imgOpenTagFile[0].name ? (
                  <img
                    src={URL.createObjectURL(imgOpenTagFile[0])}
                    alt="media"
                  />
                ) : (
                  <img src={baseUrl + imgOpenTag} alt="media" />
                )}
              </div>
              <div className="btn-edite-img-contanier">
                <label htmlFor="uploadOpenTags">
                  <div className="upload-button">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleChangeOpenTag}
                      className="file-input"
                    />
                    <button className="btn-style">Parcourir</button>
                  </div>
                </label>
                {imgOpenTagFile &&
                  imgOpenTagFile[0] &&
                  imgOpenTagFile[0].name && (
                    <div>
                      <button className="btn-style" onClick={ChangeOpenTag}>
                        Valider
                      </button>
                    </div>
                  )}
              </div>
            </div>
            <div className="img-back-container">
              <h2>Illustration T-shirt</h2>
              <div className="media-visu">
                {TshirtIllustrationInput &&
                TshirtIllustrationInput[0] &&
                TshirtIllustrationInput[0].name ? (
                  <img
                    src={URL.createObjectURL(TshirtIllustrationInput[0])}
                    alt="media"
                  />
                ) : (
                  <img src={baseUrl + TshirtIllustration} alt="media" />
                )}
              </div>
              <div className="btn-edite-img-contanier">
                <label htmlFor="uploadSerigraph">
                  <div className="upload-button">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleChangeTshirt}
                      className="file-input"
                    />
                    <button className="btn-style">Parcourir</button>
                  </div>
                </label>
                {TshirtIllustrationInput &&
                  TshirtIllustrationInput[0] &&
                  TshirtIllustrationInput[0].name && (
                    <div>
                      <button className="btn-style" onClick={ChangeTshirt}>
                        Valider
                      </button>
                    </div>
                  )}
              </div>
            </div>
            <div className="img-back-container">
              <h2>Illustration Sweat-shirt</h2>
              <div className="media-visu">
                {HoodieIllustrationInput &&
                HoodieIllustrationInput[0] &&
                HoodieIllustrationInput[0].name ? (
                  <img
                    src={URL.createObjectURL(HoodieIllustrationInput[0])}
                    alt="media"
                  />
                ) : (
                  <img src={baseUrl + HoodieIllustration} alt="media" />
                )}
              </div>
              <div className="btn-edite-img-contanier">
                <label htmlFor="uploadImp">
                  <div className="upload-button">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleChangeHoodie}
                      className="file-input"
                    />
                    <button className="btn-style">Parcourir</button>
                  </div>
                </label>
                {HoodieIllustrationInput &&
                  HoodieIllustrationInput[0] &&
                  HoodieIllustrationInput[0].name && (
                    <div>
                      <button className="btn-style" onClick={ChangeHoodie}>
                        Valider
                      </button>
                    </div>
                  )}
              </div>
            </div>
            <div className="img-back-container">
              <h2>Illustration Sac</h2>
              <div className="media-visu">
                {BagIllustrationInput &&
                BagIllustrationInput[0] &&
                BagIllustrationInput[0].name ? (
                  <img
                    src={URL.createObjectURL(BagIllustrationInput[0])}
                    alt="media"
                  />
                ) : (
                  <img src={baseUrl + BagIllustration} alt="media" />
                )}
              </div>
              <div className="btn-edite-img-contanier">
                <label htmlFor="uploadImbrodery">
                  <div className="upload-button">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleChangeBag}
                      className="file-input"
                    />
                    <button className="btn-style">Parcourir</button>
                  </div>
                </label>
                {BagIllustrationInput &&
                  BagIllustrationInput[0] &&
                  BagIllustrationInput[0].name && (
                    <div>
                      <button className="btn-style" onClick={ChangeBag}>
                        Valider
                      </button>
                    </div>
                  )}
              </div>
            </div>
            <div className="img-back-container">
              <h2>Illustration nouveautés</h2>
              <div className="media-visu">
                {NweInIllustrationInput &&
                NweInIllustrationInput[0] &&
                NweInIllustrationInput[0].name ? (
                  <img
                    src={URL.createObjectURL(NweInIllustrationInput[0])}
                    alt="media"
                  />
                ) : (
                  <img src={baseUrl + NweInIllustration} alt="media" />
                )}
              </div>
              <div className="btn-edite-img-contanier">
                <label htmlFor="uploadBanLBlog">
                  <div className="upload-button">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleChangeNewIn}
                      className="file-input"
                    />
                    <button className="btn-style">Parcourir</button>
                  </div>
                </label>
                {NweInIllustrationInput &&
                  NweInIllustrationInput[0] &&
                  NweInIllustrationInput[0].name && (
                    <div>
                      <button className="btn-style" onClick={ChangeNewIn}>
                        Valider
                      </button>
                    </div>
                  )}
              </div>
            </div>
            <div className="img-back-container">
              <h2>Signature de mail</h2>
              <div className="media-visu">
                {signatureInput &&
                signatureInput[0] &&
                signatureInput[0].name ? (
                  <img
                    src={URL.createObjectURL(signatureInput[0])}
                    alt="media"
                  />
                ) : (
                  <img src={baseUrl + signature} alt="media" />
                )}
              </div>
              <div className="btn-edite-img-contanier">
                <label htmlFor="uploadImbrodery">
                  <div className="upload-button">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleChangeSign}
                      className="file-input"
                    />
                    <button className="btn-style">Parcourir</button>
                  </div>
                </label>
                {signatureInput &&
                  signatureInput[0] &&
                  signatureInput[0].name && (
                    <div>
                      <button className="btn-style" onClick={ChangeSign}>
                        Valider
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaManagement;
