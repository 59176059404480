import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBarUserAccount from "../components/NavBarUserAccount";
import { useForm } from "react-hook-form";
import { ParamContext } from "../contexts/ParamContexts";
import countriesFr from "../countriesFr.json";
import countriesEn from "../countriesEn.json";
import { useTranslation } from "react-i18next";

const ModificationAddressUser = ({ setIsLoading }) => {
  let { id } = useParams();

  const [address, setAddress] = useState([]);
  const { idUser } = useContext(ParamContext);

  const { t, i18n } = useTranslation();

  const language = i18n.language || "fr";
  const jsonFile = language === "fr" ? countriesFr : countriesEn;

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `https://api.sweatteeshop.com/front/users/Address_info/${id}`
      );
      setAddress(Object.values(response.data));
      setIsLoading(false);
    };
    fetchData();
  }, [id, setIsLoading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  function onSubmit(data) {
    const formatData = {
      idUser,
      idAddress: id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      address: data.address,
      adressComp: data.adressComp,
      city: data.city,
      zip: data.zip,
      country: data.country,
      DefaultBilling: data.DefaultBilling,
      DefaultDelivery: data.DefaultDelivery,
    };

    axios
      .post(
        "https://api.sweatteeshop.com/front/users/edite/address",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data === true) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="user-account-page">
      <div className="left-side-user-account">
        <NavBarUserAccount />
      </div>
      <div className="rigth-user-account">
        <div className="header-user-account">
          <p>{t("Addressbook")}</p>
          <span>{t("AddressModification")}</span>
        </div>
        <div className="body-user-account">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="checkout-form-container">
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="lastName"
                    name="lastName"
                    placeholder={t("LastName")}
                    defaultValue={address[0]?.lastName || ""}
                    {...register("lastName")}
                  />
                  {errors.lastName && (
                    <small className="errorInputConfig">
                      {errors.lastName?.message}
                    </small>
                  )}
                </div>
                <div className="input-checkout-form-nd">
                  <input
                    id="firstName"
                    name="firstName"
                    placeholder={t("FirstName")}
                    {...register("firstName", {})}
                    defaultValue={address[0]?.firstName || ""}
                  />
                  {errors.firstName && (
                    <small className="errorInputConfig">
                      {errors.firstName?.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="email"
                    name="email"
                    placeholder="Email"
                    {...register("email")}
                    defaultValue={address[0]?.email || ""}
                  />
                  {errors.email && (
                    <small className="errorInputConfig">
                      {errors.email?.message}
                    </small>
                  )}
                </div>
                <div className="input-checkout-form-nd">
                  <input
                    id="phone"
                    name="phone"
                    placeholder={t("Phone")}
                    {...register("phone")}
                    defaultValue={address[0]?.phone || ""}
                  />
                  <small className="errorInputConfig">
                    {errors.phone?.message}
                  </small>
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="address"
                    name="address"
                    placeholder={t("Address") + " " + t("Line") + " 1"}
                    {...register("address")}
                    defaultValue={address[0]?.address || ""}
                  />
                  {errors.address && (
                    <small className="errorInputConfig">
                      {errors.address?.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="adressComp"
                    name="adressComp"
                    placeholder={t("Address") + " " + t("Line") + " 2"}
                    {...register("adressComp")}
                    defaultValue={address[0]?.addressComp || ""}
                  />
                </div>
              </div>
              <div className="checkout-row-nd-step">
                <div className="input-checkout-form-nd">
                  <input
                    id="city"
                    name="city"
                    placeholder={t("City")}
                    {...register("city")}
                    defaultValue={address[0]?.city || ""}
                  />
                  {errors.city && (
                    <small className="errorInputConfig">
                      {errors.city?.message}
                    </small>
                  )}
                </div>
                <div className="input-checkout-form-nd">
                  <input
                    type="text"
                    id="zip"
                    name="zip"
                    placeholder={t("Zip")}
                    {...register("zip")}
                    defaultValue={address[0]?.zipCode || ""}
                  />
                  {errors.zip && (
                    <small className="errorInputConfig">
                      {errors.zip?.message}
                    </small>
                  )}
                </div>
                <div className="selectCase-checkout">
                  <select
                    className="ItemSelect"
                    name="country"
                    {...register("country")}
                    defaultValue={address[0]?.country || ""}
                  >
                    <option disabled>{address[0]?.country}</option>
                    {jsonFile.map((country) => (
                      <option key={country.code} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="payment-methode-container">
                {address[0]?.isDefaultBilling !== 1 && (
                  <div className="payment-methode">
                    <input
                      type="checkbox"
                      name="DefaultBilling"
                      id="DefaultBilling"
                      {...register("DefaultBilling")}
                    />
                    <div className="label-card-select">
                      <label htmlFor="DefaultBilling">
                      {t("UseAsDefaultBillingAddress")}
                      </label>
                    </div>
                  </div>
                )}
                {address[0]?.isDefaultDelivery !== 1 && (
                  <div className="payment-methode">
                    <input
                      type="checkbox"
                      name="DefaultDelivery"
                      id="DefaultDelivery"
                      {...register("DefaultDelivery")}
                    />
                    <div className="label-card-select">
                      <label htmlFor="DefaultDelivery">
                      {t("UseAsDefaultDeliveryAddress")}
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <div className="btn-checkout-container">
                <button type="submit">
                  <span>{t("save")}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModificationAddressUser;
