import axios from "axios";
import React, { useEffect, useState } from "react";
import PopUp from "./PopUp";
import { NavLink } from "react-router-dom";

const AdminCard = (props) => {
  let { idAdmin } = props;

  const [admin, setAdmin] = useState([]);
  const [togglePopup, setTogglePopup] = useState(false);
  const [rank, setRank] = useState("");

  useEffect(() => {
    if (admin.adminAccess === 1) {
      setRank("administrateur");
    } else {
      setRank("collaborateur");
    }
  }, [admin]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const adminResponse = await axios.get(
          `https://api.sweatteeshop.com/back/admin/data/${idAdmin}`
        );
        const adminData = Object.values(adminResponse.data);
        setAdmin(adminData[0]);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [idAdmin]);

  const deleteadmin = () => {
    const formatData = {
      idAdmin: idAdmin,
    };

    axios
      .post("https://api.sweatteeshop.com/back/admin/delete", formatData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        // console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ul className="order-card-back">
      <li>
        <h5>Identité</h5>
        <span>
          {admin?.lastName} {admin?.firstName}
        </span>
        <span>{rank}</span>
      </li>
      <li>
        <h5>Adresse</h5>
        {<span>{admin?.adress}</span>}
        {<span>{admin?.adressComp}</span>}
        {
          <span>
            {admin?.zip}
            {" - "}
            {admin?.city}
            {", "}
            {admin?.country}
          </span>
        }
      </li>
      <li>
        <h5>Contacts</h5>
        <span>{admin?.phone}</span>
        <span>{admin?.email}</span>
      </li>
      <li>
        <NavLink to={`/back/admin/edition/${idAdmin}`}>
          <button>Modifier</button>
        </NavLink>
        <button
          onClick={() => {
            setTogglePopup(true);
          }}
        >
          Supprimer
        </button>
      </li>
      <PopUp
        togglePopup={togglePopup}
        setTogglePopup={setTogglePopup}
        onSubmite={deleteadmin}
        messagePopup={
          "La suppression est irreversible, êtes-vous certain de vouloir supprimer cet administrateur ?"
        }
        comeFrom={"adminDelete"}
      />
    </ul>
  );
};

export default AdminCard;
