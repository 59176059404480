import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ParamContext } from "../contexts/ParamContexts";
import NavbarBack from "../components/NavbarBack";
import NavSection from "../components/NavSection";
import axios from "axios";

const Settings = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const { adminData } = useContext(ParamContext);

  useEffect(() => {
    if (adminData.adminAccess === 0) {
      navigate("/back/gestion-des-commandes");
    }
  }, [adminData, navigate]);

  const [toggleMessage, setToggleMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [domain, setDomain] = useState("");
  const [homeTitle, setHomeTitle] = useState("");
  const [homeSubTitle, setHomeSubTitle] = useState("");

  const [annonce1, setAnnonce1] = useState("");
  const [annonce2, setAnnonce2] = useState("");
  const [annonce3, setAnnonce3] = useState("");

  const [isDesplayBandeST, setIsDesplayBandeST] = useState("");
  const [isDesplayBandeND, setIsDesplayBandeND] = useState("");
  const [isDesplayBandeRD, setIsDesplayBandeRD] = useState("");

  const [nameCompany, setNameCompany] = useState("");
  const [statusCompany, setStatusCompany] = useState("");
  const [addressCompany, setAddressCompany] = useState("");
  const [zipCompany, setZipCompany] = useState("");
  const [cityCompany, setCityCompany] = useState("");
  const [countryCompany, setCountryCompany] = useState("");
  const [idCompany, setIdCompany] = useState("");
  const [capitalCompany, setCapitalCompany] = useState("");
  const [publisherCompany, setPublisherCompany] = useState("");
  const [vat, setVat] = useState("");
  const [rcs, setRcs] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [isdisplaypfopup, SetIsdisplaypfopup] = useState("");

  const [nameContact, setNameContact] = useState("");
  const [emailContact, setEmailContact] = useState("");
  const [addressContact, setAddressContact] = useState("");
  const [zipContact, setZipContact] = useState("");
  const [cityContact, setCityContact] = useState("");
  const [countryContact, setCountryContact] = useState("");

  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [tiktok, setTiktok] = useState("");

  const [formDataState, setFormDataState] = useState({
    domainName: domain,
    homeTitle: homeTitle,
    homeSubTitle: homeSubTitle,
    popupMessage: popupMessage,
    isdisplaypfopup: isdisplaypfopup,
    nameCompany: nameCompany,
    StatusCompany: statusCompany,
    addressCompany: addressCompany,
    zipCompany: zipCompany,
    cityCompany: cityCompany,
    countryCompany: countryCompany,
    idCompany: idCompany,
    rcs: rcs,
    vatCompany: vat,
    ShareCapital: capitalCompany,
    publisher: publisherCompany,
    nameContact: nameContact,
    addressContact: addressContact,
    zipContact: zipContact,
    cityContact: cityContact,
    countryContact: countryContact,
    emailContact: emailContact,
    Facebook: facebook,
    Instagram: instagram,
    Tiktok: tiktok,
    annonce1: annonce1,
    annonce2: annonce2,
    annonce3: annonce3,
    activeTextFR: isDesplayBandeST,
    activeTextND: isDesplayBandeND,
    activeTextRD: isDesplayBandeRD,
  });

  const resetFormDataState = () => {
    setFormDataState({
      domainName: domain || "",
      homeTitle: homeTitle || "",
      homeSubTitle: homeSubTitle || "",
      popupMessage: popupMessage || "",
      isdisplaypfopup: isdisplaypfopup || "",
      nameCompany: nameCompany || "",
      StatusCompany: statusCompany || "",
      addressCompany: addressCompany || "",
      zipCompany: zipCompany || "",
      cityCompany: cityCompany || "",
      countryCompany: countryCompany || "",
      idCompany: idCompany || "",
      rcs: rcs || "",
      vatCompany: vat || "",
      ShareCapital: capitalCompany || "",
      publisher: publisherCompany || "",
      nameContact: nameContact || "",
      addressContact: addressContact || "",
      zipContact: zipContact || "",
      cityContact: cityContact || "",
      countryContact: countryContact || "",
      emailContact: emailContact || "",
      Facebook: facebook || "",
      Instagram: instagram || "",
      Tiktok: tiktok || "",
      annonce1: annonce1 || "",
      annonce2: annonce2 || "",
      annonce3: annonce3 || "",
      activeTextFR: isDesplayBandeST || "",
      activeTextND: isDesplayBandeND || "",
      activeTextRD: isDesplayBandeRD || "",
    });
  };

  useEffect(() => {
    const getOrder = async () => {
      await axios
        .get(`https://api.sweatteeshop.com/back/settings/datas`)
        .then((res) => {
          const SettingssObj = Object.values(res.data);
          const settingsObj = Object.values(SettingssObj);
          settingsObj.forEach((setting) => {
            switch (setting.refSettings) {
              case "facebook":
                setFacebook(setting.settingValue);
                break;
              case "instagram":
                setInstagram(setting.settingValue);
                break;
              case "tiktok":
                setTiktok(setting.settingValue);
                break;
              case "name company":
                setNameCompany(setting.settingValue);
                break;
              case "status company":
                setStatusCompany(setting.settingValue);
                break;
              case "adress company":
                setAddressCompany(setting.settingValue);
                break;
              case "zip company":
                setZipCompany(setting.settingValue);
                break;
              case "city company":
                setCityCompany(setting.settingValue);
                break;
              case "country Company":
                setCountryCompany(setting.settingValue);
                break;
              case "id company":
                setIdCompany(setting.settingValue);
                break;
              case "share capital":
                setCapitalCompany(setting.settingValue);
                break;
              case "publisher":
                setPublisherCompany(setting.settingValue);
                break;
              case "name contact":
                setNameContact(setting.settingValue);
                break;
              case "email contact":
                setEmailContact(setting.settingValue);
                break;
              case "address contact":
                setAddressContact(setting.settingValue);
                break;
              case "zip contact":
                setZipContact(setting.settingValue);
                break;
              case "City contact":
                setCityContact(setting.settingValue);
                break;
              case "country contact":
                setCountryContact(setting.settingValue);
                break;
              case "website":
                setDomain(setting.settingValue);
                break;
              case "home title":
                setHomeTitle(setting.settingValue);
                break;
              case "home sub title":
                setHomeSubTitle(setting.settingValue);
                break;
              case "vat":
                setVat(setting.settingValue);
                break;
              case "rcs":
                setRcs(setting.settingValue);
                break;
              case "popupMessage":
                setPopupMessage(setting.settingValue);
                break;
              case "isdisplaypfopup":
                SetIsdisplaypfopup(setting.settingValue);
                break;
              case "bandeST":
                setAnnonce1(setting.settingValue);
                break;
              case "bandeND":
                setAnnonce2(setting.settingValue);
                break;
              case "bandeRD":
                setAnnonce3(setting.settingValue);
                break;
              case "isDesplayBandeST":
                setIsDesplayBandeST(setting.settingValue);
                break;
              case "isDesplayBandeND":
                setIsDesplayBandeND(setting.settingValue);
                break;
              case "isDesplayBandeRD":
                setIsDesplayBandeRD(setting.settingValue);
                break;
              default:
                break;
            }
          });
        });
    };
    getOrder();
  }, []);

  const handleChange = (value, name) => {
    setFormDataState((prevState) => ({ ...prevState, [name]: value }));
  };

  const EditeSettings = async () => {
    setIsLoading(true);

    const formatData = {
      domainName: formDataState.domainName,
      homeTitle: formDataState.homeTitle,
      homeSubTitle: formDataState.homeSubTitle,
      popupMessage: formDataState.popupMessage,
      isdisplaypfopup: formDataState.activePopup,
      nameCompany: formDataState.nameCompany,
      StatusCompany: formDataState.StatusCompany,
      addressCompany: formDataState.addressCompany,
      zipCompany: formDataState.zipCompany,
      cityCompany: formDataState.cityCompany,
      countryCompany: formDataState.countryCompany,
      idCompany: formDataState.idCompany,
      rcs: formDataState.rcs,
      vatCompany: formDataState.vatCompany,
      ShareCapital: formDataState.ShareCapital,
      publisher: formDataState.publisher,
      nameContact: formDataState.nameContact,
      addressContact: formDataState.addressContact,
      zipContact: formDataState.zipContact,
      cityContact: formDataState.cityContact,
      countryContact: formDataState.countryContact,
      emailContact: formDataState.emailContact,
      Facebook: formDataState.Facebook,
      Instagram: formDataState.Instagram,
      Tiktok: formDataState.Tiktok,
      annonce1: formDataState.annonce1,
      annonce2: formDataState.annonce2,
      annonce3: formDataState.annonce3,
      activeTextFR: formDataState.activeTextFR,
      activeTextND: formDataState.activeTextND,
      activeTextRD: formDataState.activeTextRD,
      thresholdAmount: "",
    };

    try {
      const response = await axios.post(
        "https://api.sweatteeshop.com/back/settings/edite",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.log(response);
      //   resetFormDataState();
      setToggleMessage(response.data.message);
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavSection />
        <div className="admin-content-page">
          <div className="content-edit-back">
            <div className="checkout-form-container">
              <h3>Parametres du site</h3>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="domainName">Nom de domaine</label>
                  <input
                    name="domainName"
                    id="domainName"
                    defaultValue={formDataState.domainName || domain}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="homeTitle">Titre acceuil</label>
                  <input
                    name="homeTitle"
                    id="homeTitle"
                    defaultValue={formDataState.homeTitle || homeTitle}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="homeSubTitle">Titre sous titre acceuil</label>
                  <input
                    name="homeSubTitle"
                    id="homeSubTitle"
                    defaultValue={formDataState.homeSubTitle || homeSubTitle}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="selectCase-checkout">
                  <label>Afficher l'information</label>
                  <select
                    name="activePopup"
                    className="ItemSelect"
                    value={formDataState.activePopup || isdisplaypfopup}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  >
                    <option value="true">Oui</option>
                    <option value="false">Non</option>
                  </select>
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="popupMessage">Contenu du pop-up</label>
                  <input
                    name="popupMessage"
                    id="popupMessage"
                    defaultValue={formDataState.popupMessage || popupMessage}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="annonce1">Bande annonce 1</label>
                  <input
                    name="annonce1"
                    id="annonce1"
                    defaultValue={formDataState.annonce1 || annonce1}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
                <div className="selectCase-checkout">
                  <select
                    name="activeTextFR"
                    className="ItemSelect"
                    value={formDataState.activeTextFR || isDesplayBandeST}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  >
                    <option value="true">Afficher</option>
                    <option value="false">Ne pas afficher</option>
                  </select>
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="annonce2">Bande annonce 2</label>
                  <input
                    name="annonce2"
                    id="annonce2"
                    defaultValue={formDataState.annonce2 || annonce2}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
                <div className="selectCase-checkout">
                  <select
                    name="activeTextND"
                    className="ItemSelect"
                    value={formDataState.activeTextND || isDesplayBandeND}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  >
                    <option value="true">Afficher</option>
                    <option value="false">Ne pas afficher</option>
                  </select>
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="annonce3">Bande annonce 3</label>
                  <input
                    name="annonce3"
                    id="annonce3"
                    defaultValue={formDataState.annonce3 || annonce3}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
                <div className="selectCase-checkout">
                  <select
                    name="activeTextRD"
                    className="ItemSelect"
                    value={formDataState.activeTextRD || isDesplayBandeRD}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  >
                    <option value="true">Afficher</option>
                    <option value="false">Ne pas afficher</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="checkout-form-container">
              <h3>Editeur du site</h3>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="nameCompany">Nom</label>
                  <input
                    name="nameCompany"
                    id="nameCompany"
                    defaultValue={formDataState.nameCompany || nameCompany}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="StatusCompany">Status</label>
                  <input
                    name="StatusCompany"
                    id="StatusCompany"
                    defaultValue={formDataState.StatusCompany || statusCompany}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="addressCompany ">Adresse</label>
                  <input
                    name="addressCompany"
                    id="addressCompany"
                    defaultValue={
                      formDataState.addressCompany || addressCompany
                    }
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="zipCompany">Code postal</label>
                  <input
                    name="zipCompany"
                    id="zipCompany"
                    defaultValue={formDataState.zipCompany || zipCompany}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="cityCompany">Ville</label>
                  <input
                    name="cityCompany"
                    id="cityCompany"
                    defaultValue={formDataState.cityCompany || cityCompany}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="countryCompany">Pays</label>
                  <input
                    name="countryCompany"
                    id="countryCompany"
                    defaultValue={
                      formDataState.countryCompany || countryCompany
                    }
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="idCompany">Siret</label>
                  <input
                    name="idCompany"
                    id="idCompany"
                    defaultValue={formDataState.idCompany || idCompany}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="rcs">RCS</label>
                  <input
                    name="rcs"
                    id="rcs"
                    defaultValue={formDataState.rcs || rcs}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="vatCompany">Numero de TVA</label>
                  <input
                    name="vatCompany"
                    id="vatCompany"
                    defaultValue={formDataState.vatCompany || vat}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="ShareCapital">Capital social</label>
                  <input
                    name="ShareCapital"
                    id="ShareCapital"
                    defaultValue={formDataState.ShareCapital || capitalCompany}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="publisher">
                    Responsable de la publication
                  </label>
                  <input
                    name="publisher"
                    id="publisher"
                    defaultValue={formDataState.publisher || publisherCompany}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="checkout-form-container">
              <h3>Contacts</h3>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="nameContact">Nom</label>
                  <input
                    name="nameContact"
                    id="nameContact"
                    defaultValue={formDataState.nameContact || nameContact}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="addressContact">Adresse</label>
                  <input
                    name="addressContact"
                    id="addressContact"
                    defaultValue={
                      formDataState.addressContact || addressContact
                    }
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="zipContact">Code postal</label>
                  <input
                    name="zipContact"
                    id="zipContact"
                    defaultValue={formDataState.zipContact || zipContact}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="cityContact">Ville</label>
                  <input
                    name="cityContact"
                    id="cityContact"
                    defaultValue={formDataState.cityContact || cityContact}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="countryContact">Pays</label>
                  <input
                    name="countryContact"
                    id="countryContact"
                    defaultValue={
                      formDataState.countryContact || countryContact
                    }
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="emailContact">Email</label>
                  <input
                    name="emailContact"
                    id="emailContact"
                    defaultValue={formDataState.emailContact || emailContact}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="checkout-form-container">
              <h3>Reseaux sociaux</h3>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="Facebook">Facebook</label>
                  <input
                    name="Facebook"
                    id="Facebook"
                    defaultValue={formDataState.Facebook || facebook}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="Instagram">Instagram</label>
                  <input
                    name="Instagram"
                    id="Instagram"
                    defaultValue={formDataState.Instagram || instagram}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-checkout-form-nd">
                <div className="input-devis-container">
                  <label htmlFor="Tiktok">Tiktok</label>
                  <input
                    name="Tiktok"
                    id="Tiktok"
                    defaultValue={formDataState.Tiktok || tiktok}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="btn-checkout-container">
              <button
                onClick={() => {
                  resetFormDataState();
                }}
              >
                annuler
              </button>
              <button onClick={EditeSettings} disabled={isLoading}>
                {isLoading ? "Chargement..." : "Valider"}
              </button>
            </div>
            <div>{toggleMessage}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
