import React, { useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ParamContext } from "../contexts/ParamContexts";
import CheckoutFormStripe from "../components/CheckoutFormStripe";
import { useNavigate } from "react-router-dom";

const PaymentPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    discount,
    totalPrice,
    userStatue,
    discountAmount,
    pricesProduct,
    shippingUser,
    subtotalGuessCart,
    deliveryPriceGuess,
    totalCartGuess,
    dataOrder,
    usersDatas,
    cartItems,
    totalQuantity,
  } = useContext(ParamContext);

  //

  useEffect(() => {
    const isCartFull = Object.values(cartItems).some(
      (cartItem) => cartItem.quantity > 0
    );

    const timeout = setTimeout(() => {
      if (!(isCartFull || totalQuantity > 0)) {
        navigate("/");
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [cartItems, totalQuantity, navigate]);

  //

  const stripePromise = loadStripe(
    "pk_live_51OrlbRCNSBOGWgqB0G6u8vz85ixVPz29vWcQ3r8zXukgcjiMjBYaK5McelX8rBO07m8pxA7s9d2vlJWZ5zHCEMCN00WCLniGYV"
  );

  const [clientSecret, setClientSecret] = useState("");

  const fetchData = async () => {
    sessionStorage.setItem("IntentPayment", true);
    try {
      const requestBody = {
        order_amount: userStatue ? totalPrice : totalCartGuess,
        product_prices: userStatue ? pricesProduct : subtotalGuessCart,
        shipping_cost: userStatue ? shippingUser : deliveryPriceGuess,
        discount_amount: userStatue ? discountAmount : discount,
        email: userStatue ? usersDatas.email : dataOrder.emailGuess,
      };

      // console.log("Request body:", requestBody);
      // http://localhost/sts_server/models/StripeContext.php

      const response = await fetch(
        "https://api.sweatteeshop.com/StripeContext.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // console.log(response.json());
      const data = await response.json();
      setClientSecret(data.clientSecret);
      sessionStorage.setItem("IntentPayment", false);
    } catch (error) {
      console.error("Error fetching data:", error);
      sessionStorage.setItem("IntentPayment", false);
    }
  };

  useEffect(() => {
    const IntentPayment = sessionStorage.getItem("IntentPayment");
    if (
      (IntentPayment === "false" || IntentPayment === null) &&
      window.location.pathname === "/checkout/payment"
    ) {
      fetchData();
    } else {
      return;
    }
  }, []);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="checkout-form-Page">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutFormStripe />
        </Elements>
      )}
    </div>
  );
};

export default PaymentPage;
