import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ParamContext } from "../contexts/ParamContexts";

const NavBarUserAccount = () => {
  const { t } = useTranslation();
  const { handleLogoutUser } = useContext(ParamContext);
  return (
    <div className="navBar-user-account">
      <ul>
        <li>{t("MyAccount")}</li>
        <div className="separator"></div>
        <li>
          <NavLink to="/commandes">{t("MyOrders")}</NavLink>
        </li>
        <li>
          <NavLink to="/factures">{t("MyBills")}</NavLink>
        </li>
        <li>
          <NavLink to="/address-utilisateur">{t("Addressbook")}</NavLink>
        </li>
        <li>
          <NavLink to="/parametres-du-compte">{t("Settings")}</NavLink>
        </li>
        <li>
          <button onClick={() => handleLogoutUser()}>{t("Logout")}</button>
        </li>
      </ul>
    </div>
  );
};

export default NavBarUserAccount;
