import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ParamContext } from "../contexts/ParamContexts";
import { Helmet } from "react-helmet";

const CGV = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  const baseUrl = "https://api.sweatteeshop.com/public/images/";

  const {
    nameCompany,
    statusCompany,
    emailContact,
    imgOpenTags,
    webSide,
    addressCompany,
    zipCompany,
    cityCompany,
    countryCompany,
    idCompany,
    capitalCompany,
    rcs,
  } = useContext(ParamContext);

  return (
    <div className="polities-pages">
      <Helmet>
        <title>{`${nameCompany} | Conditions Generales de vente`}</title>
        <meta
          property="og:title"
          content={`${nameCompany} | Conditions Generales de vente`}
        />
        <meta
          name="description"
          content="Consultez nos conditions générales de vente pour découvrir nos termes, modalités et plus encore."
        />
        <meta
          property="og:description"
          content="Consultez nos conditions générales de vente pour découvrir nos termes, modalités et plus encore."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta
          property="og:url"
          content="https://sweatteeshop.com/termes-et-conditions"
        />
        <meta property="og:site_name" content="sweatteeshop-officiel" />
        <link
          rel="canonical"
          href={`https://sweatteeshop.com/termes-et-conditions`}
        />
      </Helmet>
      <h1>{t("CGV")}</h1>
      <div className="head-policies-pages">
        <div className="section-policies">
          <p>
            Les présentes conditions générales de vente s'appliquent de plein
            droit à toutes les ventes de produits (ci-après « Produits »)
            conclues entre :
          </p>
        </div>
        <div className="section-policies">
          <p>
            La société {nameCompany}, Société {statusCompany}, au capital de{" "}
            {capitalCompany} €, immatriculée au a la chambre des metier et de
            l'artisanat de {rcs} sous le numéro {idCompany}, dont le siège
            social est situé au {addressCompany}, {zipCompany} {cityCompany} -{" "}
            {countryCompany}. Ci-après désigné "Le Vendeur",
          </p>
        </div>
        <div className="section-policies">
          <p>
            Et les personnes physiques non commerçantes ayant la capacité de
            contracter souhaitant effectuer un achat d'un ou plusieurs articles,
            commercialisés sur le site internet accessible depuis l'adresse URL{" "}
            {webSide}. Ci-après désigné "Le Client",
          </p>
        </div>
      </div>
      <div className="body-policies-page">
        <div className="section-policies">
          <h3>ARTICLE 1 - ACCEPTATION - OPPOSABILITE</h3>
          <p>
            Conformément aux dispositions de l'article L 113-3 du Code de la
            consommation, les présentes conditions générales de vente sont
            portées à la connaissance du Client lors de la passation de la
            commande, elles figurent sur le site internet du Vendeur accessible
            depuis l'adresse URL
            {webSide} (ci-après « le Site »). En passant commande, le Client les
            agrée et reconnaît en avoir parfaite connaissance et renonce de ce
            fait à se prévaloir de tout document contradictoire. Aucun autre
            document que les présentes ne pourra créer d’obligations à la charge
            des parties ou déroger aux présentes à moins de faire l’objet d’un
            écrit signé par les parties. Les CGV sont accessibles à tout moment
            sur le Site et prévaudront, le cas échéant, sur tout autre version
            antérieure ou tout autre document contradictoire. Le Client dispose
            de la faculté de demander à ce que les CGV lui soient envoyées par
            le Vendeur par courrier électronique. Il peut également les
            sauvegarder, les éditer ou les copier, étant précisé que la
            sauvegarde, l’édition ou la copie de ce document relèvent de sa
            seule responsabilité, ces CGV pouvant être susceptibles de
            modifications. Le Vendeur peut modifier à tout instant ses
            conditions générales de vente, publiées sur son site internet
            {webSide}. Les modifications de ces CGV sont opposables aux Clients
            à compter de leur mise en ligne et s'appliquent aux transactions
            conclues postérieurement à leur mise en ligne.  
          </p>
        </div>
        <div className="section-policies">
          <h3>ARTICLE 2 - INFORMATIONS PRECONTRACTUELLES</h3>
          <p>
            Le Client reconnaît avoir eu communication, préalablement à la
            passation de sa commande et à la conclusion du contrat, d’une
            manière lisible et compréhensible, des présentes CGV et de toutes
            les informations suivantes : les caractéristiques essentielles des
            Produits ; le prix du Produit et des frais de transport ; le délai
            de livraison ; les informations relatives à l’identité du Vendeur, à
            ses coordonnées postales et électroniques et à ses activités ; les
            informations relatives au droit de rétractation, aux garanties
            légales ; la possibilité de recourir à un médiateur de la
            consommation.
          </p>
        </div>
        <div className="section-policies">
          <h3>ARTICLE 3 - PRODUITS</h3>
          <p>
            Les produits vendus par le Vendeur sont ceux figurant sur le Site au
            jour de sa consultation par le Client.   Les offres de Produits
            proposées sur le Site ne sont valables que dans la limite des stocks
            disponibles. La disponibilité peut varier dans une même journée en
            fonction du niveau des ventes enregistrées par le Vendeur. Le
            Vendeur effectue sur le Site une mise à jour très fréquente des
            disponibilités, mais il ne pourra être tenu pour responsable si le
            stock n'est pas identique à celui indiqué sur le Site. En cas
            d’indisponibilité du Produit après passation de la commande par le
            Client, le Vendeur s’engage à l’en informer par courrier
            électronique dans les huit (8) jours à compter de la date de la
            commande et à indiquer au Client le délai d’attente pour la
            réception du Produit. Si le Client le désire, il pourra demander
            l’annulation de la commande (remboursement par virement bancaire
            dans un délai de quatorze (14) jours) ou l’échange du Produit non
            disponible contre un autre Produit en contactant le Vendeur par
            email aux coordonnées indiquées à l’article 12. Chaque produit fait
            l'objet d'une description précise, comprenant la photographie, le
            prix, les tailles, les couleurs disponibles et les caractéristiques
            principales. Les photographies des produits peuvent comprendre des
            différences minimes entre le produit et l'illustration, selon la
            résolution des couleurs de l'écran de l'ordinateur ou smartphone du
            Client. Le Vendeur s'engage à livrer des produits conformes aux
            prescriptions légales et réglementaires de sécurité et de santé.
          </p>
        </div>
        <div className="section-policies">
          <h3>ARTICLE 4 - COMMANDE</h3>
          <h4>4.1 Navigation à l'intérieur du Site.</h4>
          <p>
            Le Client peut prendre connaissance des différents Produits proposés
            à la vente par le Vendeur sur son Site et naviguer librement sur les
            différentes pages du Site, sans pour autant être engagé au titre
            d'une commande.
          </p>
          <h4>4.2 Enregistrement d'une commande.</h4>
          <p>
            Si le Client souhaite passer commande, il choisira les différents
            Produits auxquels il porte un intérêt, et manifestera ledit intérêt
            en cliquant sur la case « Ajouter au panier». Sur le Site, à tout
            moment, le Client pourra : - obtenir le détail des Produits qu'il a
            sélectionnés, en cliquant sur « Mon panier », - poursuivre sa
            sélection de Produits, - terminer sa sélection de Produits et
            commander ces Produits en cliquant sur « Valider mon panier». Pour
            commander les Produits qu'il a choisis, après avoir cliqué sur «
            Valider mon panier », le Client devra s'identifier, soit en
            saisissant son adresse email et son mot de passe confidentiel, s'il
            a déjà créé son compte, soit en renseignant une adresse email de
            conatact. Dans cette dernière hypothèse, le Client devra remplir
            avec exactitude les formulaire mis à sa disposition, sur lequel il
            devra indiquer les informations nécessaires à son la livraison et la
            facturation. Le Client est informé et accepte que la saisie de son
            adresse e-mail et de son mot de passe ou de sont email de contact
            dans le second cas vaut preuve de son identité. Une fois le Client
            identifié, il devra valider l'adresse de livraison. Un bon de
            commande apparaîtra à l'écran, détaillant notamment : les nature,
            quantité et prix des Produits retenus par le Client, ainsi que le
            montant total de la commande, les coordonnées du Client, l'adresse
            de livraison des Produits. Le Client pourra corriger les éventuelles
            erreurs avant de confirmer sa commande. Si tel est le cas, un
            nouveau bon de commande sera automatiquement édité.  
          </p>
          <h4>4.3 Validation définitive de la commande.</h4>
          <p>
            Après avoir pris connaissance du bon de commande, et une fois que
            l'ensemble des informations demandées aura été complété par le
            Client, celui ci sera invité a cliquer sur « Payer maintenant » a
            savoir que passer cette etape le Client reconnait avoir lu et
            accepté sans reserve les presente CGV. Le Client pourra choisir le
            mode de paiement qu'il souhaite, parmi ceux proposés par le Vendeur
            et procédera au paiement des Produits dans les conditions de
            l’article 6. Ce deuxième clic constitue une signature électronique.
            Cette signature a valeur et engage le Client au même titre qu’une
            signature manuscrite. Le bon de commande sera enregistré sur les
            registres informatiques du Vendeur, eux-mêmes conservés sur un
            support fiable et durable et sera considéré comme preuve de
            l’engagement du Client.
          </p>
          <h4>4.4 Récapitulatif de la commande.</h4>
          <p>
            Lorsqu'il aura validé son mode de paiement sur le Site, un
            récapitulatif de la commande du Client s'affichera et mentionnera
            notamment le numéro de la transaction. La vente ne sera considérée
            comme définitive qu'après l’affichage du récapitulatif de la
            commande par le Vendeur, lequel vaut accusé de réception de la
            commande. Il sera alors envoyé au Client un courriel récapitulant la
            commande lors de son enregistrement (ci-après « le Contrat »).  
          </p>
          <p>
            En tout état de cause, le Vendeur se réserve le droit de refuser
            toute commande ou toute livraison en cas (i) de litige existant avec
            le Client, (ii) de non-paiement total ou partiel d’une commande
            précédente par le Client, (iii) de refus d’autorisation de paiement
            par carte bancaire des organismes bancaires. La responsabilité du
            Vendeur ne pourra alors être engagée.  
          </p>
          <h4>4.5 Suivi de la commande.</h4>
          <p>
            Le Client peut à tout moment consulter l'état de sa commande en
            consultant la rubrique « Mes commande ». Ce suivi permet au Client
            de connaître l'état de traitement de sa commande, mais également
            l'état de livraison de ses colis. Le Client peut à tout moment
            contacter le service client du Vendeur afin d'être informé du suivi
            de sa commande aux coordonnées indiquées à l’article 12.   Toute
            commande ne pourra être modifiée dès lors qu'elle acquiert le statut
            « en traitement ». La Société {nameCompany} {statusCompany} dispose
            de la faculté d'annuler ou de refuser toute commande d'un Client
            avec lequel il existerait un litige relatif au paiement d'une
            précédente commande. La Société
            {nameCompany} {statusCompany} pourra émettre un avoir au client en
            cas d'indisponibilité d'un produit suite à un retour pour
            indisponibilité de taille. Le délai de validité de l'avoir est d'un
            (1) an, pour toute nouvelle commande effectuée sur le site internet
            accessible depuis l'adresse URL {webSide}. Cependant, le Client
            dispose de la faculté de demander le remboursement de l'avoir,
            pendant toute la durée de validité de celui-ci.
            {nameCompany} pourra être en droit de réclamer auprès du client un
            justificatif d'identité et / ou de domicile pour s'assurer de
            l'identité du détenteur de la carte bancaire utilisée pour le
            paiement.  
          </p>
        </div>
        <div className="section-policies">
          <h3>ARTICLE 5 – PRIX</h3>
          <p>
            Les prix indiqués sur les fiches produits sont exprimés en Euros, et
            s'entendent toutes taxes comprises, hors frais de manutention et
            frais de port qui sont facturés, le cas échéant, en supplément sur
            la base du tarif applicable au jour de la commande et qui sont
            communiqués au Client avant la passation de la commande. La taxe sur
            la valeur ajoutée applicable est celle en vigueur, en France
            métropolitaine, au jour de la commande. Le Vendeur se réserve la
            faculté de modifier ses prix à sa convenance, étant précisé que le
            prix indiqué dans le bon de commande édité par le Vendeur est le
            prix définitif et inclut la TVA et les frais de port. Les produits
            commandés sont facturés aux tarifs en vigueur lors de
            l'enregistrement de la commande, sous réserve de la disponibilité
            des produits. Pour certains Produits, des frais de port
            supplémentaires pourront s’ajouter aux frais de ports forfaitaires,
            dont le montant sera dûment précisé dans l'offre de vente. Les prix
            des Produits pour les DOM-TOM, Suisse, et autres pays hors UE sont
            indiqués Hors Taxes. Les commandes effectuées sur le site internet
            {webSide}, destinées hors France métropolitaine pourront être
            soumises à des taxes éventuelles et des droits de douane. Le Client
            est tenu de s'informer des éventuels droits de douane et frais
            éventuels applicables à sa situation en cas de commande. Le Client
            est seul responsable de la vérification des possibilités
            d'importation des Produits commandés au regard du droit du pays de
            livraison. Le Vendeur n'est pas tenu de vérifier et d'informer le
            Client des droits de douane et taxes applicables ni des lois et
            réglementations du pays où sont livrés les Produits. Ces droits de
            douane et frais éventuels sont à la charge exclusive du Client.
          </p>
        </div>
        <div className="section-policies">
          <h3>ARTICLE 6 – MODALITES DE PAIEMENT</h3>
          <p>
            6.1 Lors de l'acceptation de la commande par le Vendeur, le Client
            s'engage à verser au Vendeur, l'intégralité du paiement.
          </p>
          <p>A défaut, la commande ne sera pas traitée.</p>
          <p>6.2 Le règlement de la commande s'effectue soit :</p>
          <p>* par Carte Bancaire</p>
          <p>* par Paypal</p>
          <p>
            Les transactions effectuées sur le site internet
            {webSide} sont protégées par le système universel de cryptage SSL
            (Secure Socket Layer).
          </p>
          <p>
            Le Client est informé que le Vendeur sous-traite la gestion des
            paiements au prestataire externe Stripe. Les données bancaires du
            Client sont uniquement connues de « Stripe » et ne transitent pas
            par le serveur web du Site. En conséquence, le Client reconnaît qu'à
            aucun moment ses données bancaires ne transiteront sur le système
            informatique du Vendeur, qui ne pourra donc voir sa responsabilité
            engagée en cas de soustraction frauduleuse des données bancaires du
            Client.
          </p>
          <p>
            {" "}
              6.3. Le Vendeur ne sera pas tenu de procéder à la livraison des
            Produits commandés par le Client si celui-ci ne lui en paye pas le
            prix en totalité dans les conditions ci-dessus indiquées. Le
            paiement lui-même ne sera considéré comme exécuté qu'après
            encaissement effectif des fonds par le Vendeur.
          </p>
          <p>
            {" "}
              6.4. Une facture est établie par le Vendeur et remise au Client
            lors de la livraison des Produits commandés. Une facture détaillée
            imprimable sera également disponible sur le Site dans la rubrique «
            Mon profil ».
          </p>{" "}
          <p>
              6.5. Toute somme non payée à sa date d’exigibilité produira de
            plein droit des intérêts de retard correspondant au triple du taux
            d’intérêt légal. Ces intérêts seront dus jusqu’au jour du règlement
            de la somme exigible, intérêts compris.
          </p>
        </div>
        <div className="section-policies">
          <h3>ARTICLE 7 – MODALITES DE LIVRAISON</h3>
          <p>
            La livraison s’entend du transfert au Client de la possession
            physique ou du contrôle des Produits. Elle n’est possible qu’après
            confirmation du paiement par l’organisme bancaire du Vendeur. Les
            produits commandés sont envoyés à l'adresse de livraison indiquée
            par le Client lors de sa commande, sous réserve que celle-ci soit
            comprise dans les zones de livraisons autorisées, précisées dans
            l'offre de vente. En cas d'erreur ou omission du Client dans la
            communication de l'adresse de livraison, le Vendeur ne saurait être
            tenu pour responsable de l'impossibilité de livrer les produits
            commandés. Le Vendeur s'engage à livrer les produits dans un délai
            maximal de sept (7) jours, à compter de la réception du paiement.  
            Sauf cas particulier ou indisponibilité d'un ou plusieurs Produits,
            les Produits commandés seront livrés en une seule fois.
          </p>
          <p>
            Le Client est informé et accepte que le Vendeur puisse confier les
            opérations de livraison aux tiers de son choix.   Si au moment de la
            livraison, l’emballage d’origine est abîmé, déchiré, ouvert, le
            Client doit alors vérifier l’état des Produits. S’ils ont été
            endommagés, le Client doit refuser le colis et noter une réserve sur
            le bordereau de livraison (« colis refusé car ouvert ou endommagé
            »). Le Client doit indiquer sur le bon de livraison et sous forme de
            réserves manuscrites accompagnées de sa signature toute anomalie
            concernant la livraison (Produit manquant par rapport au bon de
            livraison, colis endommagé, Produits cassés, etc.).
          </p>
          <p>
            Cette vérification est considérée comme effectuée dès lors que le
            Client, ou une personne autorisée par lui, a signé le bon de
            livraison. Les hypothèses de retour de colis par le transporteur
            sont à la charge exclusive du Client. La Société {nameCompany}{" "}
            {statusCompany}
            contactera par mail le client, afin que celui-ci communique son
            adresse complète, ainsi qu'un virement pour les frais d'envoi de sa
            commande d'un montant de : Six Euros (6,00 Euros) pour une
            expédition du produit par So Colissimo Domicile (livraison à
            domicile),_ Cinq Euros (5,00 Euros) pour une expédition en relais
            Colis (livraison dans des commerces de proximité).Sans réponse du
            Client sous un (1) mois à compter de l'envoi du mail, la Société{" "}
            {nameCompany} {statusCompany} procédera à la remise en vente des
            produits commandés.Le Client bénéficiera d'un avoir d'un montant
            correspondant au montant de la commande, hors frais de port, ou
            pourra demander le remboursement de son avoir.
          </p>
        </div>
        <div className="section-policies">
          <h3>ARTICLE 8 – DROIT DE RETRACTATION</h3>
          <p>
            8.1 Le Client dispose de la faculté de se rétracter du présent
            Contrat sans motif dans un délai de quatorze (14) jours francs, à
            compter du jour où le Client lui-même ou un tiers autre que le
            transporteur prend physiquement possession des Produits.
          </p>
          <p>
            8.2 Notification du droit de rétractation. Le Client doit notifier
            sa décision de rétractation au moyen d’une déclaration dénuée
            d’ambiguïté en utilisant un support durable (par exemple, lettre
            envoyée par la poste ou courrier électronique  à l'adresse
            suivante :
            {emailContact}).Le Client peut utiliser le formulaire de
            rétractation suivant ou toute autre déclaration dénuée d'ambiguïté :
          </p>
          <p>
            "(Veuillez compléter et renvoyer le présent formulaire uniquement si
            vous souhaitez vous rétracter de la vente).A l'attention de la
            société
            {nameCompany} {statusCompany}, {addressCompany}, {zipCompany}{" "}
            {cityCompany} - {countryCompany}.
          </p>
          <p>
            Je soussigné (nom, prénom, adresse) vous notifie par la présente ma
            rétractation du contrat portant sur la vente du bien (préciser la
            désignation), commandé le (préciser la date) et reçu le (préciser la
            date)Signature et date ".
          </p>
          <p>
            Le client recevra sans délai un accusé de réception de la
            rétractation.Pour que ce délai de rétractation soit respecté, le
            Client doit transmettre sa communication relative à l'exercice du
            droit de rétractation avant l'expiration du délai de rétractation.
          </p>
          <p>8.3 Effet de la rétractation.</p>
          <p>
            En cas de rétractation, le Vendeur remboursera au Client tous les
            paiements reçus du Client, y compris les frais de livraison initiaux
            sans retard excessif et, en tout état de cause, au plus tard
            quatorze (14) jours à compter du jour où le Vendeur est informé de
            la décision de rétractation du Client. Le Vendeur procèdera au
            remboursement en utilisant le même moyen de paiement que celui que
            le Client aura utilisé pour la transaction initiale, sauf si le
            Client convient expressément d’un moyen différent.
          </p>
          <p>
            Ce remboursement n’occasionnera pas de frais pour le Client. Le
            Vendeur se réserve le droit de différer le remboursement jusqu’à ce
            qu’il ait reçu les Produits ou jusqu’à ce que le Client ait fourni
            une preuve d’expédition des Produits, la date retenue étant celle du
            premier de ces faits.
          </p>
          <p>8.4 Modalités de retour.</p>
          <p>
            Les produits devront être retournés, sans retard excessif et , en
            tout état de cause, au plus tard quatorze (14) jours après la
            communication de la décision de rétractation. Ce délai est réputé
            respecté si le Client renvoie les Produits avant l'expiration du
            délai de quatorze (14) jours. En tout état de cause, les produits
            devront être retournés à l'état neuf, dans leurs emballages
            d'origines et accompagnés de la facture.
          </p>
          <p>Les produits doivent être retournés à l'adresse suivante :</p>
          <p>
            {nameCompany} {statusCompany}, {addressCompany}, {zipCompany}{" "}
            {cityCompany} - {countryCompany}.
          </p>
          <p>
            La responsabilité du Client pourra être engagée en cas de
            dépréciation des Produits résultant de manipulations autres que
            celles nécessaires pour établir la nature, les caractéristiques et
            le bon fonctionnement de ces biens, à savoir lorsque les articles
            sont retournés incomplets, abîmés, endommagés, utilisés ou salis.
            Dans cette hypothèse, le Vendeur se réserve la possibilité d’engager
            la responsabilité du Client et de demander le paiement d’une
            indemnité compensatrice proportionnée par rapport au prix d’achat
            desdits Produits. En cas de rétractation, les frais résultant de
            l'acheminement des produits en retour resteront à la charge
            exclusive du Client.
          </p>
          <p>
            En vertu de l’article L. 221-28 du Code de la consommation, le droit
            de rétractation est exclu pour certains Produits, à savoir :
          </p>
          <p>
            Les produits qui ont été descellés par le Client et qui ne peuvent
            être renvoyés pour des raisons d’hygiène ou de protection de la
            santé. Par exemple et sans que cette liste ne soit exhaustive :
            sous-vêtements et produits d'entretien (boxers, caleçons, underwear,
            chaussettes, brassières, maillots de bain, spray et kits entretien),
            etc ; - les produits confectionnés selon les spécifications du
            Client, ou nettement personnalisés, par exemple les pochettes cadeau
            / emballages cadeau.
          </p>
        </div>
        <div className="section-policies">
          <h3>ARTICLE 9 – GARANTIES</h3>
          <p>
            Les Produits fournis par le Vendeur bénéficient, conformément aux
            dispositions légales, de  la garantie légale de conformité dans les
            conditions des articles L.217-4 et suivants du Code de la
            consommation et de la garantie contre les vices cachés provenant
            d'un défaut de matière, de conception ou de fabrication affectant
            les Produits livrés et les rendant impropres à l'utilisation, dans
            les conditions prévues aux articles 1641 et suivants du Code civil.
          </p>
          <h4>9.2. Garantie légale de conformité</h4>
          <p>9.2.1. Article L217-4 du Code de la Consommation :</p>
          <p>
            le vendeur est tenu de livrer un bien conforme au contrat et répond
            des défauts de conformité existant lors de la délivrance. Il répond
            également des défauts de conformité résultant de l'emballage, des
            instructions de montage ou de l'installation lorsque celle-ci a été
            mise à sa charge par le contrat ou a été réalisée sous sa
            responsabilité.
          </p>
          <p>9.2.2. Article L217-5 du Code de la Consommation :</p>
          <p>
            le bien est conforme au contrat : 1° S'il est propre à l'usage
            habituellement attendu d'un bien semblable et, le cas échéant : -
            s'il correspond à la description donnée par le vendeur et possède
            les qualités que celui-ci a présentées à l'acheteur sous forme
            d'échantillon ou de modèle ; - s'il présente les qualités qu'un
            acheteur peut légitimement attendre eu égard aux déclarations
            publiques faites par le vendeur, par le producteur ou par son
            représentant, notamment dans la publicité ou l'étiquetage ; 2° Ou
            s'il présente les caractéristiques définies d'un commun accord par
            les parties ou est propre à tout usage spécial recherché par
            l'acheteur, porté à la connaissance du vendeur et que ce dernier a
            accepté.
          </p>
          <h4>9.3. Garantie des vices cachés</h4>
          <p>9.3.1. Article 1641 du Code Civil :</p>
          <p>
            le vendeur est tenu de la garantie à raison des défauts cachés de la
            chose vendue qui la rendent impropre à l'usage auquel on la destine,
            ou qui diminuent tellement cet usage que l'acheteur ne l'aurait pas
            acquise, ou n'en aurait donné qu'un moindre prix, s'il les avait
            connus.
          </p>
          <p>9.3.2. Article 1648 al 1er du Code Civil :</p>
          <p>
            l'action résultant des vices rédhibitoires doit être intentée par
            l'acquéreur dans un délai de deux ans à compter de la découverte du
            vice. Dans le cas prévu par l’article 1642-1, l'action doit être
            introduite, à peine de forclusion, dans l'année qui suit la date à
            laquelle le vendeur peut être déchargé des vices ou des défauts de
            conformité apparents.
          </p>
          <h4>9.4. Mise en œuvre</h4>
          <p>
            9.4.1. Toute demande de garantie légale au titre de la garantie de
            conformité ou des vices cachés doit être formulée auprès du Vendeur
            (Adresse : Société {nameCompany} {statusCompany}, {addressCompany},{" "}
            {zipCompany} {cityCompany} - {countryCompany}/ courriel :{" "}
            {emailContact})  en qualité de garant de la conformité des Produits
            au contrat.
          </p>
          <p>
            9.4.2. Le produit vendu au Client est conforme, lorsqu’il respecte
            les critères prévus au contrat, lorsqu’il est propre à l’usage
            habituellement attendu d’un tel bien, et lorsqu’il présente les
            qualités décrites avant la conclusion du contrat. Dans le cas
            particulier de la vente de vêtements, chaussures et accessoires de
            mode, ne constitue pas une non-conformité l’usure normale d’un
            produit consécutive à son utilisation et à son entretien par le
            Client. Il appartient au client de rapporter la preuve de la
            non-conformité qu’il invoque, en communiquant par écrit au vendeur
            tout élément d’appréciation utile.
          </p>
          <p>
            Conformément aux dispositions de l’article L. 217-3 du Code de la
            consommation, le vendeur répond des éventuels défauts de conformité
            existant au moment de la délivrance du bien, qui apparaissent dans
            un délai de (2) deux ans à compter de celle-ci. En cas de défaut de
            conformité avéré, le Client a droit à la mise en conformité du bien
            par réparation ou remplacement ou, à défaut, à la réduction du prix
            ou à la résolution du contrat, dans les conditions prévues aux
            articles L. 217-8 et suivants du code de la consommation. Le Client
            peut choisir entre la réparation et le remplacement du Produit, sous
            réserve que ce choix n’entraîne pas un coût manifestement
            disproportionné compte tenu de la valeur du Produit ou de
            l'importance du défaut.La garantie légale de conformité s’applique
            indépendamment de toute garantie commerciale éventuellement
            consentie.
          </p>
          <p>
            9.4.3. Garantie des vices cachés Si le Client décide de mettre en
            œuvre la garantie des vices cachés au sens de l’article 1641 du Code
            civil, il peut choisir entre la résolution de la vente ou une
            réduction de prix de vente conformément à l’article 1644 du Code
            civil.
          </p>
          <p>
            9.5. Toute garantie est exclue en cas d’utilisation ou de stockage
            des Produits non conformes aux dispositions figurant sur la
            documentation attachée aux Produits.
          </p>
          <p>
            9.6.  Le Vendeur ne sera pas considéré comme responsable ni
            défaillant si il rapporte la preuve que le retard ou l’inexécution
            est consécutive à la survenance d’un cas de force majeure ou est
            imputable au Client.
          </p>
        </div>
        <div className="section-policies">
          <h3>
            {" "}
            ARTICLE 10 – RESERVE DE PROPRIETE EN TOUT ETAT DE CAUSE, LE
            TRANSFERT DE LA PROPRIÉTÉ DES PRODUITS COMMANDÉS AU PROFIT DU CLIENT
            NE SERA RÉALISÉ QU’APRÈS COMPLET PAIEMENT DU PRIX PAR CE DERNIER.
          </h3>
          <p>
             Le Client devra veiller à ce que l'identification des Produits soit
            toujours possible après leur livraison.
          </p>
        </div>
        <div className="section-policies">
          <h3>ARTICLE 11 – FORCE MAJEURE </h3>
          <p>
            Aucune des parties ne pourra être tenue responsable si elle est
            empêchée ou retardée dans l'exécution de ses obligations pour cause
            de force majeure, résultant d'un événement imprévisible,
            irrésistible et extérieure à leurs volontés. Notamment, en cas
            d'incendie, d’événements climatiques, de catastrophes naturelles,
            pandémie, ou tout autre accident ayant provoqué la destruction
            totale ou partielle de l'entreprise, une grève générale, le manque
            de force motrice ou de matières premières résultant d'une cause
            d'ordre générale. En cas de force majeure, la livraison sera
            reportée à une date ultérieure après cessation de l’événement de
            force majeure, sans que le Client et le Vendeur ne puissent réclamer
            une quelconque indemnité, pour quelque préjudice que ce soit. Si
            l’événement de force majeure dépasse une durée de quatre (4)
            semaines, chacune des parties aura la faculté de résilier le Contrat
            par lettre recommandée avec accusé de réception, sans frais.Dans ce
            cas, le Vendeur restituera les sommes versées par le Client lors de
            la passation de la commande, à l'exclusion de toutes autres
            indemnités dues par le Vendeur au Client.
          </p>
        </div>
        <div className="section-policies">
          <h3>ARTICLE 12 – CONTACT ET SERVICE CLIENTELE</h3>
          <p>
            Pour contacter le Vendeur, le Client peut envoyer un mail au
            Vendeur, à l'adresse suivante : {emailContact}. Pour le suivi de
            l’exécution de sa commande, le Client peut se connecter à la
            rubrique « mon compte » sur le site internet {webSide}.
          </p>
        </div>
        <div className="section-policies">
          <h3>ARTICLE 13 – DONNEES PERSONNELLES</h3>
          <p>
            Dans le cadre de la gestion de la commande du Client, le Vendeur
            sera amené à traiter les données personnelles du Client dans les
            conditions définies dans sa politique de confidentialité. Le Client
            est donc invité à se rendre sur la page suivante {webSide}
            /protection-des-données afin de s’informer sur les conditions de
            traitement mis en œuvre.
          </p>
        </div>
        <div className="section-policies">
          <h3>ARTICLE 14 – DISPOSITIONS GENERALES</h3>
          <p>
            14.1. Si une ou plusieurs stipulations des présentes CGV sont tenues
            pour non valides ou déclarées comme telles en application d'une loi,
            d'un règlement ou à la suite d'une décision passée en force de chose
            jugée d'une juridiction compétente, les autres stipulations
            garderont toute leur force et leur portée.
          </p>
          <p>
            14.2. Les présentes CGV et le récapitulatif de la commande transmis
            au Client forment un ensemble contractuel et constituent
            l’intégralité des relations contractuelles intervenues entre les
            Parties.
          </p>
          <p>
            14.3. Sauf preuve contraire, les données enregistrées par le Vendeur
            constituent la preuve de l'ensemble des transactions.
          </p>
          <p>
            14.4. Tout contrat conclu avec le Client correspondant à une
            commande d’un montant supérieur à 120 euros TTC sera archivé par le
            Vendeur pendant une durée de dix (10) ans conformément à l’article
            L213-1 du code de la consommation. Le Vendeur archivera ces
            informations afin d’assurer un suivi des transactions et de produire
            une copie du contrat à la demande du Client.
          </p>
          <p>
            14.5. En cas de litige, le Vendeur aura la possibilité de prouver
            que son système de suivi électronique est fiable et qu’il garantit
            l’intégrité de la transaction.
          </p>
        </div>
        <div className="section-policies">
          <h3>ARTICLE 16 – DROIT APPLICABLE ET JURIDICTION COMPETENTE</h3>
          <p>
            15.1. Toutes les clauses figurant dans les présentes CGV ainsi que
            toutes les opérations de vente qui y sont visées sont soumises au
            droit français.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CGV;
