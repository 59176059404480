import React, { useContext, useEffect, useState } from "react";
import { ParamContext } from "../contexts/ParamContexts";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PaymentAccepted = ({ setIsLoading, isLoading }) => {
  const { t } = useTranslation();

  const {
    cartItems,
    userStatue,
    codeName,
    dataOrder,
    cartsProductsStored,
    totalPrice,
    setLessOnCart,
    lessOnCart,
    idUser,
    emptyCart,
    onSubmitEmptyCart,
    discount,
    discountAmount,
    code,
    typeDiscount,
    typeDiscountUser,
    rateDiscount,
    valueDiscountUser,
    shippingPriceGuess,
    totalCartGuess,
    shippingUser,
    paidFor,
  } = useContext(ParamContext);

  const urlParams = new URLSearchParams(window.location.search);
  const redirectStatus = urlParams.get("redirect_status");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (redirectStatus === "succeeded" || paidFor === true) {
    } else {
      navigate("/");
    }
  }, [navigate, paidFor, redirectStatus]);

  const [idOrder, setIdOrder] = useState(
    sessionStorage.getItem("idOrder") || ""
  );
  const [nameCustomer, setNameCustomer] = useState(
    sessionStorage.getItem("nameCustomer") || ""
  );

  function handleCustomerData() {
    setLessOnCart(lessOnCart + 1);
    sessionStorage.setItem("paymentSuccess", true);

    let orderDetails = [];

    const itemsList = userStatue ? cartsProductsStored : cartItems;

    for (let item in itemsList) {
      orderDetails.push({
        idProduct: itemsList[item].idProduct,
        price: itemsList[item].PriceProduct,
        idSize: itemsList[item].idSize,
        idColor: itemsList[item].idColor,
        quantity: itemsList[item].quantity,
      });
    }

    const formatData = {
      idUser: idUser,
      cartPrice: userStatue ? totalPrice : totalCartGuess,
      promoCode: userStatue ? code : codeName,
      typeDiscount: userStatue ? typeDiscountUser : typeDiscount,
      discountVal: userStatue ? discountAmount : discount,
      valueDiscountUser: userStatue ? valueDiscountUser : rateDiscount,
      shipping: userStatue ? shippingUser : shippingPriceGuess,
      orderDetails: orderDetails,
    };

    const dataToSend = { ...formatData, ...dataOrder };

    axios
      .post("https://api.sweatteeshop.com/front/sendOrder", dataToSend, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        console.log(response);
        // alert(response.data);
        setIdOrder(response.data.idOrder);
        setNameCustomer(response.data.name);
        emptyCart();
        onSubmitEmptyCart();
        sessionStorage.setItem("idOrder", response.data.idOrder);
        sessionStorage.setItem("nameCustomer", response.data.name);
        sessionStorage.setItem("activeStep", 1);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        window.location.reload();
      });
  }

  useEffect(() => {
    const paymentSuccess = sessionStorage.getItem("paymentSuccess");
    if (
      (paymentSuccess === "false" || paymentSuccess === null) &&
      window.location.pathname === "/confirmation-de-paiement"
    ) {
      setIsLoading(true);
      handleCustomerData();
    } else {
      return;
    }
  }, []);

  return (
    <div className="succed-payement-page">
      {!isLoading && (
        <>
          <div className="head-succed-payement">
            <h1>
              {t("ThankYou")} {nameCustomer},
            </h1>
            <h2>{t("orderRegistered")}</h2>
          </div>
          <p>
            {t("orderNumber")} : <strong>{idOrder}</strong>
          </p>
          <p>{t("confirmationSend")}</p>
          <div>
            <a href="/">
              <button>{t("backToHomePage")}</button>
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentAccepted;
