import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ParamContext } from "../contexts/ParamContexts";
import axios from "axios";

const NavSection = () => {
  const location = useLocation();
  const { adminData } = useContext(ParamContext);

  const [leadsNews, setLeadsNews] = useState([]);
  const [subscribers, setSubscribers] = useState([]);

  const countLeads = () => {
    const leads = leadsNews.filter((lead) => lead?.acceptNewsletter === 1);
    return leads.length + subscribers.length;
  };

  const leadsCount = countLeads();

  useEffect(() => {
    const getUsers = () => {
      axios.get(`https://api.sweatteeshop.com/back/users/datas`).then((res) => {
        const infoObj = Object.values(res.data);
        setLeadsNews(infoObj);
      });
    };
    getUsers();
  }, []);

  useEffect(() => {
    const getSubscribers = () => {
      axios
        .get(`https://api.sweatteeshop.com/back/users/subscribers`)
        .then((res) => {
          const infoObj = Object.values(res.data);
          setSubscribers(infoObj);
        });
    };
    getSubscribers();
  }, []);

  return (
    <div>
      {(location.pathname === "/back/gestion-des-commandes" ||
        location.pathname.startsWith("/back/commandes/")) && (
        <div className="section-back">
          <span>Gestion des commandes</span>
        </div>
      )}
      {(location.pathname === "/back/gestion-des-factures" ||
        location.pathname === "/back/creer-une-facture") && (
        <div className="section-back">
          <span>Gestion des factures</span>
          {adminData.adminAccess === 1 && (
            <div>
              <div className="Link-nav-container">
                <NavLink
                  to="/back/gestion-des-factures"
                  className="link-section-back"
                  activeclassname="active"
                >
                  Factures
                </NavLink>
              </div>
              <div className="Link-nav-container">
                <NavLink
                  to="/back/creer-une-facture"
                  className="link-section-back"
                  activeclassname="active"
                >
                  Creer une facture
                </NavLink>
              </div>
            </div>
          )}
        </div>
      )}
      {(location.pathname === "/back/gestion-des-produits" ||
        location.pathname === "/back/creer-un-produit" ||
        location.pathname.startsWith("/back/modification/produit/") ||
        location.pathname === "/back/produit/gestion-des-parametres") && (
        <div className="section-back">
          <span>Gestion produits</span>
          <div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/gestion-des-produits"
                className="link-section-back"
                activeclassname="active"
              >
                Produits
              </NavLink>
            </div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/creer-un-produit"
                className="link-section-back"
                activeclassname="active"
              >
                Créer un produit
              </NavLink>
            </div>
            {adminData.adminAccess === 1 && (
              <div className="Link-nav-container">
                <NavLink
                  to="/back/produit/gestion-des-parametres"
                  className="link-section-back"
                  activeclassname="active"
                >
                  Gestion supplementaire
                </NavLink>
              </div>
            )}
          </div>
        </div>
      )}
      {(location.pathname === "/back/gestion-des-newsletter" ||
        location.pathname === "/back/creation-de-newsletter" ||
        location.pathname.startsWith("/back/newsletter/edition/")) && (
        <div className="section-back">
          <span>Newsletter ({leadsCount} inscris)</span>
          <div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/gestion-des-newsletter"
                className="link-section-back"
                activeclassname="active"
              >
                Articles
              </NavLink>
            </div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/creation-de-newsletter"
                className="link-section-back"
                activeclassname="active"
              >
                Redaction
              </NavLink>
            </div>
          </div>
        </div>
      )}
      {(location.pathname === "/back/gestion-des-admin" ||
        location.pathname === "/back/creer-un-administrateur" ||
        location.pathname.startsWith("/back/admin/edition/")) && (
        <div className="section-back">
          <span>Gestion equipe</span>
          <div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/gestion-des-admin"
                className="link-section-back"
                activeclassname="active"
              >
                Equipe
              </NavLink>
            </div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/creer-un-administrateur"
                className="link-section-back"
                activeclassname="active"
              >
                Creer un administrateur
              </NavLink>
            </div>
          </div>
        </div>
      )}
      {(location.pathname === "/back/parametre-du-site" ||
        location.pathname === "/back/gestion-des-medias") && (
        <div className="section-back">
          <span>Paramètres</span>
          <div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/parametre-du-site"
                className="link-section-back"
                activeclassname="active"
              >
                Informations
              </NavLink>
            </div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/gestion-des-medias"
                className="link-section-back"
                activeclassname="active"
              >
                Medias
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavSection;
