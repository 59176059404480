import React, { useContext, useEffect, useState } from "react";
import NavbarBack from "../components/NavbarBack";
import NavSection from "../components/NavSection";
import axios from "axios";
import { ParamContext } from "../contexts/ParamContexts";

const CreateNewsletter = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [togglemessage, setToggleMessage] = useState("");

  const { idAdmin } = useContext(ParamContext);

  const createArticle = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const ArticleTitle = formData.get("ArticleTitle");
    const Auteur = formData.get("Auteur");
    const DescArticle = formData.get("DescArticle");
    const articleContent = formData.get("articleContent");

    try {
      const response = await axios.post(
        "https://api.sweatteeshop.com/back/newsletter/create",
        { ArticleTitle, Auteur, DescArticle, articleContent, idAdmin },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      // alert(response.data);
      event.target.reset();
      setToggleMessage(response.data.message);
    } catch (error) {
      console.log(error.message);
      setToggleMessage(
        "Une erreur s'est produite lors de la création du produit."
      );
    }
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavSection />
        <div className="admin-content-page">
          <div className="content-back-container">
            <form onSubmit={createArticle}>
              <div className="checkout-form-container">
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input
                      id="ArticleTitle"
                      name="ArticleTitle"
                      placeholder="Titre"
                      required
                    />
                  </div>
                  <div className="input-checkout-form-nd">
                    <input
                      id="Auteur"
                      name="Auteur"
                      placeholder="Auteur"
                      required
                    />
                  </div>
                </div>
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <input
                      id="DescArticle"
                      name="DescArticle"
                      placeholder="Description"
                      required
                    />
                  </div>
                </div>
                <div className="checkout-row-nd-step">
                  <div className="input-checkout-form-nd">
                    <textarea
                      rows="50"
                      id="articleContent"
                      name="articleContent"
                      placeholder="Contenu"
                      required
                    />
                  </div>
                </div>
                <div className="btn-checkout-container">
                  <button type="submit">
                    <span>Enregistrer</span>
                  </button>
                </div>
              </div>
            </form>
            <div>{togglemessage}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewsletter;
