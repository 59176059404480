import FilterBar from "../components/FilterBar";
import React, { useContext, useEffect, useState } from "react";
import ProductCard from "../components/ProductCard";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import { ParamContext } from "../contexts/ParamContexts";
import { Helmet } from "react-helmet";

const CollectionsPage = ({ setIsLoading }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  const baseUrl = "https://api.sweatteeshop.com/public/images/";

  const { nameCompany, imgOpenTags } = useContext(ParamContext);

  let { id } = useParams();

  const [products, setProducts] = useState([]);
  const [resultsPage, setResultsPage] = useState(16);
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreData = () => {
    if (!hasMore) return;
    setResultsPage(resultsPage + 16);
  };

  const shareProducts = (datas) => {
    const productFilter = datas.filter((product) => {
      return product.collectionId === parseInt(id);
    });
    setProducts(productFilter);
    setHasMore(productFilter.length > 16);
  };

  const sliceData = products.slice(0, resultsPage);

  let dataToDisplay;
  if (products.length > 0) {
    dataToDisplay = sliceData.map((product) => (
      <ProductCard
        key={product.idProduct}
        idProduct={product.idProduct}
        nameProduct={product.nameProduct}
        description={product.descriptionProduct}
        model={product.nameModel}
        images={product.mediaProduct[0].imagesProduct}
        oldPrice={product.priceDiscounted}
        price={product.price}
        currency="&euro;"
        refProduct={product.referenceProduct}
        setIsLoading={setIsLoading}
      />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>{t("NoProduct")}</h3>
      </div>
    );
  }

  return (
    <div className="Page-Products">
      <Helmet>
        <title>{`${nameCompany} | Collection ${products[0]?.collectionProduct}`}</title>
        <meta
          property="og:title"
          content={`${nameCompany} | COLLECTION ${products[0]?.collectionProduct}`}
        />
        <meta
          property="og:description"
          content={`Parcourez la collection ${products[0]?.collectionProduct}.`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta property="og:site_name" content="sweatteeshop-officiel" />
        <meta property="og:url" content="https://sweatteeshop.com" />
        <meta
          name="description"
          content={`Parcourez la collection ${products[0]?.collectionProduct}.`}
        />
        <link rel="canonical" href={`https://sweatteeshop.com`} />
      </Helmet>
      <div className="title-page-product">
        <h1>{products[0]?.collectionProduct}</h1>
      </div>
      <div className="page-product-container">
        <div className="leftside-products-page">
          <h2>Categories</h2>
          <ul>
            <li>
              <NavLink to="/t-shirts">
                T-SHIRTS
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 266.77"
                >
                  <path
                    fillRule="nonzero"
                    d="M493.12 3.22c4.3-4.27 11.3-4.3 15.62-.04a10.85 10.85 0 0 1 .05 15.46L263.83 263.55c-4.3 4.28-11.3 4.3-15.63.05L3.21 18.64a10.85 10.85 0 0 1 .05-15.46c4.32-4.26 11.32-4.23 15.62.04L255.99 240.3 493.12 3.22z"
                  />
                </svg>
              </NavLink>
            </li>
            <li>
              <NavLink to="/sweat-shirts">
                SWEAT-SHIRTS
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 266.77"
                >
                  <path
                    fillRule="nonzero"
                    d="M493.12 3.22c4.3-4.27 11.3-4.3 15.62-.04a10.85 10.85 0 0 1 .05 15.46L263.83 263.55c-4.3 4.28-11.3 4.3-15.63.05L3.21 18.64a10.85 10.85 0 0 1 .05-15.46c4.32-4.26 11.32-4.23 15.62.04L255.99 240.3 493.12 3.22z"
                  />
                </svg>
              </NavLink>
            </li>
            <li>
              <NavLink to="/accessoires">
                {t("Accessories")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 266.77"
                >
                  <path
                    fillRule="nonzero"
                    d="M493.12 3.22c4.3-4.27 11.3-4.3 15.62-.04a10.85 10.85 0 0 1 .05 15.46L263.83 263.55c-4.3 4.28-11.3 4.3-15.63.05L3.21 18.64a10.85 10.85 0 0 1 .05-15.46c4.32-4.26 11.32-4.23 15.62.04L255.99 240.3 493.12 3.22z"
                  />
                </svg>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="content-page-products">
          <FilterBar onShare={shareProducts} setIsLoading={setIsLoading} />
          {products.length > 0 ? (
            <div className="products-container">{dataToDisplay}</div>
          ) : (
            <div className="Empty-container">{dataToDisplay}</div>
          )}
          {hasMore && (
            <div className="loadMoreButtonContainer">
              <button className="loadMoreButton" onClick={fetchMoreData}>
                {t("seeMore")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollectionsPage;
