import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

const FilterBarProduct = ({ onShare }) => {
  const [toggleSortBackProduct, setToggleSortBackProduct] = useState(false);
  const [toggleArrowDate, setToggleArrowDate] = useState(false);
  const [toggleArrowPrice, setToggleArrowPrice] = useState(false);
  const [DateGrowing, setDateGrowing] = useState(false);
  const [PriceGrowing, setPriceGrowing] = useState(false);
  const [StockGrowing, setStockGrowing] = useState(false);
  const [toggleArrowStock, setToggleArrowStock] = useState(false);
  const [DateBill, setDateBill] = useState("descending");
  const [PriceOrder, setPriceOrder] = useState("descending");
  const [StockOrder, setStockOrder] = useState("descending");
  const [SortTerm, setSortTerm] = useState("");
  const [SearchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);

  const myRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!myRef.current.contains(e.target)) {
        setToggleSortBackProduct(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const toggleDateFunc = () => {
    setDateGrowing(!DateGrowing);
    setToggleArrowDate(true);
    setToggleArrowPrice(false);
    setToggleArrowStock(false);
    handleSortChangeDate();
  };

  const togglePriceFunc = () => {
    setPriceGrowing(!PriceGrowing);
    setToggleArrowDate(false);
    setToggleArrowPrice(true);
    setToggleArrowStock(false);
    handleSortChangePrice();
  };

  const toggleStockFunc = () => {
    setStockGrowing(!StockGrowing);
    setToggleArrowDate(false);
    setToggleArrowPrice(false);
    setToggleArrowStock(true);
    handleSortChangeStocks();
  };

  const resetSort = () => {
    setSortTerm("");

    window.location.reload();
  };

  const handleSortChangeDate = () => {
    const newSortOrder = DateBill === "ascending" ? "descending" : "ascending";
    setDateBill(newSortOrder);
    const sortedOrders = [...products].sort((a, b) => {
      if (newSortOrder === "ascending") {
        return a.dateProduct > b.dateProduct ? 1 : -1;
      } else {
        return a.dateProduct < b.dateProduct ? 1 : -1;
      }
    });
    setProducts(sortedOrders);
  };

  const handleSortChangePrice = () => {
    const newSortOrder =
      PriceOrder === "ascending" ? "descending" : "ascending";
    setPriceOrder(newSortOrder);

    const sortedOrders = [...products].sort((a, b) => {
      return newSortOrder === "ascending"
        ? a.PriceProduct - b.PriceProduct
        : b.PriceProduct - a.PriceProduct;
    });

    setProducts(sortedOrders);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChangeStocks = () => {
    const newSortOrder =
      StockOrder === "ascending" ? "descending" : "ascending";
    setStockOrder(newSortOrder);
    const sortedProducts = [...products].sort((a, b) => {
      const totalQuantityA = a.stocks.reduce(
        (total, stock) => total + parseInt(stock.stockProduct),
        0
      );
      const totalQuantityB = b.stocks.reduce(
        (total, stock) => total + parseInt(stock.stockProduct),
        0
      );
      if (newSortOrder === "ascending") {
        return totalQuantityA > totalQuantityB ? 1 : -1;
      } else {
        return totalQuantityA < totalQuantityB ? 1 : -1;
      }
    });
    setProducts(sortedProducts);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [ordersRes] = await Promise.all([
          axios.get("https://api.sweatteeshop.com/back/products/datas"),
        ]);

        const ordersObj = Object.values(ordersRes.data).sort(
          (a, b) => new Date(b.dateProduct) - new Date(a.dateProduct)
        );

        let filteredProduct = ordersObj;

        if (SearchTerm) {
          filteredProduct = filteredProduct?.filter(
            (product) =>
              product?.referenceProduct
                ?.toLowerCase()
                .includes(SearchTerm.toLowerCase()) ||
              product?.nameProduct
                ?.toLowerCase()
                .includes(SearchTerm.toLowerCase()) ||
              product?.PriceProduct?.toString().includes(
                SearchTerm.toLowerCase()
              )
          );
        }

        setProducts(filteredProduct);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [SortTerm, SearchTerm]);

  useEffect(() => {
    onShare(products);
  }, [onShare, products]);

  return (
    <div className="filter-bar-back-container">
      <div ref={myRef} className="sortOrderBackContainer">
        <div onClick={() => setToggleSortBackProduct(!toggleSortBackProduct)}>
          TRIER PAR
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 266.77">
            <path d="M493.12 3.22c4.3-4.27 11.3-4.3 15.62-.04a10.85 10.85 0 0 1 .05 15.46L263.83 263.55c-4.3 4.28-11.3 4.3-15.63.05L3.21 18.64a10.85 10.85 0 0 1 .05-15.46c4.32-4.26 11.32-4.23 15.62.04L255.99 240.3 493.12 3.22z" />
          </svg>
        </div>
        <div
          className={`SubMenuFilterBack ${
            toggleSortBackProduct ? "active" : "inactive"
          }`}
        >
          <ul>
            <li onClick={() => toggleDateFunc()}>
              Dates{" "}
              {toggleArrowDate && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 327 512.23"
                  className={`arrowSortProduct ${DateGrowing ? "growing" : ""}`}
                >
                  <path
                    fillRule="nonzero"
                    d="M301.64 204.53c10.06-.51 17.22-3.9 21.37-10.2 10.52-15.76-1.96-31.28-12.06-42.65L183.12 9.5c-11.46-12.67-27.78-12.67-39.23 0L18.97 148.45c-10.7 11.9-26.4 28.79-15 45.88 4.18 6.3 11.34 9.69 21.39 10.2h76.34v301.36c0 3.49 2.86 6.34 6.34 6.34h110.93c3.47 0 6.33-2.86 6.33-6.34V204.53h76.34z"
                  />
                </svg>
              )}
            </li>
            <li onClick={() => togglePriceFunc()}>
              Prix{" "}
              {toggleArrowPrice && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 327 512.23"
                  className={`arrowSortProduct ${
                    PriceGrowing ? "growing" : ""
                  }`}
                >
                  <path
                    fillRule="nonzero"
                    d="M301.64 204.53c10.06-.51 17.22-3.9 21.37-10.2 10.52-15.76-1.96-31.28-12.06-42.65L183.12 9.5c-11.46-12.67-27.78-12.67-39.23 0L18.97 148.45c-10.7 11.9-26.4 28.79-15 45.88 4.18 6.3 11.34 9.69 21.39 10.2h76.34v301.36c0 3.49 2.86 6.34 6.34 6.34h110.93c3.47 0 6.33-2.86 6.33-6.34V204.53h76.34z"
                  />
                </svg>
              )}
            </li>
            <li onClick={() => toggleStockFunc()}>
              Stocks{" "}
              {toggleArrowStock && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 327 512.23"
                  className={`arrowSortProduct ${
                    StockGrowing ? "growing" : ""
                  }`}
                >
                  <path
                    fillRule="nonzero"
                    d="M301.64 204.53c10.06-.51 17.22-3.9 21.37-10.2 10.52-15.76-1.96-31.28-12.06-42.65L183.12 9.5c-11.46-12.67-27.78-12.67-39.23 0L18.97 148.45c-10.7 11.9-26.4 28.79-15 45.88 4.18 6.3 11.34 9.69 21.39 10.2h76.34v301.36c0 3.49 2.86 6.34 6.34 6.34h110.93c3.47 0 6.33-2.86 6.33-6.34V204.53h76.34z"
                  />
                </svg>
              )}
            </li>
          </ul>
        </div>
      </div>
      {(toggleArrowDate || toggleArrowPrice) && (
        <div className="cancelFilter">
          <span
            className="btnCancelFilter"
            onClick={() => {
              resetSort();
            }}
          >
            Retirer les filtres
          </span>
        </div>
      )}
      <div className="searech-bar">
        <input
          type="search"
          placeholder="Rechercher Ref, Prix, Nom..."
          value={SearchTerm}
          onChange={handleSearchChange}
        />
      </div>
    </div>
  );
};

export default FilterBarProduct;
