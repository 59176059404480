import React, { useContext, useEffect, useState } from "react";
import NavbarBack from "../components/NavbarBack";
import NavSection from "../components/NavSection";
import AdminCard from "../components/AdminCard";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ParamContext } from "../contexts/ParamContexts";

const AdminManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [adminsData, setAdminsData] = useState([]);

  const navigate = useNavigate();

  const { adminData } = useContext(ParamContext);

  useEffect(() => {
    if (adminData.adminAccess === 0) {
      navigate("/back/gestion-des-commandes");
    }
  }, [adminData, navigate]);

  useEffect(() => {
    const getOrder = () => {
      axios.get(`https://api.sweatteeshop.com/back/admin/datas`).then((res) => {
        const infoObj = Object.values(res.data);
        setAdminsData(infoObj);
      });
    };
    getOrder();
  }, []);

  let dataToDisplay;
  if (adminsData.length > 0) {
    dataToDisplay = adminsData.map((admin) => (
      <AdminCard key={admin.idAdmin} idAdmin={admin.idAdmin} />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>Aucune commande trouvée.</h3>
      </div>
    );
  }

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavSection />
        <div className="admin-content-page">{dataToDisplay}</div>
      </div>
    </div>
  );
};

export default AdminManagement;
