import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";

const CurtainFilter = (props) => {
  const { t } = useTranslation();

  let {
    togglefilter,
    setTogglefilter,
    showCollections,
    setShowCollections,
    showColors,
    setShowColors,
    showSizes,
    setShowSizes,
    colors,
    sizes,
    collections,
    selectedColor,
    setSelectedColor,
    handleSelectColor,
    selectedSize,
    setSelectedSize,
    handleSelectSize,
    selectedCollection,
    setSelectedCollection,
    handleSelectCollection,
    selectedPrice,
    setSelectedPrice,
    handleSortChangeAsc,
    handleSortChangeDsc,
    showSorte,
    setShowSorte,
  } = props;

  const [showMenu, setShowMenu] = useState(true);

  return (
    <div
      className={`curtain-filter-container ${
        togglefilter ? "active" : "inactive"
      }`}
    >
      <div
        className="close-filter-container"
        onClick={() => setTogglefilter(!togglefilter)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="35"
          viewBox="0 0 24 24"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M18 6L6 18M6 6l12 12"
            stroke="#000"
            strokeWidth="2"
          />
        </svg>
      </div>
      {showMenu && (
        <div>
          <ul className="container-title-filter">
            <li onClick={() => setShowSorte(true)}>{t("sorteBy")}</li>
            <li onClick={() => setShowColors(true)}>{t("colors")}</li>
            <li onClick={() => setShowSizes(true)}>{t("sizes")}</li>
            <li onClick={() => setShowCollections(true)}>COLLECTIONS</li>
          </ul>
        </div>
      )}
      <CSSTransition
        in={showSorte}
        timeout={300}
        unmountOnExit
        onEnter={() => setShowMenu(false)}
        onExited={() => setShowMenu(true)}
      >
        <div>
          <div className="back-menu-btn">
            <span onClick={() => setShowSorte(false)}>{t("Exit")}</span>
          </div>
          <ul className="filter-items-container">
            <li className="filter-Item">
              <input
                type="radio"
                value="Prix croissants"
                id="PrixCroissants"
                checked={"Prix croissants" === selectedPrice}
                onChange={(e) => {
                  setSelectedPrice(e.target.value);
                  handleSortChangeAsc();
                }}
              />
              <label htmlFor="PrixCroissants">{t("IncreasingPrices")}</label>
              <svg
                className="checked-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 457.57"
              >
                <path d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z" />
              </svg>
            </li>
            <li className="filter-Item">
              <input
                type="radio"
                value="Prix decroissants"
                id="PrixDecroissants"
                checked={"Prix decroissants" === selectedPrice}
                onChange={(e) => {
                  setSelectedPrice(e.target.value);
                  handleSortChangeDsc();
                }}
              />
              <label htmlFor="PrixDecroissants">{t("decreasingPrices")}</label>
              <svg
                className="checked-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 457.57"
              >
                <path d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z" />
              </svg>
            </li>
          </ul>
        </div>
      </CSSTransition>
      <CSSTransition
        in={showCollections}
        timeout={300}
        unmountOnExit
        onEnter={() => setShowMenu(false)}
        onExited={() => setShowMenu(true)}
      >
        <div>
          <div className="back-menu-btn">
            <span onClick={() => setShowCollections(false)}>{t("Exit")}</span>
          </div>
          <ul className="filter-items-container">
            {collections.map((collection) => {
              return (
                <li className="filter-Item" key={collection.idCollection}>
                  <input
                    type="checkbox"
                    value={collection.nameCollection}
                    id={collection.nameCollection}
                    checked={collection.nameCollection === selectedCollection}
                    onChange={(e) => {
                      setSelectedCollection(e.target.value);
                      handleSelectCollection(collection.idCollection);
                    }}
                  />
                  <label htmlFor={collection.nameCollection}>
                    {collection.nameCollection}
                  </label>
                  <svg
                    className="checked-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 457.57"
                  >
                    <path d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z" />
                  </svg>
                </li>
              );
            })}
          </ul>
        </div>
      </CSSTransition>
      <CSSTransition
        in={showColors}
        timeout={300}
        unmountOnExit
        onEnter={() => setShowMenu(false)}
        onExited={() => setShowMenu(true)}
      >
        <div>
          <div className="back-menu-btn">
            <span onClick={() => setShowColors(false)}>{t("Exit")}</span>
          </div>
          <ul className="filter-items-container">
            {colors.map((item) => {
              return (
                <label key={item.idColor} htmlFor={item.nameColor}>
                  <li
                    className={`filterItem-color ${
                      item.nameColor === selectedColor ? "checked-color" : ""
                    }`}
                    style={{ background: item.code }}
                  >
                    <input
                      type="checkbox"
                      value={item.nameColor}
                      id={item.nameColor}
                      checked={item.nameColor === selectedColor}
                      onChange={(e) => {
                        setSelectedColor(e.target.value);
                        handleSelectColor(item.idColor);
                      }}
                    />
                  </li>
                </label>
              );
            })}
          </ul>
        </div>
      </CSSTransition>
      <CSSTransition
        in={showSizes}
        timeout={300}
        unmountOnExit
        onEnter={() => setShowMenu(false)}
        onExited={() => setShowMenu(true)}
      >
        <div>
          <div className="back-menu-btn">
            <span onClick={() => setShowSizes(false)}>{t("Exit")}</span>
          </div>
          <ul className="filter-items-container">
            {sizes
              .sort((a, b) => {
                const orderSizes = ["XS", "S", "M", "L", "XL", "2XL", "3XL"];
                return (
                  orderSizes.indexOf(a.nameSize) -
                  orderSizes.indexOf(b.nameSize)
                );
              })
              .map((size) => {
                return (
                  <li className="filter-Item" key={size.idSize}>
                    <input
                      type="checkbox"
                      value={size.nameSize}
                      id={size.nameSize}
                      checked={size.nameSize === selectedSize}
                      onChange={(e) => {
                        setSelectedSize(e.target.value);
                        handleSelectSize(size.idSize);
                      }}
                    />
                    <label htmlFor={size.nameSize}>{size.nameSize}</label>
                    <svg
                      className="checked-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 457.57"
                    >
                      <path d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z" />
                    </svg>
                  </li>
                );
              })}
          </ul>
        </div>
      </CSSTransition>
    </div>
  );
};

export default CurtainFilter;
