import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { ParamContext } from "../contexts/ParamContexts";

const CurtainsMenu = (props) => {
  const { t } = useTranslation();

  const { collections } = useContext(ParamContext);

  let {
    toggleNav,
    setToggleNav,
    setToggleCross,
    showCollections,
    setShowCollections,
  } = props;

  const [showMenu, setShowMenu] = useState(true);

  const closeCurtainsMenu = () => {
    setToggleNav(false);
    setToggleCross(false);
    setShowCollections(false);
  };

  return (
    <div
      className={`curtainsMenuContainer ${toggleNav ? "active" : "inactive"}`}
    >
      {showMenu && (
        <ul>
          <li>
            <NavLink to="/nouveautés" onClick={closeCurtainsMenu}>
              {t("News")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/t-shirts" onClick={closeCurtainsMenu}>
              T-SHIRTS
            </NavLink>
          </li>
          <li>
            <NavLink to="/sweat-shirts" onClick={closeCurtainsMenu}>
              SWEAT-SHIRTS
            </NavLink>
          </li>
          <li>
            <NavLink to="/sacs" onClick={closeCurtainsMenu}>
              {t("Accessories")}
            </NavLink>
          </li>
          <li onClick={() => setShowCollections(true)}>COLLECTIONS</li>
        </ul>
      )}
      <CSSTransition
        in={showCollections}
        timeout={300}
        unmountOnExit
        onEnter={() => setShowMenu(false)}
        onExited={() => setShowMenu(true)}
      >
        <div className="cellection-menu">
          <div className="back-menu-btn">
            <span onClick={() => setShowCollections(false)}>{t("Exit")}</span>
          </div>
          {collections.map((item) => {
            return (
              <ul key={item.idCollection}>
                <a
                  href={`/collection/${item.idCollection}`}
                  key={item.idCollection}
                >
                  <li className="curtainItem" onClick={closeCurtainsMenu}>
                    {item.nameCollection}
                  </li>
                </a>
              </ul>
            );
          })}
        </div>
      </CSSTransition>
    </div>
  );
};

export default CurtainsMenu;
